import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdminService } from 'src/app/admin/services/admin.service';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { ServiceInformation } from 'src/app/core/services/serviceinformation.service';
import { UtilService } from 'src/app/core/services/util.service';
import { CurrentRoomAIPage } from '../models/enums';
import * as voices from '../data/voices';
import * as languages from '../data/languages';
import * as models from '../data/model';
import * as stt from '../data/stt-model';
import * as tts from '../data/tts-model';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RoomAITemplate, UpdateRoomAITemplateBody } from '../models/roomAITemplate';




@Injectable({
  providedIn: 'root'
})
export class RoomAiService extends ServiceInformation {
  currentPage = CurrentRoomAIPage.list;

  selectedAILanguage: any
  aiLanguagesOptions: any[] = [];
  AILanguage: string = ''

  selectedAIVoice: any
  aiVoicesOptions: any[] = [];
  AIVoice: string = ''

  selectedAIModel: any
  aiModelsOptions: any[] = [];
  AIModel: string = ''

  selectedSTTModel: any
  aiSTTsOptions: any[] = [];
  AISTT: string = ''

  selectedTTSModel: any
  aiTTSOptions: any[] = [];
  AITTS: string = ''

  aiImageChangedEvent:any;
  imageChangedEvent:any

  favoriteButtonBackImageID: string;
  roomAiImageId:string

  favouriteButtonEditBackImage: string;
  aiImageEditBackImage: string;

  roomAITemplateList:RoomAITemplate[]
  templateDetails:RoomAITemplate

  deleteButtonBack:boolean = false
  deleteAIImage:boolean = false

  isAiImageEditMode:boolean = false
  isButtonBackEditMode:boolean = false
  constructor(
    private http: HttpClient,
    public messagePanelService: MessagePanelService,
    public utilService: UtilService,
    protected indexedDBService: IndexedDBService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private adminService: AdminService,
    private dynamicMethodsService: DynamicMethodsService
  ) {
    super(messagePanelService, utilService, indexedDBService);
  }

  callCreateRoomTemplate(roomName: string): Promise<any> {
    let body = {
      'room_name': roomName
    };

    return new Promise((resolve, reject) => {
      this.createRoomTemplate(body)
        .subscribe((response) => {
          if (response == false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  createRoomTemplate(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/create";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callListRoomTemplates(): Promise<void> {
    //TODO:Pagination
    let body = {
      'limit': 300, 
      "offset" : 1
    };

    return new Promise((resolve, reject) => {
      this.getListOfRoomTemplates(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response)) {
            this.roomAITemplateList = []
            reject()
            return;
          }
          resolve();
          this.roomAITemplateList = response
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callDeleteRoomTemplate(roomId:number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.deleteRoomTemplateById(roomId)
        .subscribe((response) => {
          if (response != true) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  callRoomTemplateById(roomId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getRoomTemplateById(roomId)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.templateDetails = response
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  calUpdateRoomTemplateById(updateBody:UpdateRoomAITemplateBody,roomId: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.updateRoomTemplateById(updateBody,roomId)
        .subscribe((response) => {
          if (response == false) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject(false)
            return;
          }
          resolve(true);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getListOfRoomTemplates(body): Observable<RoomAITemplate[]> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/list";
    return this.http.post<any>(endpoint,body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  getRoomTemplateById(roomId: number): Observable<RoomAITemplate> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/"+roomId;;
    return this.http.get<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  updateRoomTemplateById(updateBody:UpdateRoomAITemplateBody, roomId: number): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/"+roomId;;
    return this.http.post<any>(endpoint, updateBody,this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  deleteRoomTemplateById(roomId: number): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/template/"+roomId;
    return this.http.delete<any>(endpoint, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  aiPromptUpdate(body:FormData): Promise<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/ai-prompt/update";
    const options = {};
    return this.http.post<any>(endpoint, body, options)
      .pipe(catchError(error => this.handleError(error))).toPromise();
  }

  populateAILanguagesOptions() {
    if (languages.languageData.languages && languages.languageData.languages.length > 0) {
      const elevenLabsLanguages = languages.languageData.languages.map(lang => ({
        label: lang.language_name,
        value: lang.language_code
      }));
      this.aiLanguagesOptions = elevenLabsLanguages;
    }
  }
  setSelectedAILanguages() {
    const selected = this.aiLanguagesOptions.find(option => option.value === this.AILanguage);
    if (selected) {
      this.selectedAILanguage = selected;
    } else {
      this.selectedAILanguage = null;
    }
  }
  populateAIVoicesOptions() {
    if (voices.voiceData.voices && voices.voiceData.voices.length > 0) {
      const elevenLabsVoices = voices.voiceData.voices.map(voice => ({
        label: voice.name,
        value: voice.voice_id
      }));
      this.aiVoicesOptions = elevenLabsVoices;
    }
  }
  setSelectedAIVoices() {
    const selected = this.aiVoicesOptions.find(option => option.value === this.templateDetails.AIVOICE);
    if (selected) {
      this.selectedAIVoice = selected;
    } else {
      this.selectedAIVoice = null;
    }
  }

  populateAIModelsOptions() {
    if (models.aiModelData.models && models.aiModelData.models.length > 0) {
      const elevenLabsModels = models.aiModelData.models.map(model => ({
        label: model.model_name,
        value: model.model_code
      }));
      this.aiModelsOptions = elevenLabsModels;
    }
  }
  setSelectedAIModels() {
    const selected = this.aiModelsOptions.find(option => option.value === this.templateDetails.AIModel);
    if (selected) {
      this.selectedAIModel = selected;
    } else {
      this.selectedAIModel = null;
    }
  }

  populateAISTTOptions() {
    if (stt.sttModelData.models && stt.sttModelData.models.length > 0) {
      const elevenLabsSTTModels = stt.sttModelData.models.map(model => ({
        label: model.model_name,
        value: model.model_code
      }));
      this.aiSTTsOptions = elevenLabsSTTModels;
    }
  }
  setSelectedSttModels() {
    const selected = this.aiSTTsOptions.find(option => option.value === this.templateDetails.STTModel);
    if (selected) {
      this.selectedSTTModel = selected;
    } else {
      this.selectedSTTModel = null;
    }
  }

  populateAITTSOptions() {
    if (tts.ttsModelData.models && tts.ttsModelData.models.length > 0) {
      const elevenLabsTTSModels = tts.ttsModelData.models.map(model => ({
        label: model.model_name,
        value: model.model_code
      }));
      this.aiTTSOptions = elevenLabsTTSModels;
    }
  }
  setSelectedTTSModels() {
    const selected = this.aiTTSOptions.find(option => option.value === this.templateDetails.TTSModel);
    if (selected) {
      this.selectedTTSModel = selected;
    } else {
      this.selectedTTSModel = null;
    }
  }
}
