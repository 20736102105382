import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { UtilService } from './util.service';
import { ServiceInformation } from './serviceinformation.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessagePanelService } from './messagePanel.service';
import { PanelManagerService } from './panel-manager.service';
import { IndexedDBService } from './indexed-db.service';
import { Router } from '@angular/router';
import { DecodeJWT, UpdateUserNameResponse } from '../models/user';
import { StateIdList } from '../models/state';
import { DeviceService } from './device.service';
import { FriendshipService } from './friendship.service';
import { RoomService } from './room.service';
import { DynamicMethodsReceiveService } from './dynamic-methods-receive.service';
import { LocalStorageService } from './local-storage.service';
import { UnpermittedWordsService } from '../../admin/tabs/unpermitted-words/services/unpermitted-words.service';
import { MainPanels } from '../models/enums';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { environment } from '../../../environments/environment';
import jwt_decode from "jwt-decode";


GoogleAuth.initialize({
    clientId: environment.googleCientId,
    scopes: ['profile', 'email'],
    grantOfflineAccess: true,
});

declare var _fmOpt: any;
@Injectable({
    providedIn: 'root'
})

export class LoginService extends ServiceInformation {
    private httpClientForIP: HttpClient;

    FingerprintID: string;
    ClientIP: string;
    TabId: string;
    MachineType: string;

    constructor(
     
        private handler: HttpBackend,
        private router: Router,
        private http: HttpClient,
        protected utilService: UtilService,
        protected messagePanelService: MessagePanelService,
        protected indexedDBService: IndexedDBService,
        private panelManagerService: PanelManagerService,
        private roomService: RoomService,
        private dynamicMethodsReceiveService: DynamicMethodsReceiveService,
        protected deviceService: DeviceService,
        private friendshipService: FriendshipService,
        private storageService: LocalStorageService,
        private unpermittedWordsService: UnpermittedWordsService

    ) {
        super(messagePanelService, utilService, indexedDBService);

        this.httpClientForIP = new HttpClient(this.handler);
       
     
            GoogleAuth.initialize()
       
       
        
    }

    ngOnDestroy(): void {

    }

    //#region login operations 


    // CODE_TO_LIVE

    initializeTrustDevice(): Promise<void> {
        return new Promise((resolve, reject) => {
            let deviceId = "";
            window._fmOpt = {
                success: (result: any) => {
                    deviceId = result.device_id;
                    let element =document.getElementById("fip")
                    this.FingerprintID = `${deviceId}-${element?.textContent}`;
                    console.log(`fingerprint id : ${this.FingerprintID}`)
                    resolve();
                }

            };

            const fm = document.createElement('script');
            fm.type = 'text/javascript';
            fm.async = true;
            fm.src = '../../../assets/fingerprint/dist/fm.js?t=' + (new Date().getTime() / 3600000).toFixed(0);
            const s = document.getElementsByTagName('script')[0];
            if (s !== null && s.parentNode !== null)
                s.parentNode.insertBefore(fm, s);

        })

    }

    async googleSignIn() {
        let googleUser = await GoogleAuth.signIn();
        this.callGoogleLogin(googleUser)
        .toPromise()
        .then((response) => {
            console.log(`response : ${response}`)
            this.storageService.storageSetItem('token', response.token);
            //var decodedToken = this.decodeJWT()
            var decodedToken = this.decodeJWTWithParam(response.token)
            if (decodedToken.isNewLogin){
                this.panelManagerService.newUserRegisterPopupDisplay = true;
            }else{
                this.login()
            }
        })
    }

    decodeJWTWithParam(token:string): DecodeJWT {
        return jwt_decode(token);
    }

    decodeJWT(): DecodeJWT {
        return jwt_decode(this.storageService.storageGetItem('token'));
    }

    callGoogleLogin(googleUser: any): Observable<any> {
        const endpoint = `${environment.apiUrl}api/google/login`;
        return this.http.post<any>(endpoint, googleUser, this.options);
    }

    getMachineType() {
        let machineType = (this.deviceService.isMobile ? "m" : "d");
        this.MachineType = machineType
        return machineType;
    }

    defineAndGetTabID() {
        var iPageTabID = sessionStorage.getItem("tabID");

        if (iPageTabID === null || iPageTabID === undefined || iPageTabID === "") {
            iPageTabID = this.uuidv4();
            sessionStorage.setItem("tabID", iPageTabID);
        }
        this.TabId = iPageTabID

        return iPageTabID
    }

    //function that gets client public ip address from https://api.ipify.org
    //NOT BEARER TOKEN I BU ADRESE GÖNDERMEK RİSKLİ OLDUĞUNDAN AYRI BİR HTTPCLIENT KULLANDIM
    getIPAddress(): Promise<void> {
        var endpoint = `${environment.apiUrl}get-client-ip`;
        return new Promise((resolve, reject) => {
          this.httpClientForIP.get(endpoint, { responseType: 'text' }).subscribe(
            (res: string) => {
              this.ClientIP = res;
              console.log("ip: ", this.ClientIP);
              resolve();
            });
        });
      }
      

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    login(): Promise<any> {
        this.panelManagerService.loadedFriends = undefined;
        this.panelManagerService.mainLoading = true;
        return this.initializeTrustDevice()
            .then(() => {
                return this.getIPAddress();
            })
            .then(() => {
                return this.defineAndGetTabID()
            })
            .then(() => {
                return this.getMachineType()
            })
            .then(() => {
                return this.AuthenticateUser().toPromise()
            })
            .then(async (response) => {
                if (response.ErrMsg != "") {
                    return Promise.reject(response.ErrMsg);
                }
                // set datas
                this.indexedDBService.userFullInfo = response.UserInfo;
                this.indexedDBService.userFullInfo.RutbeFullInfo = response.Rutbeler;
                this.indexedDBService.userFullInfo.NicktypeForRoom = response.UserInfo.Nicktype;
                this.indexedDBService.ConnectionToken = response.ConnectionToken;
                this.indexedDBService.SubscriptionToken = response.SubscriptionToken;
                this.indexedDBService.UserIsAnyRoomOwner = response.UserInfo.UserIsAnyRoomOwner;
                this.roomService.getFavoriteRooms(this.indexedDBService.userFullInfo.KullaniciAdi)

                //this.roomService.connect(response.ConnectionToken, response.SubscriptionToken);
                this.roomService.connectToNats(response.UserToken)
            })
            .then(() => {
                this.friendshipService.getFriends().then((response) => {
                    this.indexedDBService.Friends = response;
                    this.panelManagerService.loadedFriends = Promise.resolve(true);
                })
            })
            .then(() => {
                this.unpermittedWordsService.getAllBlockedWords().toPromise()
                    .then((response) => {
                        this.indexedDBService.setBannedWordsByServiceResponse(response);
                    })
            })
            .then(() => {
                this.dynamicMethodsReceiveService.RefreshMyFriendsFriendList(this.indexedDBService.userFullInfo.StateId === StateIdList.Cevrimdisi ? false : true);
            })
            .then(async () => {
                this.panelManagerService.activeMainPanel = MainPanels.RoomList;
                this.router.navigate(['/home']);
            })
            .catch((err) => {
                this.messagePanelService.ShowPopupMessageWithLocalization(err, 2000);
                this.storageService.storageRemoveItem('token');
                this.router.navigate(["login"]);
                console.log(`ERROR ON LOGIN OPERATION - ERROR : ${err}`);
            })
            .finally(() => {
                this.panelManagerService.connectionLoading = false;
                this.panelManagerService.mainLoading = false;
                this.panelManagerService.loadedFriends = Promise.resolve(true);
                console.log(`this.indexedDBService.authenticated : ${this.indexedDBService.authenticated}`)
            })
    }

    callGuestLogin(){
        this.guestLogin().toPromise().then((response)=>{
            this.storageService.storageSetItem('token', response.token);
        }).then(()=>{
            this.login()
        })
    }

    callGuestLoginWithRoomUrl(roomId:number,password:string){
        this.guestLogin().toPromise().then((response)=>{
            this.storageService.storageSetItem('token', response.token);
        }).then(()=>{
            this.loginWithUrl(roomId,password)
        })
    }

    loginWithUrl(roomId:number,password?:string): Promise<any> {
        this.panelManagerService.loadedFriends = undefined;
        this.panelManagerService.mainLoading = true;
        return this.initializeTrustDevice()
            .then(() => {
                return this.getIPAddress();
            })
            .then(() => {
                return this.defineAndGetTabID()
            })
            .then(() => {
                return this.getMachineType()
            })
            .then(() => {
                return this.AuthenticateUser().toPromise()
            })
            .then(async (response) => {
                if (response.ErrMsg != "") {
                    return Promise.reject(response.ErrMsg);
                }
                this.indexedDBService.userFullInfo = response.UserInfo;
                this.indexedDBService.userFullInfo.RutbeFullInfo = response.Rutbeler;
                this.indexedDBService.userFullInfo.NicktypeForRoom = response.UserInfo.Nicktype;
                this.indexedDBService.ConnectionToken = response.ConnectionToken;
                this.indexedDBService.SubscriptionToken = response.SubscriptionToken;
                this.indexedDBService.UserIsAnyRoomOwner = response.UserInfo.UserIsAnyRoomOwner;
                this.roomService.getFavoriteRooms(this.indexedDBService.userFullInfo.KullaniciAdi)

                //this.roomService.connect(response.ConnectionToken, response.SubscriptionToken);
                this.roomService.connectToNats(response.UserToken)
            })
            .then(async () => {
                this.panelManagerService.activeMainPanel = MainPanels.RoomList;
                if (password) {
                    this.router.navigate(['/home', 'roomId', roomId, password]);
                }
                else{
                    this.router.navigate(['/home', 'roomId', roomId]);
                }
            })
            .catch((err) => {
                this.messagePanelService.ShowPopupMessageWithLocalization(err, 2000);
                this.storageService.storageRemoveItem('token');
                this.router.navigate(["login"]);
                console.log(`ERROR ON LOGIN OPERATION - ERROR : ${err}`);
            })
            .finally(() => {
                this.panelManagerService.connectionLoading = false;
                this.panelManagerService.mainLoading = false;
                this.panelManagerService.loadedFriends = Promise.resolve(true);
            })
    }

    guestLogin(){
        let apiurl = this.ServiceUrl + "GuestLogin";
        return this.http.post<any>(apiurl, this.options)
    }

    public AuthenticateUser(): Observable<any> {
        let body = {
            'MachineType': this.MachineType,
            'TabId': this.TabId,
            'FingerprintID': this.FingerprintID,
            'ClientIP': this.ClientIP
        };
        const endpoint = this.ServiceUrl + 'api/user/authenticate';

        return this.http.post<any>(endpoint, body, this.options);
    }

    public getOfflineChatAndRequests(body): Observable<any> {
        body["ClientInfo"] = this.Get_ClientInfo();
        const endpoint = this.ServiceUrl + 'GetOfflineChatAndRequests';
        return this.http.post<any>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }

    public updateUsername(body): Observable<UpdateUserNameResponse> {
        const endpoint = this.ServiceUrl + 'api/update/username';
        return this.http.post<UpdateUserNameResponse>(endpoint, body, this.options)
            .pipe(catchError(error => this.handleError(error)));
    }
}
