import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CentrifugoSubscription {
  private callbacks;
  private channel: string;

  private publishSource: Subject<any> = new Subject();
  private joinSource: Subject<any> = new Subject();
  private subscribeSource: Subject<any> = new Subject();
  private leaveSource: Subject<any> = new Subject();
  private errorSource: Subject<any> = new Subject();
  private unsubscribeSource: Subject<any> = new Subject();
  private channelSubscription;
  constructor(
  ) { }

  init(channel: string) {
    this.channel = channel;

    this.callbacks = {
      publish: (message) => {
        this.setPublishSource(message);
      },
      join: (message) => {
        setTimeout(() => {
          this.setJoinSource(message);
        }, 2000);
      },
      leave: (message) => {
        this.setLeaveSource(message);
      },
      subscribe: (context) => {
        this.setSubscribeSource(context);
      },
      error: (errContext) => {
        this.setErrorSource(errContext);
      },
      unsubscribe: (context) => {
        this.setUnsubscribeSource(context);
      },
    };
  }

  getCallbacks() {
    return this.callbacks;
  }

  getChannel(): string {
    return this.channel;
  }

  getChannelSubscription() {
    return this.channelSubscription;
  }

  setChannelSubscription(channelSubscription) {
    this.channelSubscription = channelSubscription;
  }

  unsubscribeFromChannel() {
    if (this.channelSubscription) this.channelSubscription.unsubscribe();
  }

  //#region event handlers

  getPublishSource(): Observable<any> {
    return this.publishSource.asObservable();
  }

  setPublishSource(message: any) {
    this.publishSource.next(message);
  }

  getJoinSource(): Observable<any> {
    return this.joinSource.asObservable();
  }

  setJoinSource(message: any) {
    this.joinSource.next(message);
  }

  getLeaveSource(): Observable<any> {
    return this.leaveSource.asObservable();
  }

  setLeaveSource(message: any) {
    this.leaveSource.next(message);
  }

  getSubscribeSource(): Observable<any> {
    return this.subscribeSource.asObservable();
  }

  setSubscribeSource(message: any) {
    this.subscribeSource.next(message);
  }

  getErrorSource(): Observable<any> {
    return this.errorSource.asObservable();
  }

  setErrorSource(message: any) {
    this.errorSource.next(message);
  }

  getUnsubscribeSource(): Observable<any> {
    return this.unsubscribeSource.asObservable();
  }

  setUnsubscribeSource(message: any) {
    this.unsubscribeSource.next(message);
  }
  //#endregion
}
