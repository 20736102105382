import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './component/home-page/home-page.component';
import { PublicComponent } from './component/public/public.component';
import { UnderConstructionComponent } from './component/public/under-construction/under-construction.component';
import { AuthGuard } from './guard/authenticate.guard';
import { LoginGuard } from './guard/login.guard';
import { RoomLinkComponent } from './component/public/room-link/room-link.component';

const routes: Routes = [
  // { path: 'admin', loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule) }
  { path: '', component: PublicComponent,canActivate: [LoginGuard]}, // Site bakıma alınacağı zaman buraya yönlendirilir: UnderConstructionComponent
  { path: 'login', redirectTo: '', pathMatch: 'full' },
  { path: 'roomId/:id', component: RoomLinkComponent},
  { path: 'roomId/:id/:password', component: RoomLinkComponent}, //for room password
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  { path: 'home/roomId/:id', component: HomePageComponent, canActivate: [AuthGuard]},
  { path: 'home/roomId/:id/:password', component: HomePageComponent, canActivate: [AuthGuard]} //for room password
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: false }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
