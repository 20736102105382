import { Injectable } from '@angular/core';
import { ServiceInformation } from 'src/app/core/services/serviceinformation.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { UtilService } from 'src/app/core/services/util.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RoomListInfo, RoomSettings } from '../models/rooms';
import { RoomService } from 'src/app/core/services/room.service';
import { MicrophoneJson, Room, RoomInfo, RoomServerInfo } from 'src/app/core/models/room';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { DynamicMethodsSendService } from 'src/app/core/services/dynamic-methods-send.service';
import { CurrentRoomPage } from '../models/enums';
import { SqlUser } from 'src/app/admin/models/users';
import { KeepAdminLog } from 'src/app/admin/models/keep-admin-log';
import { AdminService } from 'src/app/admin/services/admin.service';
import { SelectItem } from 'primeng/api';
import { DynamicMethodsService } from 'src/app/core/services/dynamic-methods.service';
import * as voices from '../data/voices';
import * as languages from '../data/languages';


@Injectable({
  providedIn: 'root'
})
export class AdminRoomService extends ServiceInformation {

  roomListInfo: RoomListInfo[];
  roomDetails: RoomInfo;

  sqlUsers: Array<SqlUser> = [];
  roomServerInfo: Array<RoomServerInfo> = [];

  currentPage = CurrentRoomPage.roomList;

  newRoomAddedSource: Subject<Room> = new Subject();
  newRoomAdded = this.newRoomAddedSource.asObservable();

  serverCreatedSource: Subject<SelectItem> = new Subject();
  serverCreated = this.serverCreatedSource.asObservable();

  selectedAudioPreset : any
  audioPresetsOptions: any[] = [];

  selectedAILanguage : any
  aiLanguagesOptions: any[] = [];

  selectedAIVoice : any
  aiVoicesOptions: any[] = [];

  selectedAIRole : any
  aiRolesOptions: any[] = [];

  roomServer: SelectItem[] = [];
  imageChangedEvent: any;
  aiImageChangedEvent:any;
  isThereFavoriteButtonBackImage: boolean;
  isThereAiImage:boolean;
  favoriteButtonBackImageID: string;
  aiImageId:string
  roomSettings: RoomSettings;

  constructor(
    private http: HttpClient,
    public messagePanelService: MessagePanelService,
    public utilService: UtilService,
    protected indexedDBService: IndexedDBService,
    private coreRoomService: RoomService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private adminService: AdminService,
    private dynamicMethodsService:DynamicMethodsService
  ) {
    super(messagePanelService, utilService, indexedDBService);
    this.roomServer = [
      // { label: 'Seçiniz', title: '', value: 15 },
      { label: 'Server 1', title: 'sesli-vm4-coreos-kb3', value: 1 },
      { label: 'Server 2', title: 'sesli-vm5-coreos-kb4', value: 5 },
      { label: 'Server 3', title: 'sesli-vm6-coreos-kb5', value: 7 }
    ];
  }

  //#region room list operations

  callGetRoomInfoList(): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'UserId': null
    };

    return new Promise((resolve, reject) => {
      this.getRoomInfoList(body)
        .subscribe((roomListInfo: any) => {
          if (this.utilService.isNullOrUndefined(roomListInfo) || roomListInfo === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.roomListInfo = roomListInfo;
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getRoomInfoList(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/room/info/list";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region add room operations
  callCreateRoom(roomName: string, hesapId: number): Promise<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RoomName': roomName,
      'HesapId': hesapId,
      'MicServiceUrl': this.coreRoomService.getMicServiceUrl()
    };

    return new Promise((resolve, reject) => {
      this.createRoom(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response) || response === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }
  populateAudioPresetsOptions() {
    this.audioPresetsOptions = [
      { label: 'Telephone (12_000)', value:12_000  },
      { label: 'Speech (20_000)', value: 20_000 },
      { label: 'Music (32_000)', value: 32_000 },
      { label: 'Music Stereo (48_000)', value: 48_000 },
      { label: 'Music High Quality (64_000)', value: 64_000 },
      { label: 'Music High Quality Stereo (96_000)', value: 96_000 }
    ];
  }



  createRoom(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/create";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  
  aiPromptUpdate(body:FormData): Promise<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/ai-prompt/update";
    const options = {};
    return this.http.post<any>(endpoint, body, options)
      .pipe(catchError(error => this.handleError(error))).toPromise();
  }
  //#endregion

  //#region delete room operations

  callDeleteRoomByIdAndName(roomId: number, roomName: string): Promise<any> {
    return this.coreRoomService.getMicInfoJsonFormServer(roomId)
      .then((microphoneJsonStruct: MicrophoneJson) => {
        if (this.utilService.isNullOrEmtpyString(microphoneJsonStruct.M) === false) {
          return this.coreRoomService.stopUserMicrophoneRequest(microphoneJsonStruct.M, roomId);
        }
        return;
      })
      .then(() => {
        return this.callDeleteRoomById(roomId)
      })
      .then((response) => {
        if (this.utilService.isNullOrEmtpyObject(response)) {
          this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
          return Promise.reject();
        }

        if (response.Result === false) {
          if (this.utilService.IsNullOrWhitespace(response.ErrMsg) === true)
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
          else
            this.messagePanelService.ShowPopupMessageWithLocalization(response.ErrMsg, 2000);
          return Promise.reject();
        }

        var keepAdminLog: KeepAdminLog = new KeepAdminLog();
        keepAdminLog.AdminLog = this.utilService.getKeyWithTag("RoomDeleted") + "(" + roomName + "). ";
        keepAdminLog.TargetName = this.utilService.getKeyWithTag("DeleteRoomTitle");
        keepAdminLog.UserMessage = "RoomDeleted";

        this.messagePanelService.ShowPopupMessageWithLocalization("RoomDeleted", 2000);
        return this.adminService.callSaveAdminActions(keepAdminLog.AdminLog, keepAdminLog.TargetName)
      })
      .then(() => {
        this.indexedDBService.RoomOrderList = this.indexedDBService.RoomOrderList.filter(r => r !== roomId);

        // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([this.dynamicMethodsSendService.ChangedRoomOrderList(this.indexedDBService.RoomOrderList)]);

        let operationMessageForChangedRoomOrderList = this.dynamicMethodsService.prepareAdminBulkSettingMessage(
          [this.dynamicMethodsSendService.ChangedRoomOrderList(this.indexedDBService.RoomOrderList)]
        )
        this.dynamicMethodsService.publishDynChangedRoomOrderList(operationMessageForChangedRoomOrderList)
        return this.sendMessageToUserForRoomDeleted(roomId, roomName);
      })
      .then(() => {
        return this.callGetRoomInfoList();
      })
  }

  callDeleteRoomById(roomId: number): Promise<any> {
    let body = {
      "ClientInfo": this.Get_ClientInfo(),
      "UserId": this.indexedDBService.userFullInfo.KullaniciId,
      "RoomId": roomId
    };

    return new Promise((resolve, reject) => {
      this.deleteRoomById(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response) || response === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }
  setSelectedAudioPresetByMaxBitrate() {
    const preset = this.audioPresetsOptions.find(option => option.value === this.roomDetails.AUDIO_QUALITY);
    if (preset) {
        this.selectedAudioPreset = preset;
    } else {
        this.selectedAudioPreset = null;
    }
  }
  callSaveRoomOrder(roomIds): Promise<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RoomList': roomIds
    };

    return new Promise((resolve, reject) => {
      this.saveRoomOrder(body)
        .subscribe((res: any) => {
          if (this.utilService.isNullOrEmtpyObject(res) || res === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject(null);
          }
          resolve(null);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  dropAllUserInMic(roomId:number): Observable<any> {
    let body = {
      "roomId": roomId
    }
    const endpoint = this.ServiceUrl + "api/mic/stop/room";
    return this.http.post<any>(endpoint, body,this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  saveRoomOrder(body): Observable<any> {
    const endpoint = this.ServiceUrl + "SaveRoomOrder";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  deleteRoomById(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/deletebyid";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  sendMessageToUserForRoomDeleted(roomId: number, roomName: string): Promise<void> {
    // return this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel(
    //   [this.dynamicMethodsSendService.SetOdaSetting(roomId, roomName, true)]
    // );
    let operationMessageForSetOdaSetting = this.dynamicMethodsService.prepareAdminBulkSettingMessage([
      this.dynamicMethodsSendService.SetOdaSetting(roomId, roomName, true)
    ])
   return this.dynamicMethodsService.publishDynSetOdaSetting(operationMessageForSetOdaSetting)
  }
  //#endregion

  //#region edit room operations
  callGetRoomByName(roomName: string): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RoomName': roomName
    };

    return new Promise((resolve, reject) => {
      this.getRoomByName(body)
        .subscribe((roomDetails: any) => {
          if (this.utilService.isNullOrEmtpyObject(roomDetails) || roomDetails === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.roomDetails = roomDetails;
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getRoomByName(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/room/info/byname";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callGetRoomOwnerList(rutbeId: number): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RutbeId': rutbeId
    };

    return new Promise((resolve, reject) => {
      this.getRoomOwnerList(body)
        .subscribe((sqlUsers: SqlUser[]) => {
          if (this.utilService.isNullOrEmtpyObject(sqlUsers)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.sqlUsers = sqlUsers;
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getRoomOwnerList(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/room/owner/list";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callGetHesapList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getHesapList()
        .subscribe((roomServerInfo: RoomServerInfo[]) => {
          if (this.utilService.isNullOrEmtpyObject(roomServerInfo)) {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          this.roomServerInfo = roomServerInfo;
          resolve();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getHesapList(): Observable<any> {
    const endpoint = this.ServiceUrl + "GetHesapList";
    return this.http.post<any>(endpoint, this.Get_ClientInfo(), this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  callUpdateRoom(newRoomsettings, deleteRoomBack, deleteButtonBack,deleteAIImage): Promise<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'NewRoomSettings': newRoomsettings,
      'DeleteRoomBack': deleteRoomBack,
      // 'RoomBackBlob': roomBackBlob,
      // 'RoomFavoriButtonBackBlob': roomFavoriButtonBackBlob,
      'DeleteButtonBack': deleteButtonBack,
      'DeleteAIImage':deleteAIImage
      // 'RoomBackImgIsGif': roomBackImgIsGif
    };

    return new Promise((resolve, reject) => {
      this.updateRoom(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyObject(response) || response === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  updateRoom(body): Observable<any> {
    const endpoint = this.ServiceUrl + "api/admin/room/update";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region default favorite rooms operations
  callSaveDefaultRooms(roomIdList: number[]): Promise<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RoomIdList': roomIdList
    };

    return new Promise((resolve, reject) => {
      this.saveDefaultRooms(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyString(response) || response === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  saveDefaultRooms(body): Observable<any> {
    const endpoint = this.ServiceUrl + "SaveDefaultRooms";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region kubernetes distributor operations
  callCreateKubernetesDistributor(nodeName: string, nodeId: number, roomID: string): Promise<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'NodeName': nodeName,
      'NodeId': nodeId,
      'RoomID': roomID
    };

    return new Promise((resolve, reject) => {
      this.createKubernetesDistributor(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyString(response) || response === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  createKubernetesDistributor(body): Observable<any> {
    const endpoint = this.ServiceUrl + "CreateKubernetesDistributor";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }


  callDeleteKubernetesDistributor(roomID: string): Promise<any> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RoomID': roomID
    };

    return new Promise((resolve, reject) => {
      this.deleteKubernetesDistributor(body)
        .subscribe((response) => {
          if (this.utilService.isNullOrEmtpyString(response) || response === "") {
            this.messagePanelService.ShowPopupMessageWithLocalization("ErrorPleaseReTry", 2000);
            reject()
            return;
          }
          resolve(response);
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  deleteKubernetesDistributor(body): Observable<any> {
    const endpoint = this.ServiceUrl + "DeleteKubernetesDistributor";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion  

}