import { DynamicMethodsService } from './../../../../core/services/dynamic-methods.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ServiceInformation } from '../../../../core/services/serviceinformation.service';
import { MessagePanelService } from '../../../../core/services/messagePanel.service';
import { UtilService } from '../../../../core/services/util.service';
import { IndexedDBService } from '../../../../core/services/indexed-db.service';
import { GetRutbeInfo, GetUserRutbeInfo } from '../models/rank';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RankFullInfo } from '../../../../core/models/rank';
import { CurrentRankPage } from '../models/enums';
import { KeepAdminLog } from '../../../../admin/models/keep-admin-log';
import { AdminService } from '../../../../admin/services/admin.service';
import { BulkMessageTypes } from '../../../../admin/models/enums';
import { Constants } from '../../../../core/models/constants';
import { BulkMessageType, ChatType } from '../../../../core/models/enums';
import { ChatMessageService } from '../../../../core/services/chatMessage.service';
import { CentrifugoService } from '../../../../core/centrifugo/services/centrifugo.service';
import { DynamicMethodsSendService } from '../../../../core/services/dynamic-methods-send.service';
import { RoomService } from '../../../../core/services/room.service';
import { environment } from '../../../../../environments/environment';
import { CentrifugoConfig } from 'src/app/core/centrifugo/models/constants';


@Injectable({
  providedIn: 'root'
})
export class AdminRankService extends ServiceInformation {

  getRutbeInfo: Array<GetRutbeInfo> = [];
  getRutbeInfoById: RankFullInfo;

  addRankPopupDisplay: boolean = false;

  currentPage = CurrentRankPage.rankList;

  rutbeFullInfoCalledByIdSource: Subject<RankFullInfo> = new Subject();
  rutbeFullInfoCalledById = this.rutbeFullInfoCalledByIdSource.asObservable();

  rankUpdatedSource: Subject<any> = new Subject();
  rankUpdated = this.rankUpdatedSource.asObservable();

  keepAdminLog: KeepAdminLog;

  oldRankSettings: RankFullInfo;

  constructor(
    private http: HttpClient,
    protected messagePanelService: MessagePanelService,
    protected utilService: UtilService,
    protected indexedDBService: IndexedDBService,
    private adminService: AdminService,
    private chatMessageService: ChatMessageService,
    private centrifugoService: CentrifugoService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private roomService: RoomService,
    private dynamicMethodsService:DynamicMethodsService
  ) {
    super(messagePanelService, utilService, indexedDBService);
  }

  //#region  get ranks
  callGetRutbeler(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.getRutbeler()
        .subscribe((getRutbeInfo: GetRutbeInfo[]) => {
          if (this.utilService.isNullOrEmtpyObject(getRutbeInfo) === false) {
            this.getRutbeInfo = getRutbeInfo;
            resolve();
          }
          else
            reject();
        }),
        error => {
          console.log(JSON.stringify(error));
          reject();
        };
    })
  }

  getRutbeler(): Observable<any> {
    const endpoint = `${environment.apiUrl}api/admin/rutbe/user`;
    return this.http.post<any>(endpoint, null, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region  get rank details
  callGetRutbeById(rutbeId: number): Promise<any> {
    let body = {
      "RutbeId": rutbeId
    }

    return new Promise((resolve, reject) => {
      this.getRutbeById(body)
        .subscribe((getRutbeInfoById: RankFullInfo) => {
          if (this.utilService.isNullOrEmtpyObject(getRutbeInfoById) === false) {
            this.getRutbeInfoById = getRutbeInfoById;
            this.rutbeFullInfoCalledByIdSource.next();
            this.oldRankSettings = JSON.parse(JSON.stringify(this.getRutbeInfoById));
            resolve(getRutbeInfoById);
          }
          else
            reject();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  getRutbeById(body): Observable<any> {
    const endpoint = `${environment.apiUrl}api/admin/rutbe/info`;
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region create rank
  callCreateRutbe(rutbe): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'Rutbe': rutbe
    };

    return new Promise((resolve, reject) => {
      this.createRutbe(body)
        .subscribe((response) => {
          if (!this.utilService.isNullOrEmtpyObject(response)) {
            if (response.Success === false || !this.utilService.isNullOrEmtpyString(response.ErrMsg))
              reject();
            else
              resolve();
          }
          else
            reject();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  createRutbe(body): Observable<any> {
    const endpoint = this.ServiceUrl + "CreateRutbe";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  getSubRanks(rankId: number): Observable<GetRutbeInfo[]> {
    const endpoint = `${environment.apiUrl}api/admin/subranks/${rankId}`;
    return this.http.get<GetRutbeInfo[]>(endpoint).pipe(catchError(error => this.handleError(error)));
  }

  getSubAuthorities(userId: number): Observable<GetUserRutbeInfo> {
    const endpoint = `${environment.apiUrl}api/admin/subauthorities/list/${userId}`;
    return this.http.get<GetUserRutbeInfo>(endpoint).pipe(catchError(error => this.handleError(error)));
  }

  //#region  delete rank
  callDeleteRutbeById(rutbeId: number): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RutbeId': rutbeId
    };

    return new Promise((resolve, reject) => {
      this.deleteRutbeById(body)
        .subscribe((response) => {
          if (!this.utilService.isNullOrEmtpyObject(response)) {
            if (!this.utilService.isNullOrEmtpyString(response.ErrMsg) || response.Result === false)
              reject();
            else {
              return this.saveAdminActionsForChangedRank(
                "RankDeleted",
                this.utilService.getKeyWithTag("DeleteRank"),
                this.utilService.getKeyWithTag("RankDeleted") + "(" + this.getRutbeInfoById.ADI + ").\n "
              )
                .then(() => {
                  this.sendAdminBulkMessages(BulkMessageTypes.delete);
                })
                .then(() => {
                  return this.callGetRutbeById(0)
                })
                .then((rutbeInfoByID: RankFullInfo) => {
                 // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([this.dynamicMethodsSendService.EditUsersForChangedRank(this.getRutbeInfoById.ID, rutbeInfoByID)]);
               
                  let operationMessageForEditUsersForChangedRank = this.dynamicMethodsService.prepareAdminBulkSettingMessage([
                    this.dynamicMethodsSendService.EditUsersForChangedRank(this.getRutbeInfoById.ID, rutbeInfoByID)
                  ])
                  this.dynamicMethodsService.publishDynEditUsersForChangedRank(operationMessageForEditUsersForChangedRank)
                })
                .then(() => {
                  resolve();
                })
                .catch(err => {
                  console.log(err);
                  reject();
                });
            }
          }
          else
            reject();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  saveAdminActionsForChangedRank(userMessage: string, targetName: string, adminLog: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.keepAdminLog = new KeepAdminLog();
      this.keepAdminLog.AdminLog = adminLog;
      this.keepAdminLog.TargetName = targetName;
      this.keepAdminLog.UserMessage = userMessage;
      this.adminService.callSaveAdminActions(this.keepAdminLog.AdminLog, this.keepAdminLog.TargetName)
        .then(() => { resolve(); })
        .catch(() => { reject(); });
    });
  }

  sendAdminBulkMessages(bulkMessageType: BulkMessageTypes) {

    this.chatMessageService.sendAdminBulkMessageToPublicChannel(
      this.utilService.getKeyWithTag(bulkMessageType === BulkMessageTypes.edit ? "RankUpdated" : "RankDeleted") + " (" + this.getRutbeInfoById.ADI + ")",
      Constants.BulkMessageUnnecessaryId,
      BulkMessageType.AllRooms,
      ChatType.AdminChangesMessage
    );

    // this.centrifugoService.publishBulkMessageToRankMembersFriends(
    //   this.indexedDBService.userFullInfo.KullaniciId,
    //   {
    //     rankId: this.getRutbeInfoById.ID,
    //     data: [this.dynamicMethodsSendService.RefreshFriendList()]
    //   }
    // );

    this.dynamicMethodsService.publishDynRefreshFriendList(
      this.indexedDBService.userFullInfo.KullaniciId,
      {
        rankId: this.getRutbeInfoById.ID,
        data: [this.dynamicMethodsSendService.RefreshFriendList()]
      }
    );

    // this.centrifugoService.publishBulkMessageToRankMembers(
    //   this.indexedDBService.userFullInfo.KullaniciId,
    //   {
    //     rankId: this.getRutbeInfoById.ID,
    //     data: [
    //       this.dynamicMethodsSendService.RefreshUser(Constants.BulkMessageUnnecessaryId),
    //       this.dynamicMethodsSendService.KullaniciBilgiEkle(true),
    //       this.dynamicMethodsSendService.AdminPaneliKapat(),
    //       this.dynamicMethodsSendService.KullaniciAdminHareketiMesajGoster(Constants.BulkMessageUnnecessaryId, this.utilService.getKeyWithTag(bulkMessageType === BulkMessageTypes.edit ? "RankUpdatedSetMember" : "RankDeletedSetMember"))
    //     ]
    //   }
    // );

    // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([
    //   this.dynamicMethodsSendService.SetOdaSetting(this.roomService.currentRoom?.Info.ID)
    // ]);
    let operationMessageForSetOdaSetting = this.dynamicMethodsService.prepareAdminBulkSettingMessage([
      this.dynamicMethodsSendService.SetOdaSetting(this.roomService.currentRoom?.Info.ID)
    ])
    this.dynamicMethodsService.publishDynSetOdaSetting(operationMessageForSetOdaSetting)
    
    this.rankUpdatedSource.next();
  }

  deleteRutbeById(body): Observable<any> {
    const endpoint = this.ServiceUrl + "DeleteRutbeById";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion

  //#region update rank
  callUpdateRutbe(rutbeId: number, rutbeInfoJson: string): Promise<void> {
    let body = {
      'ClientInfo': this.Get_ClientInfo(),
      'RutbeId': rutbeId,
      'RutbeInfoJson': rutbeInfoJson
    };

    return new Promise((resolve, reject) => {
      this.updateRutbe(body)
        .subscribe((response) => {
          if (!this.utilService.isNullOrEmtpyObject(response)) {
            if (response.Result === false || !this.utilService.isNullOrEmtpyString(response.ErrMsg))
              reject();
            else {
              return this.saveAdminActionsForChangedRank(
                "SavedRankSettings",
                this.utilService.getKeyWithTag("UpdateRank"),
                JSON.stringify(this.createAdminLogObject())
              )
                .then(() => {
                  this.sendAdminBulkMessages(BulkMessageTypes.edit);
                })
                .then(() => {
                  return this.callGetRutbeById(this.getRutbeInfoById.ID)
                })
                .then((rutbeInfoByID: RankFullInfo) => {
                  // this.chatMessageService.sendAdminBulkSettingMessageToPublicChannel([this.dynamicMethodsSendService.EditUsersForChangedRank(this.getRutbeInfoById.ID, rutbeInfoByID)]);
                  let operationMessageForEditUsersForChangedRank = this.dynamicMethodsService.prepareAdminBulkSettingMessage([
                    this.dynamicMethodsSendService.EditUsersForChangedRank(this.getRutbeInfoById.ID, rutbeInfoByID)
                  ])
                  this.dynamicMethodsService.publishDynEditUsersForChangedRank(operationMessageForEditUsersForChangedRank)
                })
                .then(() => {
                  resolve();
                })
                .catch(err => {
                  console.log(err);
                  reject();
                })
            }
          }
          else
            reject();
        }),
        error => {
          console.log(error);
          reject();
        };
    })
  }

  createAdminLogObject() {
    var newRankSettings = this.getRutbeInfoById;
    var myobj = [];

    if (this.oldRankSettings["ID"] !== newRankSettings.ID) {
      myobj.push({
        "localizationkey": "Prop_UserID",
        "oldSetting": this.oldRankSettings["ID"],
        "newSetting": newRankSettings.ID
      })
    }
    if (this.oldRankSettings["ADI"] !== newRankSettings.ADI) {
      myobj.push({
        "localizationkey": "Prop_UserName",
        "oldSetting": this.oldRankSettings["ADI"],
        "newSetting": newRankSettings.ADI
      })
    }
    if (this.oldRankSettings["MIS_SURE"] !== newRankSettings.MIS_SURE) {
      myobj.push({
        "localizationkey": "Prop_MicSure",
        "oldSetting": this.oldRankSettings["MIS_SURE"],
        "newSetting": newRankSettings.MIS_SURE
      })
    }
    if (this.oldRankSettings["KAMERA_LIMIT"] !== newRankSettings.KAMERA_LIMIT) {
      myobj.push({
        "localizationkey": "Prop_CamLimit",
        "oldSetting": this.oldRankSettings["KAMERA_LIMIT"],
        "newSetting": newRankSettings.KAMERA_LIMIT
      })
    }
    if (this.oldRankSettings["KISI_DEGISTIRME"] !== newRankSettings.KISI_DEGISTIRME) {
      myobj.push({
        "localizationkey": "Prop_UpdateUser",
        "oldSetting": this.oldRankSettings["KISI_DEGISTIRME"],
        "newSetting": newRankSettings.KISI_DEGISTIRME
      })
    }
    if (this.oldRankSettings["KISI_SILME"] !== newRankSettings.KISI_SILME) {
      myobj.push({
        "localizationkey": "Prop_DeleteUser",
        "oldSetting": this.oldRankSettings["KISI_SILME"],
        "newSetting": newRankSettings.KISI_SILME
      })
    }
    if (this.oldRankSettings["KISI_SIFRE"] !== newRankSettings.KISI_SIFRE) {
      myobj.push({
        "localizationkey": "Prop_UserPassword",
        "oldSetting": this.oldRankSettings["KISI_SIFRE"],
        "newSetting": newRankSettings.KISI_SIFRE
      })
    }
    if (this.oldRankSettings["KISI_YETKI"] !== newRankSettings.KISI_YETKI) {
      myobj.push({
        "localizationkey": "Prop_PersonAuthority",
        "oldSetting": this.oldRankSettings["KISI_YETKI"],
        "newSetting": newRankSettings.KISI_YETKI
      })
    }
    if (this.oldRankSettings["KISI_RUTBE"] !== newRankSettings.KISI_RUTBE) {
      myobj.push({
        "localizationkey": "Prop_UserRank",
        "oldSetting": this.oldRankSettings["KISI_RUTBE"],
        "newSetting": newRankSettings.KISI_RUTBE
      })
    }
    if (this.oldRankSettings["KISI_KILIT_BITIS_TARIHI"] !== newRankSettings.KISI_KILIT_BITIS_TARIHI) {
      myobj.push({
        "localizationkey": "Prop_KisiKilitBitisTarihi",
        "oldSetting": this.oldRankSettings["KISI_KILIT_BITIS_TARIHI"],
        "newSetting": newRankSettings.KISI_KILIT_BITIS_TARIHI
      })
    }
    if (this.oldRankSettings["KISI_PC_LISTESI"] !== newRankSettings.KISI_PC_LISTESI) {
      myobj.push({
        "localizationkey": "Prop_ComputerList",
        "oldSetting": this.oldRankSettings["KISI_PC_LISTESI"],
        "newSetting": newRankSettings.KISI_PC_LISTESI
      })
    }
    if (this.oldRankSettings["KISI_BIREYSEL_BILGI_GORME"] !== newRankSettings.KISI_BIREYSEL_BILGI_GORME) {
      myobj.push({
        "localizationkey": "Prop_SeePersonelInformation",
        "oldSetting": this.oldRankSettings["KISI_BIREYSEL_BILGI_GORME"],
        "newSetting": newRankSettings.KISI_BIREYSEL_BILGI_GORME
      })
    }
    if (this.oldRankSettings["KISI_BIREYSEL_BILGI_DUZENLEME"] !== newRankSettings.KISI_BIREYSEL_BILGI_DUZENLEME) {
      myobj.push({
        "localizationkey": "Prop_UpdatePersonalInformationn",
        "oldSetting": this.oldRankSettings["KISI_BIREYSEL_BILGI_DUZENLEME"],
        "newSetting": newRankSettings.KISI_BIREYSEL_BILGI_DUZENLEME
      })
    }
    if (this.oldRankSettings["RUTBE_DEGISTIRME"] !== newRankSettings.RUTBE_DEGISTIRME) {
      myobj.push({
        "localizationkey": "Prop_ChangeRankOptions",
        "oldSetting": this.oldRankSettings["RUTBE_DEGISTIRME"],
        "newSetting": newRankSettings.RUTBE_DEGISTIRME
      })
    }
    if (this.oldRankSettings["RUTBE_EKLEME"] !== newRankSettings.RUTBE_EKLEME) {
      myobj.push({
        "localizationkey": "Prop_AddRank",
        "oldSetting": this.oldRankSettings["RUTBE_EKLEME"],
        "newSetting": newRankSettings.RUTBE_EKLEME
      })
    }
    if (this.oldRankSettings["RUTBE_SILME"] !== newRankSettings.RUTBE_SILME) {
      myobj.push({
        "localizationkey": "Prop_DeleteRank",
        "oldSetting": this.oldRankSettings["RUTBE_SILME"],
        "newSetting": newRankSettings.RUTBE_SILME
      })
    }
    if (this.oldRankSettings["ODA_DEGISTIRME"] !== newRankSettings.ODA_DEGISTIRME) {
      myobj.push({
        "localizationkey": "Prop_ChangeRoomInformation",
        "oldSetting": this.oldRankSettings["ODA_DEGISTIRME"],
        "newSetting": newRankSettings.ODA_DEGISTIRME
      })
    }
    if (this.oldRankSettings["ODA_EKLEME"] !== newRankSettings.ODA_EKLEME) {
      myobj.push({
        "localizationkey": "Prop_AddRoom",
        "oldSetting": this.oldRankSettings["ODA_EKLEME"],
        "newSetting": newRankSettings.ODA_EKLEME
      })
    }
    if (this.oldRankSettings["ODA_SILME"] !== newRankSettings.ODA_SILME) {
      myobj.push({
        "localizationkey": "Prop_DeleteRoom",
        "oldSetting": this.oldRankSettings["ODA_SILME"],
        "newSetting": newRankSettings.ODA_SILME
      })
    }
    if (this.oldRankSettings["ODA_SIFRE"] !== newRankSettings.ODA_SIFRE) {
      myobj.push({
        "localizationkey": "Prop_SeeRoomPassword",
        "oldSetting": this.oldRankSettings["ODA_SIFRE"],
        "newSetting": newRankSettings.ODA_SIFRE
      })
    }
    if (this.oldRankSettings["ODA_SESSIZ"] !== newRankSettings.ODA_SESSIZ) {
      myobj.push({
        "localizationkey": "Prop_RoomSilentMode",
        "oldSetting": this.oldRankSettings["ODA_SESSIZ"],
        "newSetting": newRankSettings.ODA_SESSIZ
      })
    }
    if (this.oldRankSettings["BAN_ODA"] !== newRankSettings.BAN_ODA) {
      myobj.push({
        "localizationkey": "Prop_RoomBan",
        "oldSetting": this.oldRankSettings["BAN_ODA"],
        "newSetting": newRankSettings.BAN_ODA
      })
    }
    if (this.oldRankSettings["BAN_SITE"] !== newRankSettings.BAN_SITE) {
      myobj.push({
        "localizationkey": "Prop_GeneralBan",
        "oldSetting": this.oldRankSettings["BAN_SITE"],
        "newSetting": newRankSettings.BAN_SITE
      })
    }
    if (this.oldRankSettings["BAN_SAAT"] !== newRankSettings.BAN_SAAT) {
      myobj.push({
        "localizationkey": "Prop_1HourBan",
        "oldSetting": this.oldRankSettings["BAN_SAAT"],
        "newSetting": newRankSettings.BAN_SAAT
      })
    }
    if (this.oldRankSettings["BAN_1"] !== newRankSettings.BAN_1) {
      myobj.push({
        "localizationkey": "Prop_1DayBan",
        "oldSetting": this.oldRankSettings["BAN_1"],
        "newSetting": newRankSettings.BAN_1
      })
    }
    if (this.oldRankSettings["BAN_3"] !== newRankSettings.BAN_3) {
      myobj.push({
        "localizationkey": "Prop_3DaysBan",
        "oldSetting": this.oldRankSettings["BAN_3"],
        "newSetting": newRankSettings.BAN_3
      })
    }
    if (this.oldRankSettings["BAN_7"] !== newRankSettings.BAN_7) {
      myobj.push({
        "localizationkey": "Prop_7DaysBan",
        "oldSetting": this.oldRankSettings["BAN_7"],
        "newSetting": newRankSettings.BAN_7
      })
    }
    if (this.oldRankSettings["BAN_30"] !== newRankSettings.BAN_30) {
      myobj.push({
        "localizationkey": "Prop_30DaysBan",
        "oldSetting": this.oldRankSettings["BAN_30"],
        "newSetting": newRankSettings.BAN_30
      })
    }
    if (this.oldRankSettings["BAN_SURESIZ"] !== newRankSettings.BAN_SURESIZ) {
      myobj.push({
        "localizationkey": "Prop_InfiniteBan",
        "oldSetting": this.oldRankSettings["BAN_SURESIZ"],
        "newSetting": newRankSettings.BAN_SURESIZ
      })
    }
    if (this.oldRankSettings["MUTE_ODA"] !== newRankSettings.MUTE_ODA) {
      myobj.push({
        "localizationkey": "Prop_RoomMute",
        "oldSetting": this.oldRankSettings["MUTE_ODA"],
        "newSetting": newRankSettings.MUTE_ODA
      })
    }
    if (this.oldRankSettings["MUTE_SITE"] !== newRankSettings.MUTE_SITE) {
      myobj.push({
        "localizationkey": "Prop_GeneralMute",
        "oldSetting": this.oldRankSettings["MUTE_SITE"],
        "newSetting": newRankSettings.MUTE_SITE
      })
    }
    if (this.oldRankSettings["ATMA_ODA"] !== newRankSettings.ATMA_ODA) {
      myobj.push({
        "localizationkey": "Prop_KickFromRoom",
        "oldSetting": this.oldRankSettings["ATMA_ODA"],
        "newSetting": newRankSettings.ATMA_ODA
      })
    }
    if (this.oldRankSettings["ATMA_SITE"] !== newRankSettings.ATMA_SITE) {
      myobj.push({
        "localizationkey": "Prop_KickFromAllRooms",
        "oldSetting": this.oldRankSettings["ATMA_SITE"],
        "newSetting": newRankSettings.ATMA_SITE
      })
    }
    if (this.oldRankSettings["GECICI_OP_ODA"] !== newRankSettings.GECICI_OP_ODA) {
      myobj.push({
        "localizationkey": "Prop_TemporaryRoomOperatorManagement",
        "oldSetting": this.oldRankSettings["GECICI_OP_ODA"],
        "newSetting": newRankSettings.GECICI_OP_ODA
      })
    }
    if (this.oldRankSettings["GECICI_OP_SITE"] !== newRankSettings.GECICI_OP_SITE) {
      myobj.push({
        "localizationkey": "Prop_TemporaryGeneralOperatorManagement",
        "oldSetting": this.oldRankSettings["GECICI_OP_SITE"],
        "newSetting": newRankSettings.GECICI_OP_SITE
      })
    }
    if (this.oldRankSettings["ADMIN_HAREKETLERI"] !== newRankSettings.ADMIN_HAREKETLERI) {
      myobj.push({
        "localizationkey": "Prop_AdminActions",
        "oldSetting": this.oldRankSettings["ADMIN_HAREKETLERI"],
        "newSetting": newRankSettings.ADMIN_HAREKETLERI
      })
    }
    // if (this.oldRankSettings["GIRIS_KAYITLARI_MESSENGER"] !== newRankSettings.GIRIS_KAYITLARI_MESSENGER) {
    //   myobj.push({
    //     "localizationkey": "Prop_EntryrecordsMessenger",
    //     "oldSetting": this.oldRankSettings["GIRIS_KAYITLARI_MESSENGER"],
    //     "newSetting": newRankSettings.GIRIS_KAYITLARI_MESSENGER
    //   })
    // }
    if (this.oldRankSettings["GIRIS_KAYITLARI_SOHBET"] !== newRankSettings.GIRIS_KAYITLARI_SOHBET) {
      myobj.push({
        "localizationkey": "Prop_EntryrecordsChatRoom",
        "oldSetting": this.oldRankSettings["GIRIS_KAYITLARI_SOHBET"],
        "newSetting": newRankSettings.GIRIS_KAYITLARI_SOHBET
      })
    }
    if (this.oldRankSettings["KELIME_YASAKLAMA"] !== newRankSettings.KELIME_YASAKLAMA) {
      myobj.push({
        "localizationkey": "Prop_WordBan",
        "oldSetting": this.oldRankSettings["KELIME_YASAKLAMA"],
        "newSetting": newRankSettings.KELIME_YASAKLAMA
      })
    }
    if (this.oldRankSettings["SISTEM_AYARLARI"] !== newRankSettings.SISTEM_AYARLARI) {
      myobj.push({
        "localizationkey": "Prop_SystemSettings",
        "oldSetting": this.oldRankSettings["SISTEM_AYARLARI"],
        "newSetting": newRankSettings.SISTEM_AYARLARI
      })
    }
    if (this.oldRankSettings["BAN_GIRISLERI_AC_KAPA"] !== newRankSettings.BAN_GIRISLERI_AC_KAPA) {
      myobj.push({
        "localizationkey": "Prop_TurningOnOfBannedUserEntrence",
        "oldSetting": this.oldRankSettings["BAN_GIRISLERI_AC_KAPA"],
        "newSetting": newRankSettings.BAN_GIRISLERI_AC_KAPA
      })
    }
    if (this.oldRankSettings["ANIMASYONLAR"] !== newRankSettings.ANIMASYONLAR) {
      myobj.push({
        "localizationkey": "Prop_Animations",
        "oldSetting": this.oldRankSettings["ANIMASYONLAR"],
        "newSetting": newRankSettings.ANIMASYONLAR
      })
    }
    if (this.oldRankSettings["GERI_BILDURUM"] !== newRankSettings.GERI_BILDURUM) {
      myobj.push({
        "localizationkey": "Prop_FeedbackManagement",
        "oldSetting": this.oldRankSettings["GERI_BILDURUM"],
        "newSetting": newRankSettings.GERI_BILDURUM
      })
    }
    if (this.oldRankSettings["KENDI_FLASHI"] !== newRankSettings.KENDI_FLASHI) {
      myobj.push({
        "localizationkey": "_ManageYourOwnFlashNick",
        "oldSetting": this.oldRankSettings["KENDI_FLASHI"],
        "newSetting": newRankSettings.KENDI_FLASHI
      })
    }
    if (this.oldRankSettings["YAZILARI_SIL"] !== newRankSettings.YAZILARI_SIL) {
      myobj.push({
        "localizationkey": "Prop_ClearRoomChatPanel",
        "oldSetting": this.oldRankSettings["YAZILARI_SIL"],
        "newSetting": newRankSettings.YAZILARI_SIL
      })
    }
    if (this.oldRankSettings["IKINCI_RUMUZ"] !== newRankSettings.IKINCI_RUMUZ) {
      myobj.push({
        "localizationkey": "Prop_SecondNickname",
        "oldSetting": this.oldRankSettings["IKINCI_RUMUZ"],
        "newSetting": newRankSettings.IKINCI_RUMUZ
      })
    }
    if (this.oldRankSettings["MIKROFON_DUSURME"] !== newRankSettings.MIKROFON_DUSURME) {
      myobj.push({
        "localizationkey": "Prop_StopUserMicrophone",
        "oldSetting": this.oldRankSettings["MIKROFON_DUSURME"],
        "newSetting": newRankSettings.MIKROFON_DUSURME
      })
    }
    if (this.oldRankSettings["MIKROFON_ALMA"] !== newRankSettings.MIKROFON_ALMA) {
      myobj.push({
        "localizationkey": "Prop_TakeMicrophoneBack",
        "oldSetting": this.oldRankSettings["MIKROFON_ALMA"],
        "newSetting": newRankSettings.MIKROFON_ALMA
      })
    }
    if (this.oldRankSettings["GENEL_MESAJ"] !== newRankSettings.GENEL_MESAJ) {
      myobj.push({
        "localizationkey": "Prop_SendGeneralMessage",
        "oldSetting": this.oldRankSettings["GENEL_MESAJ"],
        "newSetting": newRankSettings.GENEL_MESAJ
      })
    }
    if (this.oldRankSettings["GENEL_MESAJ_ADET"] !== newRankSettings.GENEL_MESAJ_ADET) {
      myobj.push({
        "localizationkey": "Prop_GeneralMessageLimit",
        "oldSetting": this.oldRankSettings["GENEL_MESAJ_ADET"],
        "newSetting": newRankSettings.GENEL_MESAJ_ADET
      })
    }
    if (this.oldRankSettings["EL_INDIR"] !== newRankSettings.EL_INDIR) {
      myobj.push({
        "localizationkey": "Prop_HandDown",
        "oldSetting": this.oldRankSettings["EL_INDIR"],
        "newSetting": newRankSettings.EL_INDIR
      })
    }
    if (this.oldRankSettings["OZEL_MESAJ"] !== newRankSettings.OZEL_MESAJ) {
      myobj.push({
        "localizationkey": "Prop_SendPrivateMessageinRoom",
        "oldSetting": this.oldRankSettings["OZEL_MESAJ"],
        "newSetting": newRankSettings.OZEL_MESAJ
      })
    }
    if (this.oldRankSettings["ODAYA_CEKME"] !== newRankSettings.ODAYA_CEKME) {
      myobj.push({
        "localizationkey": "Prop_PuttingUsertotheRoom",
        "oldSetting": this.oldRankSettings["ODAYA_CEKME"],
        "newSetting": newRankSettings.ODAYA_CEKME
      })
    }
    if (this.oldRankSettings["GORUNMEZ_MOD"] !== newRankSettings.GORUNMEZ_MOD) {
      myobj.push({
        "localizationkey": "Prop_InvisibleMode",
        "oldSetting": this.oldRankSettings["GORUNMEZ_MOD"],
        "newSetting": newRankSettings.GORUNMEZ_MOD
      })
    }
    if (this.oldRankSettings["YAZMA_KONUSMA_IZNI"] !== newRankSettings.YAZMA_KONUSMA_IZNI) {
      myobj.push({
        "localizationkey": "Prop_WritingSpeakingManagement",
        "oldSetting": this.oldRankSettings["YAZMA_KONUSMA_IZNI"],
        "newSetting": newRankSettings.YAZMA_KONUSMA_IZNI
      })
    }
    if (this.oldRankSettings["KARA_LISTE"] !== newRankSettings.KARA_LISTE) {
      myobj.push({
        "localizationkey": "Prop_Blacklist",
        "oldSetting": this.oldRankSettings["KARA_LISTE"],
        "newSetting": newRankSettings.KARA_LISTE
      })
    }
    if (this.oldRankSettings["RENKLI_RUMUZ"] !== newRankSettings.RENKLI_RUMUZ) {
      myobj.push({
        "localizationkey": "Prop_NicknameColor",
        "oldSetting": this.oldRankSettings["RENKLI_RUMUZ"],
        "newSetting": newRankSettings.RENKLI_RUMUZ
      })
    }
    if (this.oldRankSettings["SESSIZ_SESLI"] !== newRankSettings.SESSIZ_SESLI) {
      myobj.push({
        "localizationkey": "Prop_SessizSesli",
        "oldSetting": this.oldRankSettings["SESSIZ_SESLI"],
        "newSetting": newRankSettings.SESSIZ_SESLI
      })
    }
    if (this.oldRankSettings["DERECE"] !== newRankSettings.DERECE) {
      myobj.push({
        "localizationkey": "Prop_Derece",
        "oldSetting": this.oldRankSettings["DERECE"],
        "newSetting": newRankSettings.DERECE
      })
    }
    if (this.oldRankSettings["MIK_ENGEL_ODA"] !== newRankSettings.MIK_ENGEL_ODA) {
      myobj.push({
        "localizationkey": "Prop_BlockMicrophoneinRoom",
        "oldSetting": this.oldRankSettings["MIK_ENGEL_ODA"],
        "newSetting": newRankSettings.MIK_ENGEL_ODA
      })
    }
    if (this.oldRankSettings["MIK_ENGEL_SITE"] !== newRankSettings.MIK_ENGEL_SITE) {
      myobj.push({
        "localizationkey": "Prop_BlockMicrophoneinRoominAllRooms",
        "oldSetting": this.oldRankSettings["MIK_ENGEL_SITE"],
        "newSetting": newRankSettings.MIK_ENGEL_SITE
      })
    }
    if (this.oldRankSettings["ODADA_KULLANICI_LISTELE"] !== newRankSettings.ODADA_KULLANICI_LISTELE) {
      myobj.push({
        "localizationkey": "Prop_ListAllUsersinAllRooms",
        "oldSetting": this.oldRankSettings["ODADA_KULLANICI_LISTELE"],
        "newSetting": newRankSettings.ODADA_KULLANICI_LISTELE
      })
    }
    if (this.oldRankSettings["KAMERA_SONLANDIR"] !== newRankSettings.KAMERA_SONLANDIR) {
      myobj.push({
        "localizationkey": "Prop_EndCamera",
        "oldSetting": this.oldRankSettings["KAMERA_SONLANDIR"],
        "newSetting": newRankSettings.KAMERA_SONLANDIR
      })
    }
    if (this.oldRankSettings["FLASHNICK_DEGISTIRME"] !== newRankSettings.FLASHNICK_DEGISTIRME) {
      myobj.push({
        "localizationkey": "Prop_UpdateFlashNick",
        "oldSetting": this.oldRankSettings["FLASHNICK_DEGISTIRME"],
        "newSetting": newRankSettings.FLASHNICK_DEGISTIRME
      })
    }
    if (this.oldRankSettings["GRUP_ACMA"] !== newRankSettings.GRUP_ACMA) {
      myobj.push({
        "localizationkey": "Prop_CreateNewGroup",
        "oldSetting": this.oldRankSettings["GRUP_ACMA"],
        "newSetting": newRankSettings.GRUP_ACMA
      })
    }
    if (this.oldRankSettings["GOREV_DESTEK"] !== newRankSettings.GOREV_DESTEK) {
      myobj.push({
        "localizationkey": "Prop_TaskSupport",
        "oldSetting": this.oldRankSettings["GOREV_DESTEK"],
        "newSetting": newRankSettings.GOREV_DESTEK
      })
    }
    if (this.oldRankSettings["ACIL_CAGRI"] !== newRankSettings.ACIL_CAGRI) {
      myobj.push({
        "localizationkey": "Prop_EmergencyCall",
        "oldSetting": this.oldRankSettings["ACIL_CAGRI"],
        "newSetting": newRankSettings.ACIL_CAGRI
      })
    }
    if (this.oldRankSettings["BAS_KONUS"] !== newRankSettings.BAS_KONUS) {
      myobj.push({
        "localizationkey": "Prop_SendSoundRecord",
        "oldSetting": this.oldRankSettings["BAS_KONUS"],
        "newSetting": newRankSettings.BAS_KONUS
      })
    }
    if (this.oldRankSettings["SIKAYET_GORUNTULE"] !== newRankSettings.SIKAYET_GORUNTULE) {
      myobj.push({
        "localizationkey": "Prop_ViewComplaint",
        "oldSetting": this.oldRankSettings["SIKAYET_GORUNTULE"],
        "newSetting": newRankSettings.SIKAYET_GORUNTULE
      })
    }
    if (this.oldRankSettings["SIKAYET_SIL"] !== newRankSettings.SIKAYET_SIL) {
      myobj.push({
        "localizationkey": "Prop_DeleteComplaint",
        "oldSetting": this.oldRankSettings["SIKAYET_SIL"],
        "newSetting": newRankSettings.SIKAYET_SIL
      })
    }
    if (this.oldRankSettings["ODADAN_ARKADAS_EKLEME_BUTONU"] !== newRankSettings.ODADAN_ARKADAS_EKLEME_BUTONU) {
      myobj.push({
        "localizationkey": "Prop_AddFriendinRoom",
        "oldSetting": this.oldRankSettings["ODADAN_ARKADAS_EKLEME_BUTONU"],
        "newSetting": newRankSettings.ODADAN_ARKADAS_EKLEME_BUTONU
      })
    }
    if (this.oldRankSettings["ODAYA_DAVET"] !== newRankSettings.ODAYA_DAVET) {
      myobj.push({
        "localizationkey": "Prop_InvitetoRoom",
        "oldSetting": this.oldRankSettings["ODAYA_DAVET"],
        "newSetting": newRankSettings.ODAYA_DAVET
      })
    }
    if (this.oldRankSettings["TRANSLATE_ODA"] !== newRankSettings.TRANSLATE_ODA) {
      myobj.push({
        "localizationkey": "TranslateRoom",
        "oldSetting": this.oldRankSettings["TRANSLATE_ODA"],
        "newSetting": newRankSettings.TRANSLATE_ODA
      })
    }
    if (this.oldRankSettings["TRANSLATE_MESSENGER"] !== newRankSettings.TRANSLATE_MESSENGER) {
      myobj.push({
        "localizationkey": "TranslateMessenger",
        "oldSetting": this.oldRankSettings["TRANSLATE_MESSENGER"],
        "newSetting": newRankSettings.TRANSLATE_MESSENGER
      })
    }
    if (this.oldRankSettings["KENDINICKINIYONETEBILIR"] !== newRankSettings.KENDINICKINIYONETEBILIR) {
      myobj.push({
        "localizationkey": "Prop_ManageYourOwnFlashNick",
        "oldSetting": this.oldRankSettings["KENDINICKINIYONETEBILIR"],
        "newSetting": newRankSettings.KENDINICKINIYONETEBILIR
      })
    }
    if (this.oldRankSettings["YETKILI_SLIDER_YONETIMI"] !== newRankSettings.YETKILI_SLIDER_YONETIMI) {
      myobj.push({
        "localizationkey": "authorizedSliderManagement",
        "oldSetting": this.oldRankSettings["YETKILI_SLIDER_YONETIMI"],
        "newSetting": newRankSettings.YETKILI_SLIDER_YONETIMI
      })
    }
    return myobj;
  }

  updateRutbe(body): Observable<any> {
    const endpoint = this.ServiceUrl + "UpdateRutbe";
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }
  //#endregion
}