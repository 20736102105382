import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { ChatMessage, ConferenceRequestResponse, FileMessageData, P2PConferenceAnswerType } from '../models/chat-message';
import { ChatService } from './chat.service';
import { ChatPanelProps } from '../models/chat-panel-props';
import { ContactType, ContactState, MessageSendingType, MessageBubbleType, ChatType, MessageType } from 'src/app/core/models/enums';
import { Friend, UserFullInfo } from '../models/user';
import { MessagePanelService } from './messagePanel.service';
import { IndexedDBService } from './indexed-db.service';
import { ChatMessageService } from './chatMessage.service';
import { DynamicMethodsSendService } from './dynamic-methods-send.service';
import { StateImagesByName } from '../models/state';
import { MessengerService } from './messenger.service';
import { PanelManagerService } from './panel-manager.service';
import { DynamicMethodsService } from './dynamic-methods.service';


@Injectable({
  providedIn: 'root'
})
export class P2PService {
  maxBubbleCount = 20;
  constructor(
    public utilService: UtilService,
    private chatService: ChatService,
    private messagePanelService: MessagePanelService,
    private indexedDBService: IndexedDBService,
    private chatMessageService: ChatMessageService,
    private dynamicMethodsSendService: DynamicMethodsSendService,
    private messengerService: MessengerService,
    private panelManagerService: PanelManagerService,
    private dynamicMethodsService:DynamicMethodsService
  ) {


  }



  sendCallingForVideoConferenceMessageToFriend(chatPanelProps: ChatPanelProps) {
    this.sendInfoMessageToFriend(chatPanelProps, this.utilService.getKeyWithTag("CallingYou_Video"));
  }

  sendCallingForAudioConferenceMessageToFriend(chatPanelProps: ChatPanelProps) {
    this.sendInfoMessageToFriend(chatPanelProps, this.utilService.getKeyWithTag("CallingYou_Audio"));
  }

  sendRejectConferenceMessageToFriend(chatPanelProps: ChatPanelProps) {
    this.sendInfoMessageToFriend(chatPanelProps, this.utilService.getKeyWithTag("CallingYou_StopLast"));
  }

  sendStopConferenceMessageToFriend(chatPanelProps: ChatPanelProps) {
    this.sendInfoMessageToFriend(chatPanelProps, this.utilService.getKeyWithTag("UserEndedConference"));
  }

  sendAcceptConferenceMessageToFriend(chatPanelProps: ChatPanelProps) {
    this.sendInfoMessageToFriend(chatPanelProps, this.utilService.getKeyWithTag("UserJoinConference"));
  }

  sendNotResponseConferenceMessageToFriend(chatPanelProps: ChatPanelProps) {
    this.sendInfoMessageToFriend(chatPanelProps, this.utilService.getKeyWithTag("UserNotResponse"));
  }



  sendInfoMessageToFriend(chatPanelProps: ChatPanelProps, message: string) {
    this.sendMessageToFriend(chatPanelProps, message, MessageBubbleType.PtoPInfo)
      .then(() => {

      })
      .catch(error => {
        console.log(error)
      })
  }

  sendMessageToFriend(chatPanelProps: ChatPanelProps, messageHtml: string, messageBubbleType: MessageBubbleType,isAnimation?:boolean): Promise<any> {
    var chatMessage = this.chatService.getChatMessage(chatPanelProps, messageHtml, ContactType.Chat, ContactState.Hicbiri, this.indexedDBService.userFullInfo);
    chatMessage.RtfText = this.chatService.clearFakeSpans(chatMessage.RtfText);
    chatMessage.RtfText = this.chatService.replaceImageTagToImageSymbols(chatMessage.RtfText);

    chatMessage.Time = new Date();

    if (messageHtml.indexOf(this.utilService.getKeyWithTag("UserNotResponse")) !== -1) {
      chatMessage.SenderName = chatPanelProps.UserName;
      chatMessage.SenderRutbeDerece = chatPanelProps.UserRubeDerece;
    }
    if (isAnimation) {
      chatMessage.MessageType = MessageType.Animation;
    }
    // chatMessage.Time = this.utilService.addServerTimeDistanceToDate(chatMessage.Time, this.indexedDBService.serverTimeDistance); // mesajı gönerirken server time ile pc time arasındaki fark eklenerek time server time a ayarlanır ve giden mesajlar her zmaan server time olmalıdır. mesajı alan kullanıcı kendi pc timena göre yeniden set edip ekrana öyle basacaktır.

    chatMessage.MessageBubbleType = messageBubbleType;
    chatMessage.MessageSendingType = chatPanelProps.MessageSendingType;
    // chatMessage.IsSended = false;
    chatMessage.NickType = this.indexedDBService.userFullInfo.NicktypeForRoom;

    chatMessage.MessageId = this.chatService.getMessageId(chatMessage);
    //  this.chatService.setChatMessageLocalDbId(chatMessage);

    // TODO_CENTRIFUGE : not trigger subscription
    this.chatService.setUserChannelMessageReceivedSource(Object.assign({}, chatMessage));

    let chatMessageForSend = Object.assign({}, chatMessage);

    var user: Friend = this.indexedDBService.getFriendById(chatMessage.ReceiverId);

    //arkadaşım değilse:
    if (this.utilService.isNullOrUndefined(user)) {
      this.panelManagerService.p2pChatPanels.forEach(chatPanel => {
        chatPanel.user.KullaniciId === chatMessage.ReceiverId ? user = chatPanel.user : null;
      });
    }

    if (user.StateId === StateImagesByName.State_Offline &&
      ChatType.SingleChat === chatMessage.ChatType &&
      chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
      var chat = this.chatService.chatMessageToSqlChatMessageTable(chatMessage, user.KullaniciId, true);
      this.callSaveOfflineChat(chat);
    }

    return this.chatService.centrifugoService.publishP2PChatMessage(this.indexedDBService.userFullInfo.KullaniciId, chatPanelProps.UserId, chatMessageForSend)
      .then(() => {
        // chatMessageForSend.IsSended = true;
         return Promise.resolve();
        // return this.indexedDBService.updateChatMessage(chatMessageForSend, false);
      })
      .catch((err) => {
        console.log(err)
        // chatMessageForSend.IsSended = false;
        return Promise.reject();
        // return this.indexedDBService.updateChatMessage(chatMessageForSend, true);
      })

  }

  sendFileMessageToFriend(chatPanelProps: ChatPanelProps, messageHtml: string, messageBubbleType: MessageBubbleType,fileData:FileMessageData): Promise<any> {
    var chatMessage = this.chatService.getChatMessage(chatPanelProps, messageHtml, ContactType.Chat, ContactState.Hicbiri, this.indexedDBService.userFullInfo);
    chatMessage.RtfText = this.chatService.clearFakeSpans(chatMessage.RtfText);
    chatMessage.RtfText = this.chatService.replaceImageTagToImageSymbols(chatMessage.RtfText);

    chatMessage.Time = new Date();

    if (messageHtml.indexOf(this.utilService.getKeyWithTag("UserNotResponse")) !== -1) {
      chatMessage.SenderName = chatPanelProps.UserName;
      chatMessage.SenderRutbeDerece = chatPanelProps.UserRubeDerece;
    }

    // chatMessage.Time = this.utilService.addServerTimeDistanceToDate(chatMessage.Time, this.indexedDBService.serverTimeDistance); // mesajı gönerirken server time ile pc time arasındaki fark eklenerek time server time a ayarlanır ve giden mesajlar her zmaan server time olmalıdır. mesajı alan kullanıcı kendi pc timena göre yeniden set edip ekrana öyle basacaktır.

    chatMessage.MessageBubbleType = messageBubbleType;
    chatMessage.MessageSendingType = chatPanelProps.MessageSendingType;
    // chatMessage.IsSended = false;
    chatMessage.NickType = this.indexedDBService.userFullInfo.NicktypeForRoom;

    chatMessage.MessageId = this.chatService.getMessageId(chatMessage);

    //file properties
    chatMessage.IsDocs = fileData.isFile;
    chatMessage.IsImage = fileData.isImage;
    chatMessage.URL = fileData.url;
    chatMessage.RtfText = fileData.path
    chatMessage.Text = fileData.path

    //  this.chatService.setChatMessageLocalDbId(chatMessage);

    // TODO_CENTRIFUGE : not trigger subscription
    this.chatService.setUserChannelMessageReceivedSource(Object.assign({}, chatMessage));

    let chatMessageForSend = Object.assign({}, chatMessage);

    var user: Friend = this.indexedDBService.getFriendById(chatMessage.ReceiverId);

    //arkadaşım değilse:
    if (this.utilService.isNullOrUndefined(user)) {
      this.panelManagerService.p2pChatPanels.forEach(chatPanel => {
        chatPanel.user.KullaniciId === chatMessage.ReceiverId ? user = chatPanel.user : null;
      });
    }

    if (user.StateId === StateImagesByName.State_Offline &&
      ChatType.SingleChat === chatMessage.ChatType &&
      chatMessage.SenderId === this.indexedDBService.userFullInfo.KullaniciId) {
      var chat = this.chatService.chatMessageToSqlChatMessageTable(chatMessage, user.KullaniciId, true);
      this.callSaveOfflineChat(chat);
    }
    return this.chatService.centrifugoService.publishP2PChatMessage(this.indexedDBService.userFullInfo.KullaniciId, chatPanelProps.UserId, chatMessageForSend)
      .then(() => {
        // chatMessageForSend.IsSended = true;
         return Promise.resolve();
        // return this.indexedDBService.updateChatMessage(chatMessageForSend, false);
      })
      .catch((err) => {
        console.log(err)
        // chatMessageForSend.IsSended = false;
        return Promise.reject();
        // return this.indexedDBService.updateChatMessage(chatMessageForSend, true);
      })

  }

  callSaveOfflineChat(chat: any) {
    let body = {
      'ClientInfo': null,
      'Chat': chat
    };

    return this.messengerService.saveOfflineChat(body)
      .toPromise()
      .then((value) => {

      })
  }

  sendConferenceRequestMessage(friendId: number, conferenceRequestResponse: ConferenceRequestResponse) {
    //  this.chatMessageService.sendUserSettingMessage(friendId,
    //   [this.dynamicMethodsSendService.P2PConferenceRequest(conferenceRequestResponse)]
    // );

    let operationMessageForP2PConferenceRequest = this.dynamicMethodsService.prepareUserSettingMessage(
      friendId,
      [this.dynamicMethodsSendService.P2PConferenceRequest(conferenceRequestResponse)])
     this.dynamicMethodsService.publishDynP2PConferenceRequest(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForP2PConferenceRequest);
  }

  sendConferenceResponseMessage(friendId: number, conferenceRequestResponse: ConferenceRequestResponse) {
    // this.chatMessageService.sendUserSettingMessage(friendId,
    //   [this.dynamicMethodsSendService.P2PConferenceResponse(conferenceRequestResponse)]
    // );

    let operationMessageForP2PConferenceResponse = this.dynamicMethodsService.prepareUserSettingMessage(
      friendId,
      [this.dynamicMethodsSendService.P2PConferenceResponse(conferenceRequestResponse)])
     this.dynamicMethodsService.publishDynP2PConferenceResponse(this.indexedDBService.userFullInfo.KullaniciId,friendId,operationMessageForP2PConferenceResponse);
  }




}
