<div class="guest">
    <p-dialog class="updateStateMessage" [(visible)]="panelManagerService.guestWarningPopupDisplay" [modal]="true"
    [baseZIndex]="10000" [responsive]="true" header="Uyarı" >
    
        <div class="main-content">
            <p>
                Tüm özelliklerden faydalanmak için çıkış yapın ve <span class="google">GOOGLE</span> hesabı ile üye olun  
            </p>
        </div>
    
        <p-footer class="d-flex justify-content-center">
            <button pButton type="button" label="{{ 'logout' | translate}}" (click)="signOut()"
                class="ui-button-raised ui-button-info popup" style="width: 50%;"></button>
        </p-footer>
    
    </p-dialog>
    
</div>
