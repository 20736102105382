import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation, OnDestroy, ComponentFactoryResolver, Output, EventEmitter, HostListener } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { RoomService } from 'src/app/core/services/room.service';
import { ChatMessage, ChatMessageForBubble, MessageRecords } from 'src/app/core/models/chat-message';
import { ChatMessageService } from 'src/app/core/services/chatMessage.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { ScrollPanel } from 'primeng/scrollpanel';
import { BubbleDirective } from 'src/app/core/component/bubble/bubble.directive';
import { BubbleComponent } from 'src/app/core/component/bubble/bubble.component';
import { ContextMenuService } from 'ngx-contextmenu';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { EditorContextMenuComponent } from '../context-menu/context-menu.component';
import { Subscription } from 'rxjs';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { RoomUser } from 'src/app/core/models/room-user';
import { Nicktype } from 'src/app/core/models/nick-type';
import { MessageBubbleType, MessageSendingType, MessageType, WhoEnterRoomTypes } from 'src/app/core/models/enums';
import { CentrifugoService } from 'src/app/core/centrifugo/services/centrifugo.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { MessagePanelService } from '../../../../services/messagePanel.service';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-room-chat-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

// animations: [fadeInDownAnimation()]

export class RoomChatBubbleComponent implements OnInit, OnDestroy {

  //public messages: ChatMessageForBubble[] = new Array<ChatMessageForBubble>();
  messageFirst: string;
  bubbleAreaItems: MenuItem[];
  userImageItems: MenuItem[];
  isThereRoomOwner: boolean;
  isEmptyRoomMessage: boolean;
  roomUser: RoomUser;
  whoEnterRoomTypes = WhoEnterRoomTypes;

  @Output() editorHeight = new EventEmitter<number>();
  @ViewChild(BubbleDirective, { static: false, read: BubbleDirective }) bubbleHost: BubbleDirective;

  @ViewChild('scrollPanel') scrollPanel: ScrollPanel
  @ViewChild('userimage') userimage: ElementRef<HTMLElement>
  @ViewChild('chatPanelEditorArea') chatPanelEditorArea: ElementRef<HTMLElement>

  @ViewChild('editorArea') editorArea: ElementRef<HTMLElement>

  @ViewChild(EditorContextMenuComponent) roomEditorContextMenuClick: EditorContextMenuComponent;

  bubbleAreaHeight = "calc(100% - 90px)";
  roomMessagesReceivedSubscription;
  roomAllMessagesRemovedSubscription;
  showRoomEditorContextMenu: boolean = false;
  roomUserUpdatedSubscription: Subscription;
  editorFontChangedSubscription: Subscription;

  listOldMessagesSubscription: Subscription;


  isLoading:boolean = false
  isAtTop: boolean = false;
  lastScrollTop: number = 0;
  touchStartY: number = 0;

  nextKey: string = "";
  showScrollDownIcon: boolean = false;
  faArrowDown=faArrowDown
  constructor(
    public roomService: RoomService,
    private chatMessageService: ChatMessageService,
    private chatService: ChatService,
    public utilService: UtilService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private contextMenuService: ContextMenuService,
    private indexedDBService: IndexedDBService,
    private roomUserListService: RoomUserListService,
    private centrifugoService: CentrifugoService,
    public deviceService: DeviceService,
    private messagePanelService: MessagePanelService,

  ) {
    this.listOldMessagesSubscription = this.roomService.listOldMessages.subscribe(s => {
       this.loadOldMessagesAndSetToBubbles(true)
    })
  }

  ngOnInit() {
    this.roomMessagesReceivedSubscription = this.centrifugoService.roomMessagesReceived.subscribe((chatMessage: ChatMessage) => {
      //TODO_PUBLISH migth be better place to check validation
      //TODO Better way for control this part (like chatMessage.SenderRutbeDerece == -1)
      if (chatMessage.SenderName.includes('Misafir-')) { {
          return
        }
      }
      if (chatMessage.MessageBubbleType === MessageBubbleType.AdminChangesMessage) {
        if (chatMessage.SenderRutbeDerece <= this.indexedDBService.userFullInfo.RutbeDerece) {
          this.sendMessageToBubble(chatMessage);
          this.setBrowserTitle();
        }
      }
      else if (chatMessage.MessageSendingType === MessageSendingType.GenelAdmin) {
        if (this.indexedDBService.userFullInfo.RutbeDerece !== 0) {
          this.sendMessageToBubble(chatMessage);
          this.setBrowserTitle();
        }
      }
      else {
        this.sendMessageToBubble(chatMessage);
        this.setBrowserTitle();
      }
    });

    this.roomAllMessagesRemovedSubscription = this.roomService.roomAllMessagesRemoved.subscribe(s => {
      this.clearAllMessages();
    });

    this.roomUserUpdatedSubscription = this.roomUserListService.roomUserUpdated.subscribe((roomUser: RoomUser) => {
      this.roomUser = roomUser;
    });

    this.editorFontChangedSubscription = this.chatService.editorFontChanged.subscribe((fontSettings: Nicktype) => {
      this.setBubbleAreaHeight();
    });

    setTimeout(() => { this.setBubbleAreaHeight(); }, 100);

    this.isThereRoomOwner = this.utilService.isNullOrEmtpyObject(this.roomService.currentRoom.OwnerInfo);
    this.isEmptyRoomMessage = this.utilService.IsNullOrWhitespace(this.roomService.currentRoom.Info?.ODAMESAJ)

  }

  ngOnDestroy() {
    if (this.roomMessagesReceivedSubscription)
      this.roomMessagesReceivedSubscription.unsubscribe();

    if (this.roomAllMessagesRemovedSubscription)
      this.roomAllMessagesRemovedSubscription.unsubscribe();

    if (this.roomUserUpdatedSubscription)
      this.roomUserUpdatedSubscription.unsubscribe();

    if (this.editorFontChangedSubscription)
      this.editorFontChangedSubscription.unsubscribe();

    if(this.listOldMessagesSubscription){
      this.listOldMessagesSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.addScrollEventListener();
  }

  addScrollEventListener() {
    const scrollContent = this.scrollPanel.contentViewChild.nativeElement;
    if (scrollContent) {
      scrollContent.addEventListener('scroll', this.onScroll.bind(this));
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    const target = event.target as HTMLElement;
    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    this.isAtTop = scrollTop === 0;
    if (this.isAtTop && !this.isLoading) {
      if (this.nextKey !== "") {
        this.loadOldMessagesAndSetToBubbles();
      }
    }
    this.showScrollDownIcon = scrollTop + clientHeight < scrollHeight;
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    if (event.deltaY < 0 && this.isAtTop && !this.isLoading) {
        if (this.nextKey !== "") {
          this.loadOldMessagesAndSetToBubbles()
        }
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    const touch = event.touches[0];
    this.touchStartY = touch.clientY;
    const target = event.target as HTMLElement;
    this.isAtTop = target.scrollTop === 0;
  }


  scrollToBottom() {
    setTimeout(() => { 
      this.scrollPanel.scrollTop(99999999);
      this.showScrollDownIcon = false;
     });
  }

  loadOldMessagesAndSetToBubbles(isFirst?:boolean){
      this.isLoading = true
      const roomId = this.roomService.currentRoom.Info.ID
      this.chatService.getRoomChatOldMessages(roomId, this.nextKey).then((response:MessageRecords)=>{
        if (response.data != null) {
          let data = response
          data.data.forEach((chatMessage:ChatMessage) => {
              this.sendOldMessageToBubble(chatMessage);
          })
        }
        this.nextKey = response.next
    }).finally(() => {
      if (isFirst) {
        this.scrollToBottom()
      }
      this.isLoading = false
    })
  }

  sendOldMessageToBubble(chatMessage: ChatMessage) {
    this.chatMessageService.getChatMessageForRoomBubble(chatMessage)
      .then((chatMessageForBubble: ChatMessageForBubble) => {
        if (chatMessageForBubble) {
          if(chatMessageForBubble.messageType == MessageType.Text) {
            chatMessageForBubble.messageHtml = chatMessageForBubble.messageHtml.map(html => { return this.utilService.parseGetMessageValue(html) })
            var el = document.createElement('html');
            el.innerHTML = chatMessageForBubble.messageHtml[0];
            if (!this.utilService.isHTML(el.getElementsByTagName('div')[0].innerHTML)) {
              this.setRoomUser(chatMessageForBubble.senderId);
              this.appendToBubblesToFirst(chatMessageForBubble);
            }
          }else{
            this.setRoomUser(chatMessageForBubble.senderId);
            this.appendToBubblesToFirst(chatMessageForBubble);
          }
        }
      })
      .catch(error => {
        console.log(error);
      });

    //TODO scroll to botttom
  }

  appendToBubblesToFirst(chatMessageForBubble: ChatMessageForBubble) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(BubbleComponent);
      let viewContainerRef = this.bubbleHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory,0);
      (<BubbleComponent>(componentRef.instance)).set(chatMessageForBubble);
    } catch (error) {
      console.log(error);
    }
  }

  setRoomUser(userId: number) {
    // this.roomUser = this.roomService.roomUserList.find(f => f.KullaniciId === userId);
    this.roomUser = this.roomService.getRoomUserFromActiveListByUserId(userId);
  }

  public onContextMenu($event: MouseEvent): void {
    if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
      return
    }
    this.showRoomEditorContextMenu = true;
    setTimeout(() => {
      this.contextMenuService.show.next({
        contextMenu: this.roomEditorContextMenuClick.contextMenu,
        event: $event,
        item: this.indexedDBService.userFullInfo.RutbeFullInfo
      });

    }, 100);


    $event.preventDefault();
    $event.stopPropagation();

  }

  setBrowserTitle() {
    if (!this.roomService.roomFocused)
      this.roomService.messageCount = 1;
  }

  editorTextChange($event) {
    this.setBubbleAreaHeight();
  }

  setBubbleAreaHeight() {
    setTimeout(() => {
      if (this.chatPanelEditorArea) {
        this.bubbleAreaHeight = "calc(100% - " + this.chatPanelEditorArea.nativeElement.offsetHeight + "px)";
        this.editorHeight.emit(this.chatPanelEditorArea.nativeElement.offsetHeight);
      }
    });
  }

  clearAllMessages() {
    if (this.bubbleHost && this.bubbleHost.viewContainerRef)
      this.bubbleHost.viewContainerRef.clear();
  }

  sendMessageToBubble(chatMessage: ChatMessage) {
    this.chatMessageService.getChatMessageForRoomBubble(chatMessage)
      .then((chatMessageForBubble: ChatMessageForBubble) => {
        if (chatMessageForBubble) {
          if(chatMessageForBubble.messageType == MessageType.Text) {
            chatMessageForBubble.messageHtml = chatMessageForBubble.messageHtml.map(html => { return this.utilService.parseGetMessageValue(html) })
            var el = document.createElement('html');
            el.innerHTML = chatMessageForBubble.messageHtml[0];
            if (!this.utilService.isHTML(el.getElementsByTagName('div')[0].innerHTML)) {
              this.setRoomUser(chatMessageForBubble.senderId);
              this.appendToBubbles(chatMessageForBubble);
              setTimeout(()=>{
                this.scrollToBottom();
              },100)
            }
          }else{
            this.setRoomUser(chatMessageForBubble.senderId);
            this.appendToBubbles(chatMessageForBubble);
            setTimeout(()=>{
              this.scrollToBottom();
            },100)
          }
         
        }
      })
      .catch(error => {
        console.log(error);
      });

    //TODO scroll to botttom
  }


  appendToBubbles(chatMessageForBubble: ChatMessageForBubble, index?: number) {
    try {
      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(BubbleComponent);
      let viewContainerRef = this.bubbleHost.viewContainerRef;
      let componentRef = viewContainerRef.createComponent(componentFactory, index !== 0 ? viewContainerRef.length : index);
      (<BubbleComponent>(componentRef.instance)).set(chatMessageForBubble, this.roomUser);

      if (this.bubbleHost.viewContainerRef.length > 30) {
        this.bubbleHost.viewContainerRef.remove(0);
      }
      
    } catch (error) {
      console.log(error);
    }
  }




}