import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceInformation } from '../../core/services/serviceinformation.service';
import { UtilService } from 'src/app/core/services/util.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { KeepAdminLog } from '../models/keep-admin-log';
import { LanguageService } from 'src/app/core/services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends ServiceInformation implements OnDestroy {
  changedLanguageSubscription: Subscription;
  adminLoading: boolean = false;
    
  selectedTabFromPublic: string = "";

  constructor(
    private http: HttpClient,
    protected utilService: UtilService,
    protected messagePanelService: MessagePanelService,
    protected indexedDBService: IndexedDBService,
    protected languageService: LanguageService,
    protected translateService: TranslateService
  ) {
    super(messagePanelService, utilService, indexedDBService)

    this.languageService.initChangeLanguageEventsForChildModules(this.translateService, this.languageService, this.changedLanguageSubscription);
    //this.translateService.use("tr");
  }
  ngOnDestroy(): void {
    if (this.changedLanguageSubscription) {
      this.changedLanguageSubscription.unsubscribe();
    }
  }

  public saveAdminActions(body): Observable<any> {
    body["ClientInfo"] = this.Get_ClientInfo();
    const endpoint = this.ServiceUrl + 'SaveAdminActions';
    return this.http.post<any>(endpoint, body, this.options)
      .pipe(catchError(error => this.handleError(error)));
  }

  public callSaveAdminActions(adminActionMessage: string, adminActionTarget: string): Promise<void> {
    let body = {
      'ClientInfo': null,
      'Ip': null,
      'UserId': this.indexedDBService.userFullInfo.KullaniciId,
      'RutbeDerece': this.indexedDBService.userFullInfo.RutbeDerece,
      'Target': adminActionTarget,
      'Message': adminActionMessage,
    };

    return new Promise((resolve, reject) => {
      this.saveAdminActions(body)
        .subscribe(response => {
          if (this.utilService.isNullOrUndefined(response))
            reject();

          if (response === true)
            resolve();
          else
            reject();
        },
          error => {
            reject();
            console.log(error);
          });
    });
  }

  // public getAuthorizedUserDetailById(body): Observable<any> {
  //   body["ClientInfo"] = this.Get_ClientInfo();
  //   const endpoint = this.ServiceUrl + 'GetAuthorizedUserDetailById';
  //   return this.http.post<any>(endpoint, body, this.options)
  //     .pipe(catchError(error => this.handleError(error)));
  // }  

  createAdminLog(keepAdminLog: KeepAdminLog, obj) {
    keepAdminLog.AdminLog = "";
    for (var i = 0; i < obj.length; i++) {
      this.addToAdminLog(
        keepAdminLog,
        this.utilService.TagStart + obj[i].localizationkey + this.utilService.TagEnd,
        obj[i].oldSetting,
        obj[i].newSetting,
        obj[i].localizationkey
      );
    }
    return keepAdminLog.AdminLog;
  }

  addToAdminLog(keepAdminLog, key, oldValue, newValue, localizationkey) {
    if (oldValue !== newValue) {
      keepAdminLog.AdminLog += key + " [" + this.utilService.TagStart + "oldvalue" + this.utilService.TagEnd + " = " + oldValue + " ] - " +
        "[" + this.utilService.TagStart + "newvalue" + this.utilService.TagEnd + " = " + newValue + " ]. <br/>";
    } else if (oldValue === "?" && newValue === "?") {
      keepAdminLog.AdminLog += key + "<br/>";
    }
    else if(localizationkey === 'Nickname'){
      keepAdminLog.AdminLog += key + " [" + this.utilService.TagStart + "oldvalue" + this.utilService.TagEnd + " = " + oldValue + " ] - " +
      "[" + this.utilService.TagStart + "newvalue" + this.utilService.TagEnd + " = " + newValue + " ]. <br/>";
    }

  }
}