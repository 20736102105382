import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { Component, OnDestroy } from '@angular/core';
import { AboutUsService } from '../../../admin/tabs/about-us/services/about-us.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentVariableService } from '../../services/environment-variable.service';

@Component({
  selector: 'app-who-are-we-page',
  templateUrl: './who-are-we-page.component.html',
  styleUrls: ['./who-are-we-page.component.scss']
})
export class WhoAreWePageComponent implements OnDestroy {
  htmlContent: string;
  displayWeAreWeFile: Subscription;
  envVariable: Subscription

  constructor(private aboutUsService: AboutUsService,
    public panelManagerService: PanelManagerService,
    public environmentVariableService: EnvironmentVariableService,
    private http: HttpClient) {

    this.displayWeAreWeFile = this.aboutUsService.displayWhoAreWeFile.subscribe((res) => {
      this.environmentVariableService.getHtmlElementByLink(res).subscribe(
        (content: string) => {
          this.htmlContent = content;
          this.panelManagerService.whoWeArePageDisplay = true;
        },
        (error) => {
          console.error('Error loading HTML content', error);
        }
      );
    });
    this.envVariable = this.environmentVariableService.sentEnvironmentVariable.subscribe(res => {
      this.setHtmlContentWithLink(res.BizKimiz)
    })
  }

  ngOnInit(): void {
  }
  setHtmlContentWithLink(link: string) {
    this.environmentVariableService.getHtmlElementByLink(link).subscribe(
      (content: string) => {
        this.htmlContent = content;
      }
    )
  }

  ngOnDestroy(): void {
    if (this.displayWeAreWeFile) {
      this.displayWeAreWeFile.unsubscribe();
    }
    if (this.envVariable) {
      this.envVariable.unsubscribe();
    }
  }
}
