import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { RoomService } from '../../services/room.service';
import { UtilService } from 'src/app/core/services/util.service';
import { FavoriteRoom, Room, CurrentRoom, RoomJson, RoomJsonData } from 'src/app/core/models/room';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { MainPanels } from 'src/app/core/models/enums';
import { DeviceService } from 'src/app/core/services/device.service';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { RoomUserListService } from 'src/app/core/services/room-user-list-service';
import { faUsers, faTimes, faSearch, faEye, faHeart, faChevronLeft, faChevronRight, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { AdminRoomService } from 'src/app/admin/tabs/rooms/services/room.service';
import { debounceTime } from 'rxjs/operators';
import { Constants } from '../../models/constants';
@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss', './room-list.mobile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomListComponent implements OnInit, OnDestroy {
  //faStar = faStar;
  faUsers = faUsers;
  faTimes = faTimes;
  faSearch = faSearch;
  faEye = faEye;
  faHeart = faHeart;

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  @Input() userinfo: any;
  searchKey: any = '';
  favoriteRooms: Array<FavoriteRoom>;
  isFavoriteRoomsTabs = false;
  rooms: Array<Room>;
  roomInfoChangedSubscription: Subscription;
  roomFavoriteAddedRemovedSubscription: Subscription;
  roomUserCountUpdatedSubscription: Subscription;
  roomInfoUpdatedSubscription: Subscription;
  newRoomAddedSubscription: Subscription;
  roomDeletedSubscription: Subscription;
  clickEnterRoomSubscription: Subscription;
  isFiltering: boolean = false;

  eyeImage: string;

  @ViewChild('searchText', { static: false }) searchText: ElementRef;
  @ViewChild('roomChatPanel') roomChatPanel: ElementRef<HTMLElement>;

  roomBackgroundUrl: string = Constants.S3AccountCentralUrl+ '/roomfavoritebuttons/';
  constructor(
    public roomService: RoomService,
    public panelManagerService: PanelManagerService,
    public indexedDBService: IndexedDBService,
    public deviceService: DeviceService,
    public utilService: UtilService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private roomUserListService: RoomUserListService,
    private adminRoomService: AdminRoomService
  ) {

    this.eyeImage = "../../../../assets/images/viewroom.png";

    this.filterList();
    this.roomInfoChangedSubscription = this.roomService.roomInfoChanged.subscribe((res) => {
      if (res) {
        this.filterList();
      }
    });

    this.roomFavoriteAddedRemovedSubscription = this.roomService.favoriteRoomUpdated.subscribe(() => {
      this.rooms = this.indexedDBService.Rooms;
    });

    this.roomUserCountUpdatedSubscription = this.roomUserListService.roomUserCountUpdated.subscribe((count) => {
      this.roomUserCountUpdated(count);

    });

    this.clickEnterRoomSubscription = this.roomService.clickSubject.pipe(debounceTime(250))
      .subscribe(roomId => {
        this.roomService.enterRoomNew(roomId);
      });
      
    this.newRoomAddedSubscription = this.adminRoomService.newRoomAdded.subscribe((addedRoom: Room) => {

      this.rooms.push(addedRoom);
      this.indexedDBService.Rooms.push(addedRoom);
      this.rooms = this.indexedDBService.Rooms;
    });

    this.roomDeletedSubscription = this.roomService.roomDeleted.subscribe((deletedRoomId: number) => {

      this.indexedDBService.Rooms = this.indexedDBService.Rooms.filter(f => f.I !== deletedRoomId);
      this.rooms = this.indexedDBService.Rooms;
    });

    this.roomInfoUpdatedSubscription = this.roomService.roomInfoUpdated.subscribe((currentRoom: CurrentRoom) => {

      if (!this.utilService.isNullOrEmtpyObject(this.rooms.find(f => f.I === currentRoom.Info.ID))) {

        this.rooms.find(f => f.I === currentRoom.Info.ID).N = currentRoom.Info.NAME;
        this.rooms.find(f => f.I === currentRoom.Info.ID).M = currentRoom.Info.ODAMESAJ;
      }

      if (!this.utilService.isNullOrEmtpyObject(this.roomService.favoriteRooms.find(f => f.OdaId === currentRoom.Info.ID))) {

        this.roomService.favoriteRooms.find(f => f.OdaId === currentRoom.Info.ID).OdaAdi = currentRoom.Info.NAME;
      }
    });
  }

  ngOnInit() {
    this.indexedDBService.RoomListJsonCurrentFileIndex = -1;
    this.roomService.refreshRoomListAndAnimations();
  }

  ngOnDestroy() {
    if (this.roomInfoChangedSubscription)
      this.roomInfoChangedSubscription.unsubscribe();

    if (this.roomFavoriteAddedRemovedSubscription)
      this.roomFavoriteAddedRemovedSubscription.unsubscribe();

    if (this.roomUserCountUpdatedSubscription)
      this.roomUserCountUpdatedSubscription.unsubscribe();

    if (this.roomInfoUpdatedSubscription)
      this.roomInfoUpdatedSubscription.unsubscribe();

    if (this.newRoomAddedSubscription)
      this.newRoomAddedSubscription.unsubscribe();

    if (this.roomDeletedSubscription) {
      this.roomDeletedSubscription.unsubscribe();
    }
    if (this.clickEnterRoomSubscription) {
      this.clickEnterRoomSubscription.unsubscribe();
    }

  }

  previousPage(){
    if (this.roomService.pageIndexForRoomList == 1) {
      return
    }
    let pageIndex = this.roomService.pageIndexForRoomList -1 ;
    this.roomService.expandRoomlist(pageIndex);
  }

  nextPage(){
    if (this.roomService.allPageCountForRoomList == this.roomService.pageIndexForRoomList) {
      return
    }
    let pageIndex = this.roomService.pageIndexForRoomList + 1 ;
    this.roomService.expandRoomlist(pageIndex);

  }

  viewRoom(roomId: number, event:Event) {
    event.stopPropagation();
    if (this.indexedDBService.userFullInfo?.RutbeFullInfo?.ODADA_KULLANICI_LISTELE && this.panelManagerService.roomOpened) {
      if (this.roomService.currentRoom.Info.ID === roomId) {
        //this.panelManagerService.openRoomPage();
        this.panelManagerService.viewTheRoomPopupDisplay = true;
        this.roomService.viewingRoomInfo = this.roomService.currentRoom.Info;
        setTimeout(() => {
          this.roomService.viewRoomUserListDoneSource.next(true);
        }, 500);
      }
      else {
        if (this.roomService.roomUserListForViewTheRoom.length > 0) {
          for (var i = 0; i < this.roomService.roomUserListForViewTheRoom.length; i++) {
            // if (!this.roomService.roomUserList.find(f => f.KullaniciId === this.roomService.roomUserListForViewTheRoom[i].KullaniciId))
            if (!this.roomService.getRoomUserFromActiveListByUserId(this.roomService.roomUserListForViewTheRoom[i].KullaniciId))
              delete this.roomUserListService.userViewInRoom[this.roomService.roomUserListForViewTheRoom[i].KullaniciId];
          }
        }
        this.roomService.roomUserListForViewTheRoom = [];
        this.roomService.controlBeforeEnterRoom(roomId, false, true)
          .then(() => {
            //this.panelManagerService.openRoomPage();
            this.panelManagerService.viewRoomLoading = true;
            this.panelManagerService.viewTheRoomPopupDisplay = true;
          })
          .then(() => {
            // return this.roomService.getRoomUserList(true, roomId)
           return this.roomService.getRoomUserListForView(roomId)
          })
          .then(() => {
            this.roomService.viewRoomUserListDoneSource.next(false);
            this.panelManagerService.viewRoomLoading = false;
          })
          .catch((err) => {
            console.log(err)
          })
      }

    }
  }

  roomUserCountUpdated(count: number) {
    this.rooms.find(f => f.I === this.roomService.currentRoom.Info.ID).O = count;
  }

  closePanel() {
    this.panelManagerService.activeMainPanel = MainPanels.None;
  }

  enterRoom(roomId) {
    this.roomService.clickSubjectSource.next(roomId);
  }

  onkeyUp(event) {
    if (event.target.value)
      this.searchKey = event.target.value;
    else
      this.searchKey = '';
    this.filterList()
  }

  clearSearchText() {
    this.searchKey = "";
    this.searchText.nativeElement.value = "";
    this.filterList();
  }

  filterList() {
    this.favoriteRooms = this.roomService.favoriteRooms;
    let filteresRooms;
    if (this.searchKey) {
      this.isFiltering = true;
      this.roomService.searchRoomByNameOrId(this.searchKey).then((rooms:RoomJsonData) => {
        this.rooms = rooms.R

      })
    } else {
      this.isFiltering = false;
      filteresRooms = [...this.indexedDBService.Rooms]
    }

    if (this.isFavoriteRoomsTabs && this.favoriteRooms)
      filteresRooms = filteresRooms.filter((item) => this.favoriteRooms.map(m => m.OdaId).includes(item.I));

    // filteresRooms.sort(this.sortRoomList);
    this.rooms = filteresRooms;
  }

  /* sortRoomList(a: Room, b: Room) {
     if (a.O > b.O)
       return -1;
     else if (a.O < b.O)
       return 1;
     else
       return 0;
   }*/

  removeFromFavoriteRoom(roomId: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('removeFavoriteRoomWarning'),
      header: this.translateService.instant('Warning'),
      icon: null,
      accept: () => {
        this.roomService.removeFromFavoriteRooms(roomId).then(() => {
        }).catch(error => {
        });
        return false;
      },
      reject: () => {
        return false;
      }
    });
  }

  addToFavoriteRoom(roomId: number) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: this.translateService.instant('addFavoriteRoomWarning'),
      header: this.translateService.instant('Warning'),
      icon: null,
      accept: () => {
        this.roomService.addToFavoriteRooms(roomId)
          .then(() => {
          })
          .catch(error => {
          });
        return false;
      },
      reject: () => {
        return false;
      }
    });
  }
}