<router-outlet></router-outlet>
<!-- *ngIf="confirmationDialogService.loadConfirmationDialog"
*ngIf="messagePanelService.displayMessagePanel" -->
<div class="about-us-section">
  <app-who-are-we-page></app-who-are-we-page>
  <app-usage-agreement-page></app-usage-agreement-page>
  <app-legal-warning-page></app-legal-warning-page>
</div>

<app-confirmation-dialog></app-confirmation-dialog>

<app-message-panel ></app-message-panel>


<!-- <div class="loading" [style.display]="panelManagerService.mainLoading?'block':'none'">
    <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/loading2.gif" />
  </div> -->

<div [style.display]="panelManagerService.mainLoading?'block':'none'" class="main-loading">
  <p-progressSpinner [style]="{width: '70px', height: '70px'}" strokeWidth="3" fill="#EEEEEE" animationDuration=".5s">
  </p-progressSpinner>
</div>


<app-report *ngIf="reportService.reportPopupDisplay"></app-report>

<div class="connectionLoading" [style.display]="panelManagerService.connectionLoading?'block':'none'">
  <img src="https://sesliturkiye.azureedge.net/themes/SesliTurkiye/loading2.gif" />
  <span>{{'Please wait connection...' | translate}}</span>
</div>


<!-- <app-version-control-dialog></app-version-control-dialog> -->
<app-media-device-access-modal [(isVisible)]="panelManagerService.mediaDeviceModalVisible"
  (onAllow)="onMediaAccessAllow($event)" [header]="'header'" [message]="'message'" [autoCheck]="true">
</app-media-device-access-modal>

<p-confirmDialog [baseZIndex]="999999999999"></p-confirmDialog>