import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Constants } from 'src/app/core/models/constants';
import { SelectItem } from 'primeng/api';
import { Nicktype } from 'src/app/core/models/nick-type';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { FontService } from 'src/app/core/services/font.service';
import { AdminModeratorService } from '../../../../admin/tabs/moderators/services/moderator.service';
@Component({
  selector: 'app-change-font',
  templateUrl: './change-font.component.html',
  styleUrls: ['./change-font.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class ChangeFontComponent implements OnInit {


  fontSizes: SelectItem[] = [];
  colors: SelectItem[] = [];

  nicktype: Nicktype;

  constructor(
    private messagePanelService: MessagePanelService,
    private indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    private chatService: ChatService,
    public fontService: FontService,
    private adminModeratorService: AdminModeratorService
  ) {
    this.getDefaultFontSettings();
    this.fillFontOptions();
  }

  ngOnInit() {
  }

  getDefaultFontSettings() {
    this.nicktype = Object.assign({}, this.indexedDBService.userFullInfo.NicktypeForRoom);
  }

  fillFontOptions() {
    Constants.AVAILABLECOLORS.forEach(element => {
      this.colors.push({ label: element, value: element.toLowerCase() });
      // this.colors.push({'label': element, 'value': element.toLowerCase() });
    });

    Constants.AVAILABLESIZES.forEach(element => {
      this.fontSizes.push({ 'label': element.toString(), value: element });
    });
  }

  saveFontSettings() {
    this.messagePanelService.ShowPopupMessageWithLocalization('FontSettingSaved', 2000);
    this.panelManagerService.changeFontPopupDisplay = false;
    this.indexedDBService.userFullInfo.NicktypeForRoom = this.nicktype;
    this.nicktype.C = this.nicktype.C.charAt(0).toLowerCase() + this.nicktype.C.slice(1);
    this.updateIndexedDbFontSettings();

    this.adminModeratorService.callSaveUserNickSettings(this.indexedDBService.userFullInfo.KullaniciId, JSON.stringify(this.nicktype), 2, JSON.stringify(null), this.indexedDBService.userFullInfo.KullaniciAdi);
  }

  updateIndexedDbFontSettings() {
    this.indexedDBService.updateNicktype(this.nicktype, this.indexedDBService.userFullInfo.KullaniciAdi)
      .then(() => {
        this.indexedDBService.userFullInfo.NicktypeForRoom = this.nicktype;
        this.chatService.editorFontChangedSource.next(this.nicktype);
      })
      .catch(err => {
        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
      });
  }
}