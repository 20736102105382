import { element } from 'protractor';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApplicationData, EnvironmentVariable, UpdateAppAndDomainName, UpdateCapacitorConfAndLogoModel, UpdateDocuments, UpdateImage, UpdateMetaTagModel, UpdateShareRecord } from '../models/environmentVariables';
import { MessagePanelService } from '../../../../core/services/messagePanel.service';

@Injectable({
  providedIn: 'root'
})
export class AboutUsService {

  appNames: any[] = [];
  domainNames: any[] = [];

  isEditMode = false;
  apiUrl = `${environment.apiUrl}`

  selectedAppName: any
  selectedDomainName: any

  selectedAudioPreset : any
  audioPresetsOptions: any[] = [];

  //is logos edited in edit mode
  isLogoSEditedInEditMode = false;
  isLogoMEditedInEditMode = false;
  isLogoLEditedInEditMode = false;
  isLogoXLEditedInEditMode = false;
  isIconOnlyEditedInEditMode=false
  isLogoEditedInEditMode=false
  isLogoDarkEditedInEditMode=false
  isSplashEditedtInEditMode=false
  isSplashDarkEditedInEditMode=false

  //is document edited
  isWhoAreWeEdited = false;
  isLegalWarningEdited = false;
  isUsageAggrementEdited = false;

  //Env variable
  id: number;
  AlanAdi: string;
  UygulamaAdi: string;
  LogoS: string;
  LogoM: string;
  LogoL: string;
  LogoXL: string;
  BizKimiz: string;
  YasalUyari: string;
  KullanimSozlesmesi: string;
  YetkiAlimSatim: string;
  CapacitorConfig:string;
  IconOnly:string;
  Logo: string;
  LogoDark: string;
  Splash:string;
  SplashDark:string
  Keyword:string
  Title:string
  Description:string
  CanScreenShare:boolean = false
  CanRecord:boolean = false
  AudioQuality :number 
  IsSipOn:boolean = false


  //Local logos
  localLogoS: string;
  localLogoM: string;
  localLogoL: string;
  localLogoXL: string;
  localIconOnly:string
  localLogo:string
  localLogoDark:string
  localSplash:string
  localSplashDark:string

  //subject
  displayUsageAggrementFileSource: Subject<any> = new Subject();
  displayUsageAggrementFile = this.displayUsageAggrementFileSource.asObservable();

  displayWhoAreWeFileSource: Subject<any> = new Subject();
  displayWhoAreWeFile = this.displayWhoAreWeFileSource.asObservable();

  displayLegalWarningFileSource: Subject<any> = new Subject();
  displayLegalWarningFile = this.displayLegalWarningFileSource.asObservable();


  constructor(private http: HttpClient,
    private messagePanelService: MessagePanelService) { }

  createEnvironmentVariables() {
    if (!this.validateInputs()) {
      return;
    }
    const body = {
      AlanAdi: this.AlanAdi,
      UygulamaAdi: this.UygulamaAdi,
      LogoS: this.LogoS,
      LogoM: this.LogoM,
      LogoL: this.LogoL,
      LogoXL:this.LogoXL, 
      BizKimiz: this.BizKimiz,
      YasalUyari: this.YasalUyari,
      KullanimSozlesmesi: this.KullanimSozlesmesi,
      YetkiAlimSatim: this.YetkiAlimSatim,
      CapacitorConfig:this.CapacitorConfig,
      IconOnly:this.IconOnly,
      Logo:this.Logo,
      LogoDark:this.LogoDark,
      Splash:this.Splash,
      SplashDark:this.SplashDark,
      Keyword:this.Keyword,
      Title:this.Title,
      Description:this.Description,
      CanScreenShare:this.CanScreenShare,
      CanRecord:this.CanRecord,
      AudioQuality :this.AudioQuality,
      IsSipOn:this.IsSipOn        
    };

    const path = this.apiUrl + 'CreateOrtamDegiskenleri';
    return this.http.post<any>(path, body);
  }

  validateInputs() {
    if (
      !this.AlanAdi ||
      !this.UygulamaAdi ||
      !this.LogoS ||
      !this.LogoM ||
      !this.LogoL ||
      !this.LogoXL ||
      !this.BizKimiz ||
      !this.YasalUyari ||
      !this.KullanimSozlesmesi ||
      !this.YetkiAlimSatim ||
      !this.Keyword ||
      !this.Title ||
      !this.Description
    ) {
      this.messagePanelService.ShowPopupMessageWithLocalization('check-all-input', 2000);
      return false;
    }

    if (this.checkIfAppDomainNameExists(this.domainNames, this.AlanAdi)) {
      this.messagePanelService.ShowPopupMessageWithLocalization('domainName-already-exist', 2000);
      return false;
    }

    if (this.checkIfAppDomainNameExists(this.appNames, this.UygulamaAdi)) {
      this.messagePanelService.ShowPopupMessageWithLocalization('appName-already-exist', 2000);
      return false;
    }

    return true;
  }
  
  checkIfAppDomainNameExists(items: any[], itemName: string): boolean {
    return items.some((item) => item.name === itemName);
  }

  refreshAppDomainNames(): Promise<void> {
    this.appNames = [];
    this.domainNames = [];
    return new Promise<void>((resolve, reject) => {
      this.getAllAppAndDomainName().subscribe(
        res => {
          res.Data.forEach(element => {
              this.appNames.push({ name: element.UygulamaAdi });
              this.domainNames.push({ name: element.AlanAdi });
          });
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }
  

  getEnvironmentVariableByAppName(appName: string) :Promise<EnvironmentVariable>{
    let body = {
      UygulamaAdi: appName
    }
    const path = this.apiUrl + 'GetOrtamDegiskenleri';
    return this.http.post<EnvironmentVariable>(path, body).toPromise();
  }

  getAllAppAndDomainName() {
    const path = this.apiUrl + 'GetAppDomainList';
    return this.http.get<ApplicationData>(path);
  }

  updateAppAndDomainName(updateAppAndDomainName: UpdateAppAndDomainName) {
    const path = this.apiUrl + 'UpdateAppDomain';
    return this.http.post<any>(path, updateAppAndDomainName);
  }

  updateMetaTags(updateMetaTagModel: UpdateMetaTagModel) {
    const path = this.apiUrl + 'UpdateOrtamDegiskenleriMetaTags';
    return this.http.post<any>(path, updateMetaTagModel);
  }

  updateValues() {
    if (this.isEditMode) {
      this.updateAppAndDomain();
      this.updateDocumentsIfEdited();
      this.updateImagesIfEdited();
      this.updateCapacitorImagesIfEdited();
      this.CallUpdateMetaTags();
      this.CallUpdatShareRecord();
    }
  }

  updateAppAndDomain() {
    if (this.selectedAppName.name != this.UygulamaAdi && this.selectedDomainName.name != this.AlanAdi) {
      if (this.checkIfAppDomainNameExists(this.domainNames, this.AlanAdi)) {
        this.messagePanelService.ShowPopupMessageWithLocalization('domainName-already-exist', 2000);
        return;
      }
      if (this.checkIfAppDomainNameExists(this.appNames, this.UygulamaAdi)) {
        this.messagePanelService.ShowPopupMessageWithLocalization('appName-already-exist', 2000);
        return;
      }
      let updateModel = this.getAppDomainElementForUpdate();
      this.updateAppAndDomainName(updateModel).subscribe(
        res => {
          this.handleSuccess('app-domain-updated-success', updateModel.NewUygulamaAdi);
          this.refreshAppDomainNames().then(() => this.updateSelectedItem());
        },
        catchError => this.handleFailure('app-domain-updated-fail')
      );
    }
  }

  CallUpdateMetaTags() {
    if (this.Keyword && this.Title && this.Description) {
      let updateModel = this.getMetaElementElementForUpdate();
      this.updateMetaTags(updateModel).subscribe(
        res => {
          this.setEnvironmentVeriableByAppName(this.selectedAppName.name);
          this.refreshAppDomainNames().then(() => this.updateSelectedItem());
        },
        catchError => this.handleFailure('meta-tags-updated-fail')
      );
    }
  }
  CallUpdatShareRecord() {
      this.updateShareRecord().subscribe(
        res => {
          this.handleSuccess('update-success', this.selectedAppName.name);
          this.refreshAppDomainNames().then(() => this.updateSelectedItem());
        },
        catchError => this.handleFailure('Update Successful')
      );
  }
  updateDocumentsIfEdited() {
    if (this.isWhoAreWeEdited && this.isLegalWarningEdited && this.isUsageAggrementEdited) {
      this.updateDocuments().subscribe(
        res => {
          this.handleSuccess('doc-updated-success', this.selectedAppName.name);
          this.updateSelectedItem();
        },
        catchError => this.handleFailure('doc-updated-fail')
      );
    }
  }

  updateImagesIfEdited() {
    if (this.isLogoSEditedInEditMode && this.isLogoMEditedInEditMode && this.isLogoLEditedInEditMode && this.isLogoXLEditedInEditMode) {
      this.updateImages().subscribe(
        res => {
          this.handleSuccess('logos-updated-success', this.selectedAppName.name);
          this.updateSelectedItem();
        },
        catchError => this.handleFailure('logos-updated-fail')
      );
    }
  }

  updateCapacitorImagesIfEdited() {
    if (this.isIconOnlyEditedInEditMode && this.isLogoEditedInEditMode && this.isLogoDarkEditedInEditMode 
      && this.isSplashEditedtInEditMode && this.isSplashDarkEditedInEditMode) {
      this.updateCapLogo().subscribe(
        res => {
          this.handleSuccess('cap-setting-updated', this.selectedAppName.name);
          this.updateSelectedItem();
        },
        catchError => this.handleFailure('cap-setting-update-fail')
      );
    }
  }

  updateCapLogo(){
    let path = this.apiUrl + 'UpdateOrtamDegiskenleriCapacitor';
    let body:UpdateCapacitorConfAndLogoModel= {
      UygulamaId:this.id,
      CapacitorConfig:this.CapacitorConfig,
      IconOnly:this.IconOnly,
      Logo:this.Logo,
      LogoDark:this.LogoDark,
      Splash:this.Splash,
      SplashDark:this.SplashDark
    }
    return this.http.post<any>(path,body);
  }
  handleSuccess(messageKey, appName) {
    this.messagePanelService.ShowPopupMessageWithLocalization(messageKey, 2000);
    this.setEnvironmentVeriableByAppName(appName);
  }

  handleFailure(messageKey) {
    this.messagePanelService.ShowPopupMessageWithLocalization(messageKey, 2000);
  }


  getAppDomainElementForUpdate(): UpdateAppAndDomainName {
    let updateModel: UpdateAppAndDomainName = {
      UygulamaId: this.id,
      OldUygulamaAdi: this.selectedAppName.name,
      NewAlanAdi: this.AlanAdi,
      NewUygulamaAdi: this.UygulamaAdi
    }
    return updateModel
  }
  getMetaElementElementForUpdate(): UpdateMetaTagModel {
    let updateModel: UpdateMetaTagModel = {
      UygulamaId: this.id,
      Description: this.Description,
      Keyword: this.Keyword,
      Title: this.Title
    }
    return updateModel
  }
  updateDocuments() {
    const path = this.apiUrl + 'UpdateOrtamDegiskenleriBelgeler';
    let updateModel: UpdateDocuments = {
      UygulamaId: this.id,
      BizKimiz: this.BizKimiz,
      YasalUyari: this.YasalUyari,
      KullanimSozlesmesi: this.KullanimSozlesmesi,
      YetkiAlimSatim: this.YetkiAlimSatim
    }
    return this.http.post<any>(path, updateModel);
  }

  updateSelectedItem() {
    let appName = this.appNames.find(d => d.name === this.UygulamaAdi);
    if (appName) {
      this.selectedAppName = appName;
    }
    let domainName = this.domainNames.find(d => d.name === this.AlanAdi);
    if (domainName) {
      this.selectedDomainName = domainName;
    }
  }
  setEnvironmentVeriableByAppName(appName: string) {
    this.getEnvironmentVariableByAppName(appName).then(res => {
      this.assignPropertiesFromResponse(res);
    })
  }
  closeLogoEditMode() {
    this.isLogoSEditedInEditMode = false;
    this.isLogoMEditedInEditMode = false;
    this.isLogoLEditedInEditMode = false;
    this.isLogoXLEditedInEditMode = false;
    this.isIconOnlyEditedInEditMode=false
    this.isLogoEditedInEditMode=false;
    this.isLogoDarkEditedInEditMode=false;
    this.isSplashEditedtInEditMode=false;
    this.isSplashDarkEditedInEditMode=false;
  }

  closeDocumentEditMode() {
    this.isWhoAreWeEdited = false;
    this.isLegalWarningEdited = false;
    this.isUsageAggrementEdited = false;
  }

  clearAllEnvironmentElementForNewRecord() {
    this.id = 0
    this.AlanAdi = ""
    this.UygulamaAdi = ""
    this.LogoS = ""
    this.LogoM = ""
    this.LogoL = ""
    this.LogoXL = ""
    this.BizKimiz = ""
    this.YasalUyari = ""
    this.KullanimSozlesmesi = ""
    this.YetkiAlimSatim = ""
    this.CapacitorConfig = "";
    this.IconOnly ="";
    this.Logo ="";
    this.LogoDark = "";
    this.Splash ="";
    this.SplashDark = "";
    this.Keyword = "";
    this.Title = "";
    this.Description = "";
    this.AudioQuality  = null
    this.CanRecord  = null
    this.CanScreenShare  = null
    this.IsSipOn  = null
  }
  assignPropertiesFromResponse(res: EnvironmentVariable) {
    this.id = res.id;
    this.AlanAdi = res.AlanAdi;
    this.UygulamaAdi = res.UygulamaAdi;
    this.LogoS = res.LogoS;
    this.LogoM = res.LogoM;
    this.LogoL = res.LogoL;
    this.LogoXL = res.LogoXL;
    this.BizKimiz = res.BizKimiz;
    this.YasalUyari = res.YasalUyari;
    this.KullanimSozlesmesi = res.KullanimSozlesmesi;
    this.YetkiAlimSatim = res.YetkiAlimSatim;
    this.CapacitorConfig = res.CapacitorConfig,
    this.IconOnly = res.IconOnly,
    this.Logo = res.Logo,
    this.LogoDark = res.LogoDark,
    this.Splash = res.Splash,
    this.SplashDark = res.SplashDark,
    this.Keyword = res.Keyword,
    this.Title = res.Title,
    this.Description = res.Description,
    this.CanScreenShare = res.CanScreenShare,
    this.CanRecord = res.CanRecord,
    this.AudioQuality =res.AudioQuality,
    this.IsSipOn = res.IsSipOn  
  }

  updateImages() {
    const path = this.apiUrl + 'UpdateOrtamDegiskenleriImage';
    let updateModel: UpdateImage = {
      UygulamaId: this.id,
      LogoS: this.LogoS,
      LogoM: this.LogoM,
      LogoL: this.LogoL,
      LogoXL: this.LogoXL
    }
    return this.http.post<any>(path, updateModel);
  }
  updateShareRecord() {
    const path = this.apiUrl + 'UpdateShareRecord';
    let updateModel: UpdateShareRecord = {
      UygulamaId: this.id,
      CanRecord: this.CanRecord,
      CanScreenShare: this.CanScreenShare,
      AudioQuality:this.AudioQuality,
      IsSipOn:this.IsSipOn        

    }
    return this.http.post<any>(path, updateModel);
  }
  populateAudioPresetsOptions() {
    this.audioPresetsOptions = [
      { label: 'Telephone (12_000)', value:12_000  },
      { label: 'Speech (20_000)', value: 20_000 },
      { label: 'Music (32_000)', value: 32_000 },
      { label: 'Music Stereo (48_000)', value: 48_000 },
      { label: 'Music High Quality (64_000)', value: 64_000 },
      { label: 'Music High Quality Stereo (96_000)', value: 96_000 }
    ];
  }

  setSelectedAudioPresetByMaxBitrate() {
    const preset = this.audioPresetsOptions.find(option => option.value === this.AudioQuality);
    if (preset) {
        this.selectedAudioPreset = preset;
    } else {
        this.selectedAudioPreset = null;
    }
}

}
