import { Component, OnInit, HostListener, ViewChild, AfterViewInit, ViewEncapsulation, Input, ElementRef } from '@angular/core';
import { RoomService } from 'src/app/core/services/room.service';
import { RoomChatBubbleComponent } from '../../bubble/bubble.component';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { ChatMessageForBubble } from 'src/app/core/models/chat-message';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Constants, Azure, AzureFolder } from 'src/app/core/models/constants';
import { MessageType } from 'src/app/core/models/enums';
import { ChatService } from 'src/app/core/services/chat.service';
import { AnimationModel } from 'src/app/core/models/animations';

@Component({
  selector: 'app-room-chat-buttons-animations',
  templateUrl: './animations.component.html',
  styleUrls: ['./animations.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomChatButtonsAnimationsComponent implements OnInit, AfterViewInit {

  @Input() isDisplay: boolean = false;
  //animations: Array<any>[];
  animations: AnimationModel[] = []

  Veri24MessengerAnimationsUrl: string;
  Veri24MessengerSoundsUrl: string
  THEME: string;
  chatMessageForBubble: ChatMessageForBubble;
  roomBubble: RoomChatBubbleComponent;
  Veri24MessengerLoadingImage: string;
  S3AccountCentralUrl: string
  d: Date = new Date();
  @ViewChild("animationsControl") animationsControl: ScrollPanel;


  constructor(
    private roomService: RoomService,
    public indexedDBService: IndexedDBService,
    private chatService: ChatService
  ) { }

  ngOnInit() {
    this.S3AccountCentralUrl = Constants.S3AccountCentralUrl + "/";
    this.getAllEmojiGifs();
    this.Veri24MessengerAnimationsUrl = Constants.Veri24MessengerAnimationsUrl;
    this.Veri24MessengerSoundsUrl = Constants.Veri24MessengerSoundsUrl;
    this.THEME = Constants.THEME;
    this.Veri24MessengerLoadingImage = Constants.Veri24MessengerLoadingImage;
  }

  ngAfterViewInit() {

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (this.animationsControl) {
      if (this.animationsControl.el.nativeElement.contains(event.target)) {
        this.isDisplay = false;
      }
    }
  }

  getAllEmojiGifs() {
    this.chatService.getEmojiGifs().subscribe(response => {
      this.animations = response
    })
  }
  ToggleAnimationsPanel() {
    this.isDisplay = !this.isDisplay;
  }

  sendAnimation(animationId) {
    // var html = `<img class="${animationId}" alt="${""}" src="${Azure.path + AzureFolder.AzureAnimationsContainerName}/${animationId}.gif"`;
    this.roomService.currentMessageType = MessageType.Animation;
    this.roomService.sendMessageToRoom(animationId.toString())
      .then(() => {
        this.ToggleAnimationsPanel();
      })
      .catch(error => {
        console.log(error);
      });
  }

  onAnimationError(animation: any) {
    this.indexedDBService.Animations = this.indexedDBService.Animations.filter(a => a !== animation);
  }
}