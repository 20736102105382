export const environment = {
  production: true,
  appVersion: require('../../package.json').version + '-dev',
  checkVersion: true,
  builtAppHash: "",
  centrifugoWebsocketUrl: "wss://centri.zumti.com/connection/websocket",
  jitsiurl: "meetx.turkpages.com",
  webSiteUrl: "https://web.seslidunya.com/",
  apiUrl: "https://v2-be.seslidunya.com/",
  //googleCientId: "572001134774-bveghvbh9jda001oge723ujj45jbrihq.apps.googleusercontent.com", // zumti
  googleCientId: "590432653135-m4etmnl73b4h37pf4peqv3ik951fesm6.apps.googleusercontent.com", // sesli
  //webSiteUrl: "https://web.seslidunya.com/",
  //apiUrl:"https://v2-be.seslidunya.com/"
  natsUrl:"wss://centri.zumti.com",
};