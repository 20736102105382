import { DeviceService } from './../../../services/device.service';
import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AnimationModel } from 'src/app/core/models/animations';
import { ChatMessageForBubble } from 'src/app/core/models/chat-message';
import { RoomChatBubbleComponent } from '../../room/chat/bubble/bubble.component';
import { ScrollPanel } from 'primeng/scrollpanel';
import { Constants } from 'src/app/core/models/constants';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomService } from 'src/app/core/services/room.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { MessageType } from 'src/app/core/models/enums';

@Component({
  selector: 'app-p2p-chat-animation',
  templateUrl: './friend-animation.component.html',
  styleUrls: ['./friend-animation.component.scss']
})
export class P2PChatAnimationComponent implements OnInit, AfterViewInit {

  @Input() isDisplay: boolean = false;
  //animations: Array<any>[];
  animations: AnimationModel[] = []

  Veri24MessengerAnimationsUrl: string;
  Veri24MessengerSoundsUrl: string
  THEME: string;
  chatMessageForBubble: ChatMessageForBubble;
  roomBubble: RoomChatBubbleComponent;
  Veri24MessengerLoadingImage: string;
  S3AccountCentralUrl: string
  d: Date = new Date();
  @ViewChild("animationsControl") animationsControl: ScrollPanel;


  constructor(
    private roomService: RoomService,
    public indexedDBService: IndexedDBService,
    private chatService: ChatService,
    public deviceService:DeviceService
  ) { }

  ngOnInit() {
    this.S3AccountCentralUrl = Constants.S3AccountCentralUrl + "/";
    this.getAllEmojiGifs();
    this.Veri24MessengerAnimationsUrl = Constants.Veri24MessengerAnimationsUrl;
    this.Veri24MessengerSoundsUrl = Constants.Veri24MessengerSoundsUrl;
    this.THEME = Constants.THEME;
    this.Veri24MessengerLoadingImage = Constants.Veri24MessengerLoadingImage;
  }

  ngAfterViewInit() {

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (this.animationsControl) {
      if (this.animationsControl.el.nativeElement.contains(event.target)) {
        this.isDisplay = false;
      }
    }
  }

  getAllEmojiGifs() {
    this.chatService.getEmojiGifs().subscribe(response => {
      this.animations = response
    })
  }
  toggleAnimationsPanel() {
    this.isDisplay = !this.isDisplay;
  }

  sendAnimation(animationId) {
    this.chatService.p2pAnimationSendSource.next(animationId);
    this.toggleAnimationsPanel();
  }

  onAnimationError(animation: any) {
    this.indexedDBService.Animations = this.indexedDBService.Animations.filter(a => a !== animation);
  }
}