import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { CentrifugoSubscription } from './centrifugo.subscription.service';
import { CentrifugoConfig } from '../models/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OperationMessage } from '../../models/operation-message';
import { ChatMessage } from '../../models/chat-message';
import { IndexedDBService } from '../../services/indexed-db.service';
import { environment } from '../../../../environments/environment';
import { UserFullInfo } from '../../models/user';
import { UtilService } from '../../services/util.service';
import { Centrifuge } from 'centrifuge';

declare function require(name: string);

@Injectable({
    providedIn: 'root',
})
export class CentrifugoService {
    // Centrifuge = require('centrifuge');

    client;
    callbacks;
    
    //TODO_SUBJECT
    //tokenTakenSource: Subject<string> = new Subject();
    connettionSuccessSource: Subject<any> = new Subject();

    dynamicMethodsReceivedSource: Subject<OperationMessage> = new Subject();
    dynamicMethodsReceived = this.dynamicMethodsReceivedSource.asObservable();

    roomMessagesReceivedSource: Subject<ChatMessage> = new Subject();
    roomMessagesReceived = this.roomMessagesReceivedSource.asObservable();

    connectionEndedSource: Subject<any> = new Subject();
    connectionEndedReceived = this.connectionEndedSource.asObservable();

    connectionRestartedSource: Subject<any> = new Subject();
    connectionRestartedReceived = this.connectionRestartedSource.asObservable();

    connectionEndedTime: number;
    connectionStartedTime: number;

    publicSubscription: Subscription;
    centrifugoSubscription2;
    JWT: string;

    subscriptions: { [key: string]: CentrifugoSubscription } = {}; //key channel ,

    connected = false;

    constructor(
        private http: HttpClient,
        private indexedDBService: IndexedDBService,
        private utilService: UtilService,
        // private Centrifuge: Centrifuge
    ) {

    }

    init() {

    }

    createNewSubscription(channel: string) {
        let centrifugoSubscription = this.subscriptions[channel];
        if (centrifugoSubscription) return centrifugoSubscription;

        this.subscriptions[channel] = new CentrifugoSubscription();
        this.subscriptions[channel].init(channel);

        return this.subscriptions[channel];
    }

    subscribe(centrifugoSubscription: CentrifugoSubscription): void {
        let channel = centrifugoSubscription.getChannel();
        let subs = this.client.subscribe(channel, centrifugoSubscription.getCallbacks());
        centrifugoSubscription.setChannelSubscription(subs);
    }

    unsubscribe(centrifugoSubscription: CentrifugoSubscription): void {
        let channel = centrifugoSubscription.getChannel();
        centrifugoSubscription.unsubscribeFromChannel();
        setTimeout(() => {
            delete this.subscriptions[channel];
        }, 1000);
    }

    unsubscribeFromChannel(channel: string) {
        this.subscriptions[channel].unsubscribeFromChannel();
        delete this.subscriptions[channel];
    }

    centrifugeClient: Centrifuge
    connect(token: string, tokenP2P: string) {
        var channelName = `user:userid#${this.indexedDBService.userFullInfo.KullaniciId}`
        // Use WebSocket transport endpoint.
        this.centrifugeClient = new Centrifuge(environment.centrifugoWebsocketUrl, {
            token: token
        });

        // Allocate Subscription to a channel.
        var sub = this.centrifugeClient.newSubscription(channelName, {
            token: tokenP2P
        });

        // React on `user:userid#` channel real-time publications.
        sub.on('publication', (ctx) => {
            console.log(`centrifugo.getPublishSource worked context: ${JSON.stringify(ctx.data)}`)
            console.log(`ctx.data.MethodNames: ${ctx.data.MethodNames}`)
            if (ctx.data.MethodNames !== undefined && ctx.data.MethodNames !== null) {
                console.log('on publication first if')
                let operationMessage = ctx.data as OperationMessage;
                this.dynamicMethodsReceivedSource.next(operationMessage);
            }
            else {
                let chatMessage = ctx.data as ChatMessage;
                chatMessage.Time = new Date(chatMessage.Time); // serverda time setlendiğinde string olarak gelecek
                this.roomMessagesReceivedSource.next(chatMessage);
            }
        });

        // Trigger subscribe process.
        sub.subscribe();

        // Trigger actual connection establishement.
        this.centrifugeClient.connect();

        return
        
        console.log(`token : ${token}`)
        console.log(`tokenP2P : ${tokenP2P}`)
        this.client.setToken(token);
        this.client.setToken(tokenP2P);

        let centrifugoSubscription = this.createNewSubscription(`user:userid#${this.indexedDBService.userFullInfo.KullaniciId}`);

        this.client.on('connect', (context) => {
            console.log(`centrifugo.connect worked context: ${JSON.stringify(context)}`)
            this.connectionStartedTime = new Date().getTime();
            this.connected = true;
        });

        this.client.on('disconnect', (context) => {
            console.log(`centrifugo.disconnect worked context: ${JSON.stringify(context)}`)
            this.connectionEndedTime = new Date().getTime();
            this.connected = false;
            this.unsubscribe(centrifugoSubscription)
            this.connectionEndedSource.next();
        });

        this.client.connect();

        centrifugoSubscription.getJoinSource().subscribe((message) => {
            console.log(`centrifugo.getJoinSource worked context: ${JSON.stringify(message)}`)
        });

        centrifugoSubscription.getPublishSource().subscribe((data) => {
            console.log(`centrifugo.getPublishSource worked context: ${JSON.stringify(data)}`)
            if (data.data.MethodNames !== undefined) {
                let operationMessage = data.data as OperationMessage;
                this.dynamicMethodsReceivedSource.next(operationMessage);
            }
            else {
                let chatMessage = data.data as ChatMessage;
                chatMessage.Time = new Date(chatMessage.Time); // serverda time setlendiğinde string olarak gelecek
                this.roomMessagesReceivedSource.next(chatMessage);
            }
        });

        centrifugoSubscription.getLeaveSource().subscribe((data) => {
            if (this.indexedDBService.userFullInfo.KullaniciId !== parseInt(data.info.user)) {
                if (this.indexedDBService.isMyFriend(parseInt(data.info.user))) {

                    let friend: UserFullInfo = new UserFullInfo();

                    this.indexedDBService.Friends.AllFriends = this.indexedDBService.Friends.AllFriends.filter((f: UserFullInfo) => {
                        if (f.KullaniciId === parseInt(data.info.user)) {
                            friend = f;
                            friend.StateId = 2;
                        }

                        return f.KullaniciId !== friend.KullaniciId
                    });

                    this.indexedDBService.Friends.AllFriends.push(friend);
                    this.indexedDBService.refreshFriendListAfterChanges();
                }
            }
        });

        this.subscribe(centrifugoSubscription);
        this.setConnettionSuccessSource();
    }

    disconnect() {
        this.centrifugeClient.disconnect();
    }

    // presence(userid: string) : Promise<boolean> {
    //     console.log("111 presence userid")
    //     console.log(userid)
    //     return new Promise((resolve, reject) => {
    //         this.client.presence(this.getUserChatChannelName(userid))
    //         .then((resp) => {
    //             console.log("222 presence resp")
    //             console.log(resp);
    //             return resolve(true);
    //         })
    //         .catch(err => {
    //             console.log('333 presence error', err);
    //             return reject(false);
    //         });
    //     })
    // }

    publish(centrifugoSubscription: CentrifugoSubscription, message: any): Promise<void> {
        return new Promise((resolve, reject) => {
            centrifugoSubscription
                .getChannelSubscription()
                .publish(message)
                .then(
                    () => {
                        resolve();
                    },
                    (err) => {
                        console.log(err)
                        reject(err);
                    }
                );
        });
    }

    createNewUserChatSubscription(userId: string): CentrifugoSubscription {
        return this.createNewSubscription(this.getUserChatChannelName(userId));
    }

    createNewUserSettingSubscription(userId: string): CentrifugoSubscription {
        return this.createNewSubscription(this.getUserSettingChannelName(userId));
    }

    createNewRoomChatSubscription(roomId: string): CentrifugoSubscription {
        return this.createNewSubscription(this.getRoomChatChannelName(roomId));
    }

    createNewRoomSettingSubscription(roomId: string): CentrifugoSubscription {
        return this.createNewSubscription(this.getRoomSettingChannelName(roomId));
    }

    createNewRoomMicrophoneSubscription(roomId: string): CentrifugoSubscription {
        return this.createNewSubscription(this.getRoomMicrophoneChannelName(roomId));
    }

    UserChannelPrefix: string = 'user:userid#'
    userChatSubscription: CentrifugoSubscription;
    createUserChannelSubscription(userId: string): CentrifugoSubscription {
        return this.createNewSubscription(`${this.UserChannelPrefix}${userId}`);
    }

    subscribeUserChannel(userId: string) {
        this.userChatSubscription = this.createUserChannelSubscription(userId);

        this.userChatSubscription.getJoinSource().subscribe((data) => {
            console.log(`user getJoinSource worked - data : ${JSON.stringify(data)}`)
        });

        this.userChatSubscription.getPublishSource().subscribe((data) => {
            console.log(`data from publish source : ${JSON.stringify(data)}`)
            // if (this.utilService.isNullOrUndefined(this.indexedDBService.messageCounts[data.data.SenderId]))
            //     this.indexedDBService.messageCounts[data.data.SenderId] = 0;

            // // setTimeout(() => {
            // this.textChatOnReceivePtoP(data.data as ChatMessage, null, false);
            // //}, 150);

        });

        this.userChatSubscription.getLeaveSource().subscribe((data) => {
            console.log(`user getLeaveSource worked - data : ${JSON.stringify(data)}`)
        })


        this.subscribe(this.userChatSubscription);

    }

    getCentrifugoTokenFromBackend() {
        let serviceurl = `${environment.apiUrl}api/message/token`;
        return this.http.post<any>(serviceurl, null).toPromise();
    }

    getUserChatChannelName(userId: string) {
        return CentrifugoConfig.userChatChannelTag + CentrifugoConfig.userChannelSeperator + userId;
    }

    getUserSettingChannelName(userId: string) {
        return CentrifugoConfig.userSettingChannelTag + CentrifugoConfig.userChannelSeperator + userId;
    }

    getRoomChatChannelName(roomId: string) {
        return CentrifugoConfig.roomChannelTag + CentrifugoConfig.roomChannelSeperator + roomId;
    }

    getRoomSettingChannelName(roomId: string) {
        return CentrifugoConfig.roomSettingChannelTag + CentrifugoConfig.roomChannelSeperator + roomId;
    }

    getRoomMicrophoneChannelName(roomId: string) {
        return CentrifugoConfig.roomMicrophoneChannelTag + CentrifugoConfig.roomChannelSeperator + roomId;
    }




    //#region event handlers

    // getTokenTakenSource(): Observable<string> {
    //     return this.tokenTakenSource.asObservable();
    // }

    // setTokenTakenSource(token: string) {
    //     this.tokenTakenSource.next(token);
    // }

    getConnettionSuccessSource(): Observable<any> {
        return this.connettionSuccessSource.asObservable();
    }

    setConnettionSuccessSource() {
        this.connected = true;
        this.connettionSuccessSource.next();

    }

    //#endregion


    //FOR_PUBLISH : message will be published to centrifugo through backend 
    publishPublicSettingMessage(data: any) {
        // return this.publish(this.subscriptions[CentrifugoConfig.sessionChannelTag], data);
        return this.publishPublicSettingMessageEndpoint(CentrifugoConfig.sessionChannelTag, data);
    }

    publishRoomChatMessage(roomId: number, data: any) {
        // return this.publish(this.subscriptions[this.getRoomChatChannelName(roomId.toString())], data);
        return this.publishRoomChatMessageEndpoint(this.getRoomChatChannelName(roomId.toString()), data);
    }

    // publishRoomSettingMessage(roomId: number, data: any) {
    //     // return this.publish(this.subscriptions[this.getRoomSettingChannelName(roomId.toString())], data);
    //     return this.publishMessageThroughBackend(this.getRoomSettingChannelName(roomId.toString()), data);
    // }

    // publishMessageThroughBackend(channelName: string, data: any) {
    //     data.Channelname = channelName;
    //     if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
    //         data.MessageId = this.utilService.guid();;
    //     }
    //     let serviceurl = `${environment.apiUrl}api/message/publish`;
    //     return this.http.post<any>(serviceurl, data).toPromise();
    // }

    publishPublicSettingMessageEndpoint(channelName: string, data: any) {
        data.Channelname = channelName;
        if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
            data.MessageId = this.utilService.guid();;
        }
        let serviceurl = `${environment.apiUrl}api/dyn/PublishPublicSettingMessage`;
        return this.http.post<any>(serviceurl, data).toPromise();
    }

    publishRoomChatMessageEndpoint(channelName: string, data: any) {
        data.Channelname = channelName;
        if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
            data.MessageId = this.utilService.guid();;
        }
        let serviceurl = `${environment.apiUrl}api/dyn/PublishRoomChatMessage`;
        return this.http.post<any>(serviceurl, data).toPromise();
    }

    publishP2PChatMessage(userId: number, receiverId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let serviceurl = environment.apiUrl + "api/dyn/PublishP2PChatMessage/";
        return this.http.post<any>(serviceurl + receiverId, data, options)
            .toPromise();
    }

    publishP2PSettingMessage(userId: number, receiverId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let serviceurl = environment.apiUrl + "api/publishP2PSettingMessage/";
        return this.http.post<any>(serviceurl + receiverId, data, options)
            .toPromise();
    }


    publishBulkMessageToFriends(userId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let serviceurl = environment.apiUrl + "api/publish/friends";
        return this.http.post<any>(serviceurl, data, options)
            .toPromise();
    }


    publishBulkMessageToRankMembers(userId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let serviceurl = environment.apiUrl + "api/publish/rankmembers/";
        return this.http.post<any>(serviceurl + userId + '/' + this.indexedDBService.userFullInfo.Token, data, options)
            .toPromise();
    }

    publishBulkMessageToRankMembersFriends(userId: number, data: any) {
        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let serviceurl = environment.apiUrl + "api/publish/rankmembersfrineds/";
        return this.http.post<any>(serviceurl + userId + '/' + this.indexedDBService.userFullInfo.Token, data, options)
            .toPromise();
    }
}
