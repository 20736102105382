import { EnvironmentVariableService } from './../../services/environment-variable.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Image } from 'src/app/core/models/constants';
import { UtilService } from 'src/app/core/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { UserFullInfo } from 'src/app/core/models/user';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { DeviceService } from 'src/app/core/services/device.service';
import {
  faTimes, faHandshake, faEdit, faCogs, faAngleRight, faAngleDown, faFlag, faComments, faFont, faUserPlus,
  faUsers, faTimesCircle, faPlusCircle, faQuestionCircle, faNewspaper, faBolt, faWrench, faSignOutAlt, faEye, faTrash, faUserMinus
} from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { RoomService } from '../../services/room.service';
import { MessagePanelService } from '../../services/messagePanel.service';
import { StateService } from '../../services/state.service';
import { ChatMessageService } from '../../services/chatMessage.service';
import { LocalizationService } from '../../services/localization.service';
import { StateIdList, StateIdNumberList, StateImagesByName, StateImagesById } from '../../models/state';
import { ChatType, FriendListType, MainMenuType, MainPanels } from '../../models/enums';
import { LanguageService } from '../../services/language.service';
import { ConfirmationService } from 'primeng/api';
import { FriendshipService } from '../../services/friendship.service';
import { FriendListService } from '../../services/friendlist.service';
import { ConfirmationDialogParams } from '../../models/dialog';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { AdminService } from 'src/app/admin/services/admin.service';
import { AdminSystemSettingsService } from 'src/app/admin/tabs/system-settings/services/system-settings.service';

export class SelectedStateHeader {
  key: string;
  value: string;
  icon: string;
}
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class MainMenuComponent implements OnInit {
  //#region Icons
  faTimes = faTimes;
  faEdit = faEdit;
  faCogs = faCogs;
  faFlag = faFlag;
  faComments = faComments;
  faFont = faFont;
  faUserPlus = faUserPlus;
  faUsers = faUsers;
  faTimesCircle = faTimesCircle;
  faPlusCircle = faPlusCircle;
  faQuestionCircle = faQuestionCircle;
  faNewspaper = faNewspaper;
  faBolt = faBolt;
  faWrench = faWrench;
  faEye = faEye;
  faTrash = faTrash;
  faSignOutAlt = faSignOutAlt;
  faHandshake = faHandshake;
  faAngleRight = faAngleRight;
  faAngleDown = faAngleDown;
  faUserMinus = faUserMinus;

  mainMenuType = MainMenuType;
  selectedMainMenuType = MainMenuType.None;
  //#endregion

  params: ConfirmationDialogParams;
  selectedLanguage: string;
  rankImageUrl: string;
  userUpdatedSubscription: Subscription;
  version: string;
  mainMenuHeight: string;
  states: any[] = [];
  selectedStateHeader = new SelectedStateHeader();
  authLink: string;
  envVariable:Subscription
  constructor(
    public utilService: UtilService,
    public translateService: TranslateService,
    public indexedDBService: IndexedDBService,
    public panelManagerService: PanelManagerService,
    public userService: UserService,
    public deviceService: DeviceService,
    private roomService: RoomService,
    private messagePanelService: MessagePanelService,
    public stateService: StateService,
    private chatMessageService: ChatMessageService,
    private localizationService: LocalizationService,
    private languageService: LanguageService,
    private confirmationService: ConfirmationService,
    private friendshipService: FriendshipService,
    private friendListService: FriendListService,
    private confirmationDialogService: ConfirmationDialogService,
    private adminService: AdminService,
    private adminSystemSettingsService: AdminSystemSettingsService,
    private environmentVariableService:EnvironmentVariableService
  ) {

    this.userUpdatedSubscription = this.userService.userUpdated.subscribe((user: UserFullInfo) => {
      if (this.indexedDBService.userFullInfo.KullaniciId === user.KullaniciId) {
        this.ngOnInit();
      }
    })
    this.envVariable = this.environmentVariableService.sentEnvironmentVariable.subscribe(res=>{
      this.authLink = res.YetkiAlimSatim
    })

    this.params = new ConfirmationDialogParams();
  }

  //#region Lifecycle Hooks 
  ngOnInit() {
    //  this.userService.getCurrentUser();
    this.setRankImageUrl();
    this.setStateDetails();
    this.setSelectedLanguage();
  }
  openDeleteAccountRequestPopup(){
    this.panelManagerService.accountDeletionPopupDisplay = true
  }
  whoWeAreDisplay(){
    this.panelManagerService.whoWeArePageDisplay = true;
  }

  legalWarningPageDisplay(){
    this.panelManagerService.legalWarningPageDisplay = true;
  }

  usageAggrementPageDisplay(){
    this.panelManagerService.usageAggrementPageDisplay = true;
  }
  
  ngAfterViewInit() {
    this.setVersion();
    this.mainMenuHeight = this.deviceService.isMobile ? 'calc(100% - 50px)' : 'calc(100% - 150px)';
  }

  ngOnDestroy(): void {
    if (this.userUpdatedSubscription)
      this.userUpdatedSubscription.unsubscribe();

    if (this.envVariable){
      this.envVariable.unsubscribe();
    }
  }
  //#endregion

  openMenu(mainMenuType: MainMenuType) {
    if (this.selectedMainMenuType === mainMenuType) {
      this.selectedMainMenuType = MainMenuType.None;
    }
    else {
      this.selectedMainMenuType = mainMenuType;
    }
  }
  openLink(){
    if (this.authLink) {
      window.open(this.authLink, '_blank');
    }
  }
  deleteAccount() {
    if (
      (
        this.roomService.oldmicrophoneJsonStruct.M === this.indexedDBService.userFullInfo.KullaniciId.toString()
        ||
        (
          !this.utilService.isNullOrUndefined(this.roomService.oldmicrophoneJsonStruct.M2) &&
          this.roomService.oldmicrophoneJsonStruct.M2.find(userid => userid === this.indexedDBService.userFullInfo.KullaniciId.toString())
        )
      )
    ) {
      this.messagePanelService.ShowPopupMessageWithLocalization(this.translateService.instant('cannot_account_delete_on_microphone'), 2000);
      return;
    }

    this.confirmationService.confirm({
      message: this.translateService.instant('delete_account_confirmation'),
      header: this.translateService.instant('delete_account_confirmation_header'),
      icon: null,
      accept: () => {
        this.userService.deleteAccount().subscribe(res => {
          if (res.Result == true) {
            this.roomService.signOut();
          } else {
            this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
          }
        })
      },
      reject: () => {

      }
    })
  }

  giveUpRank() {
    this.confirmationService.confirm({
      message: this.translateService.instant('drop-to-user'),
      header: this.translateService.instant('drop-to-user-header'),
      icon: null,
      accept: () => {
        this.userService.giveUpRank()
          .subscribe((response) => {
            if (response == true) {
              this.messagePanelService.ShowPopupMessageWithLocalization('OperationSuccess', 2000);
              setTimeout(() => {
                this.roomService.signOut();
              }, 2000);
            } else {
              this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
            }
          })
      },
      reject: () => {
        this.indexedDBService.FriendshipRequestActive = !this.indexedDBService.FriendshipRequestActive;
      }
    })
  }

  //#region Profile
  setRankImageUrl() {
    this.rankImageUrl = Image.path + 'Rank' + this.indexedDBService.userFullInfo.RutbeDerece + '.png';
  }

  setVersion() {
    (environment.appVersion).includes("-") ? this.version = (environment.appVersion).split("-")[0] : this.version = (environment.appVersion);
  }

  changeHeight(isOpen: boolean) {
    if (isOpen) {
      this.mainMenuHeight = this.deviceService.isMobile ? 'calc(100% - 150px)' : 'calc(100% - 50px)';
    }
    else {
      this.mainMenuHeight = this.deviceService.isMobile ? 'calc(100% - 50px)' : 'calc(100% - 150px)';
    }
  }
  //#endregion

  //#region State Panel
  setStateDetails() {
    this.states = this.utilService.states;
    this.setSelectedStateHeader();
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  async setSelectedStateHeader() {
    if (this.indexedDBService.userFullInfo.StateId === 0) {
      await this.delay(3000)
    }

    var header = this.utilService.states.find(state => state.value === StateImagesById[this.indexedDBService.userFullInfo.StateId])

    if (header === undefined || header === null) {
      header = this.utilService.states[0]
    }
    this.selectedStateHeader.value = header.value;
    this.selectedStateHeader.key = header.key;
    this.selectedStateHeader.icon = header.icon;
  }

  getStateInfoFromCurrentState() {
    this.selectedStateHeader.key = this.stateService.currentStateText;
    this.selectedStateHeader.value = this.utilService.states.find(state => state.key === this.selectedStateHeader.key).value;
    this.selectedStateHeader.icon = this.utilService.states.find(state => state.key === this.selectedStateHeader.key).icon;
  }

  getStateInfoFromIndexedDB() {
    this.indexedDBService.getPrivateSetting(this.indexedDBService.settingsCurrentStatePrivateKey, this.indexedDBService.userFullInfo.KullaniciAdi)
      .then((response) => {
        this.selectedStateHeader = response;
      })
      .catch(err => {
      });
  }

  selectedState(item) {
    //this.index = 0;
    if (item.value === 'State_Offline') {
      if (this.roomService.oldmicrophoneJsonStruct.M === this.indexedDBService.userFullInfo.KullaniciId.toString()
        ||
        (
          this.utilService.isNullOrEmtpyObject(this.roomService.oldmicrophoneJsonStruct.M2) === false &&
          this.roomService.oldmicrophoneJsonStruct.M2.find(f => f === this.indexedDBService.userFullInfo.KullaniciId.toString()) !== undefined
        )
        ||
        (
          this.roomService.oldmicrophoneJsonStruct.H !== null && this.roomService.oldmicrophoneJsonStruct.H !== undefined &&
          this.roomService.oldmicrophoneJsonStruct.H.find(f => f === this.indexedDBService.userFullInfo.KullaniciId.toString()) !== undefined
        )
      ) {
        this.messagePanelService.ShowPopupMessageWithLocalization('cantBeInvisible', 2000);
        return;
      }

      if (this.roomService.isMyCamOpened) {
        this.messagePanelService.ShowPopupMessageWithLocalization('cantBeInvisible', 2000);
        return;
      }
    }
    this.updateIndexedDBService(item);
    this.callUpdateUserState(item);
  }

  updateIndexedDBService(item) {
    this.indexedDBService.updatePrivateSetting(this.indexedDBService.settingsCurrentStatePrivateKey, item)
      .then(() => {
      })
      .catch(error => {
        console.log(error);
      });
  }

  callUpdateUserState(item) {
    this.userService.callUpdateUserState(StateImagesByName[item.value])
      .then(() => {
        var oldStateId = this.indexedDBService.userFullInfo.StateId;
        this.indexedDBService.userFullInfo.StateId = parseInt(StateImagesByName[item.value]);
        this.indexedDBService.updateUser(this.indexedDBService.userFullInfo);

        // this.dynamicMethodsReceiveService.RefreshMyFriendsFriendList(false, false);
        this.roomService.updateUserStateInListDynamic();
        if (oldStateId === StateIdList.Cevrimdisi && this.indexedDBService.userFullInfo.StateId !== StateIdList.Cevrimdisi) {
          this.roomService.SendMessageToFriendForOnlineInfo(StateIdNumberList[this.indexedDBService.userFullInfo.StateId]);
        }

        if (this.panelManagerService.roomOpened && oldStateId === StateIdList.Cevrimdisi && this.indexedDBService.userFullInfo.StateId !== StateIdList.Cevrimdisi) {
          this.chatMessageService.sendAdminMessageToOneRoom(this.roomService.currentRoom.Info.ID,
            this.localizationService.getKeyWithTag("EnteredRoom"),
            ChatType.RoomActionMessage
          );
        }

        this.selectedStateHeader = item;
        //this.index = -1
      })
      .catch(err => {
        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
      })
  }
  //#endregion

  //#region Personal Settings
  setSelectedLanguage() {
    this.selectedLanguage = this.languageService.languages.find(element => element.key === this.translateService.getDefaultLang()).value;
  }

  setSiteLanguage($event) {

    this.languageService.changeLanguage(this.languageService.languages.find(element => element.value === this.selectedLanguage))

  }
  //#endregion

  //#region Chat Settings
  friendshipRequestActiveChange($event) {
    this.confirmationService.confirm({
      message: $event.checked ? this.translateService.instant('friendship_activating_message') : this.translateService.instant('friendship_disabling_message'),
      header: $event.checked ? this.translateService.instant('friendship_activating') : this.translateService.instant('friendship_disabling'),
      icon: null,
      accept: () => {
        this.callSaveFriendshipRequestActivation();
      },
      reject: () => {
        this.indexedDBService.FriendshipRequestActive = !this.indexedDBService.FriendshipRequestActive;
      }
    })
  }

  callSaveFriendshipRequestActivation() {
    this.friendshipService.callSaveFriendshipRequestActivation()
      .subscribe((res) => {
        if (this.indexedDBService.FriendshipRequestActive === false) {
          let incomingList = this.indexedDBService.Friends.IncomingFriends;
          for (var i = 0; i < incomingList.length; i++) {
            this.friendListService.callRemoveFriend(incomingList[i].KullaniciId, FriendListType.IncomingFriends);
          }
        }
      });
  }
  //#endregion

  //#region Help
  openLinkInNewTab(url: string) {
    window.open(url, '_blank').focus();
  }
  //#endregion

  //#region Under Construction Mode
  underConstructionModeActivate() {
    if (this.indexedDBService.underConstructionModeActive === false) {
      this.confirmationService.confirm({
        message: this.translateService.instant('Dikkat! Bu işlem tüm kullanıcıların oturumunu sonlandırır ve logini devre dışı bırakır! İşiniz bittikten sonra tekrar aktif etmeniz önerilir..'),
        header: null,
        icon: null,
        accept: () => {
          this.adminService.adminLoading = true;
          this.callUnderConstructionModeActivate(true)
            .then(() => {
              this.messagePanelService.ShowPopupMessageWithLocalization('LoginDisabled', 2000);
              this.messagePanelService.ShowPopupMessageWithLocalization('PleaseWait', 2000);
            })
            .then(() => {
              return this.adminSystemSettingsService.callLogoutOtherAllSessions(this.deviceService.defineAndGetTabID(), this.indexedDBService.userFullInfo.KullaniciId)
            })
            .then((userIdList: Array<number>) => {
              this.messagePanelService.ShowPopupMessageWithLocalization('AllUsersAreLoggedOut', 4000);
            })
            .then(() => {
              this.indexedDBService.underConstructionModeActive = true;
            })
            .catch(err => {
              this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 4000);
            })
            .finally(() => {
              this.adminService.adminLoading = false;
            })
        },
        reject: () => {
        }
      });
    }
    else {
      this.loginEnable();
    }
  }

  callUnderConstructionModeActivate(isOpen): Promise<void> {
    return this.adminSystemSettingsService.callUnderConstructionModeActivate(isOpen);
  }

  loginEnable() {
    this.adminService.adminLoading = true;
    this.callUnderConstructionModeActivate(false)
      .then(() => {
        this.messagePanelService.ShowPopupMessageWithLocalization('LoginEnabled', 4000);
        this.indexedDBService.underConstructionModeActive = false;
      })
      .catch(err => {
        this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 4000);
      })
      .finally(() => {
        this.adminService.adminLoading = false;
      })
  }
  //#endregion

  //#region Signout
  signOut() {
    this.params.title = this.translateService.instant('menu_signout');
    this.params.message = this.translateService.instant('are-you-sure-to-logout');
    this.params.display = true;
    this.params.acceptLabel = this.translateService.instant('Yes');
    this.params.rejectLabel = this.translateService.instant('No');
    this.params.ok = () => {
      this.roomService.signOut()
    };
    this.params.cancel = () => {
      return
    };

    this.confirmationDialogService.showPopup(this.params);
  }
  //#endregion

  showRoomOwnerPicture() {
    this.indexedDBService.friendProfilePicture = this.utilService.getProfilePhotoUrl(this.indexedDBService.userFullInfo.ProfilResmiId, true);
    this.panelManagerService.profilePhotoPopupDisplay = true;
  }
}