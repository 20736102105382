import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { Language } from '../models/language';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})

export class LanguageService {

    languageSource: Subject<Language> = new Subject();
    currentLanguage;

    public languages: Array<Language> = [
        new Language('tr', 'turkish', 'Turkish'),
        new Language('en', 'english', 'English')
    ];

    constructor(
        private translateService: TranslateService,
        private storageService: LocalStorageService
    ) {
        //NOTE: fix for tab close
        const currentLanguageStr = this.storageService.storageGetItem('currentLanguage');
        // const currentLanguageStr = localStorage.getItem('currentLanguage');

        if (currentLanguageStr) {
            this.currentLanguage = JSON.parse(currentLanguageStr);
            this.setChangedLanguage(this.currentLanguage);
            this.translateService.use(this.currentLanguage.key);
        } else {
            this.currentLanguage = { key: 'tr', value: 'turkish', label: 'Turkish' };
        }
    }

    changeLanguage(data: Language) {
      
        this.currentLanguage = data;
        this.translateService.use(this.currentLanguage.key);
        this.setChangedLanguage(this.currentLanguage);
        //NOTE: fix for tab close
        // localStorage.setItem('currentLanguage', JSON.stringify(data));
        this.storageService.storageSetItem('currentLanguage', JSON.stringify(data));
    }


    getDefaultLanguage() {
        return this.languages.find(f => f.key === "tr");
    }

    getChangedLanguage(): Observable<Language> {
        return this.languageSource.asObservable();
    }

    setChangedLanguage(language: Language) {
        this.languageSource.next(language);
    }


    initChangeLanguageEventsForChildModules(translate: TranslateService, languageService: LanguageService, changedLanguageSubscription: Subscription) {
        let currentLanguage = languageService.getCurrentLanguage();
        if (currentLanguage) {
          
            translate.use(currentLanguage.key);
        }

      
        if (!changedLanguageSubscription)
            changedLanguageSubscription = languageService.getChangedLanguage().subscribe((language: Language) => {
                translate.use(language.key);
               
    
            });
    }

    getCurrentLanguage() {
        return this.currentLanguage;
    }
}