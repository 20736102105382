import { EnvironmentVariableService } from './../../services/environment-variable.service';
import { Component, Inject, ViewEncapsulation, OnInit, ElementRef, ViewChild } from '@angular/core';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { IndexedDBService } from '../../services/indexed-db.service';
import { DeviceService } from '../../services/device.service';
import * as Bowser from "bowser";
import { LanguageService } from '../../services/language.service';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import jwt_decode from "jwt-decode";
import { DecodeJWT, UpdateUserNameResponse } from '../../models/user';
import { UtilService } from '../../services/util.service';
import { faInfoCircle, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { MessagePanelService } from '../../services/messagePanel.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalStorageService } from '../../services/local-storage.service';
import { Subscription } from 'rxjs';
import { sha512_256 } from 'js-sha512';
@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
})

export class PublicComponent implements OnInit {
    loaded: boolean = false;
    loadedTempPage: boolean = false;

    faGoogle = faGoogle
    faFacebook = faFacebook
    faInfoCircle = faInfoCircle;
    faGuest = faUserTie;
    apiUrl = `${environment.apiUrl}`
    selectedLanguage: any;
    logoLoaded: boolean = false;
    token: string = "";
    logo_128: string = "";
    logo_800:string = "";
    envVariable:Subscription
    load: Promise<boolean>;
    appName:string = "";
    roomId:number 
    @ViewChild('webglCanvas') canvasRef: ElementRef
    private gl: WebGL2RenderingContext;
    private program: WebGLProgram;
    private vertexBuffer: WebGLBuffer;
    private uniformOffset: WebGLUniformLocation;
    usernameForm = this.fb.group({
        userName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(13)]]
    });

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public panelManagerService: PanelManagerService,
        public indexedDBService: IndexedDBService,
        public deviceService: DeviceService,
        private languageService: LanguageService,
        private router: Router,
        private loginService: LoginService,
        public utilService: UtilService,
        private messagePanelService: MessagePanelService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private storageService: LocalStorageService,
        private environmentVariableService:EnvironmentVariableService
    ) {
        this.envVariable = this.environmentVariableService.sentEnvironmentVariable.subscribe(res=>{
            this.logo_128 = res.LogoL;
            this.logo_800 = res.LogoXL;
            this.appName=res.UygulamaAdi
            this.load = Promise.resolve(true);

          })
          
        const browser = Bowser.getParser(window.navigator.userAgent);
        let browserInfo = browser.getBrowser();

        if (this.indexedDBService.SupportedBrowsers.includes(browserInfo.name)) {
            this.loaded = true;
            this.loadedTempPage = false;
            return;
        }
        else {
            this.loadedTempPage = true;
            this.loaded = false;
        }

    }

    ngOnInit(): void {
        this.checkUserIsExist();
    }
    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.setSelectedLanguage();
        if (this.envVariable){
            this.envVariable.unsubscribe();
        }
    }
    signInwithGoogle() {
        // let url = this.apiUrl + "google/login";
        // this.document.location.href = url;
        // this.checkUserIsExist();
        this.loginService.googleSignIn();
    }

    signInwithFacebook() {
        let url = this.apiUrl + "facebook/login";
        this.document.location.href = url;
        this.checkUserIsExist();
    }
    signAsGuest(){
        this.loginService.callGuestLogin();
    }

    checkUserIsExist() {
        if (this.token) {
            localStorage.setItem('token', this.token);
            let decodedJWT: DecodeJWT = this.decodeJWT();
            if (!decodedJWT.isNewLogin) { // user is exist
                this.login();
            } else { // user is not exist
                this.panelManagerService.newUserRegisterPopupDisplay = true;
            }
        }
    }

    login() {
        console.log(`public.component.ts login worked`)
        this.loginService.login()
    }

    openHelpLink() {
        window.open('https://www.seslidunya.com/yardim-merkezi/', '_blank');
    }
    checkUsername() {
        if (this.usernameForm.valid) {
            this.updateUsername();
        }
        else if (!this.usernameForm.get('userName').valid) {
            this.messagePanelService.ShowPopupMessageWithLocalization('UserNameEmptyWarning', 2000);
            return;
        }
        else {
            this.messagePanelService.ShowPopupMessageWithLocalization('invalidForm', 2000);
            return;
        }
    }

    updateUsername() {
        this.loginService.updateUsername({ 'UserName': this.usernameForm.controls['userName'].value })
            .subscribe((updateUserNameRes: UpdateUserNameResponse) => {
                if (updateUserNameRes.UserNameExist) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('user-name-already-exist', 2000);
                    return
                }
                if (updateUserNameRes.Success) {
                    this.messagePanelService.ShowPopupMessageWithLocalization('SavedSuccess', 2000)
                    this.panelManagerService.newUserRegisterPopupDisplay = false;
                    this.token = updateUserNameRes.Token;
                    //NOTE: fix for tab close
                    // localStorage.setItem('token', this.token);
                    this.storageService.storageSetItem('token', this.token)
                    this.login();
                }
                else {
                    this.messagePanelService.ShowPopupMessageWithLocalization('ErrorPleaseReTry', 2000);
                    return;
                }
            })
    }

    setSelectedLanguage() {
        this.selectedLanguage = this.languageService.languages[0];
    }

    handleChange($event) {
        if ($event.checked === false) {
            this.languageService.setChangedLanguage(this.languageService.languages.find(lang => lang.value === 'english'))
            this.languageService.changeLanguage(this.languageService.languages.find(lang => lang.value === 'english'))
        } else {
            this.languageService.setChangedLanguage(this.languageService.languages.find(lang => lang.value === 'turkish'))
            this.languageService.changeLanguage(this.languageService.languages.find(lang => lang.value === 'turkish'))
        }
    }

    decodeJWT(): DecodeJWT {
        return jwt_decode(this.token);
    }
}