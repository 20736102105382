import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { MessagePanelService } from './messagePanel.service';
import { IndexedDBService } from './indexed-db.service';
import { ServiceInformation } from './serviceinformation.service';
import { OperationMessageService } from './operation-message.service';
import { DynamicMethod } from '../models/dynamic-method';
import { ChatType } from '../models/enums';
import { OperationMessage } from '../models/operation-message';
import { Constants } from '../models/constants';
import { CentrifugoConfig } from '../centrifugo/models/constants';

@Injectable({
  providedIn: 'root'
})
export class DynamicMethodsService extends ServiceInformation {
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(
    private http: HttpClient,
    public utilService: UtilService,
    public messagePanelService: MessagePanelService,
    protected indexedDBService: IndexedDBService,
    private operationMessageService: OperationMessageService
  ) {
    super(messagePanelService, utilService, indexedDBService);
  }

  //endpoints area
  publishDynRefreshFriendList(userId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/RefreshFriendList/";
    return this.http.post<any>(serviceurl + userId + '/' + this.indexedDBService.userFullInfo.Token, data, this.options)
      .toPromise();
  }

  publishDynRefreshFriendListForOneUserP2PSetting(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/RefreshFriendListForOneUserP2PSetting/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  RefreshFriendListForOneUserFriend(userId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/RefreshFriendListForOneUserFriend";
    return this.http.post<any>(serviceurl, data, this.options)
        .toPromise();
}

  publishDynRemoveUserFromMessengerList(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/RemoveUserFromMessengerList/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynAddFriendAfterEvents(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/AddFriendAfterEvents/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynUserBlockUnblock(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/UserBlockUnblock/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }
  publishDynRefreshUser(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/RefreshUser/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }
  publishDynKullaniciBilgiEkle(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciBilgiEkle/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynRefreshRoomUserListForAllUsersInRoom(channelName: string, data: any) {
    data.Channelname = channelName;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/RefreshRoomUserListForAllUsersInRoom";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynAdminPaneliKapat(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/AdminPaneliKapat/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciLogOut(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciLogOut/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynRedirectToLoginPage(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/RedirectToLoginPage/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciAdminHareketiMesajGoster(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciAdminHareketiMesajGoster/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynShowBalloonTipForFriendActions(userId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/ShowBalloonTipForFriendActions";
    return this.http.post<any>(serviceurl, data, this.options)
      .toPromise();
  }

  publishDynSetOdaSetting(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/SetOdaSetting";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynSetPictureboxImage(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/SetPictureboxImage/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynDeleteUserImage_SendMessageToRoom(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/DeleteUserImage_SendMessageToRoom/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynInviteToMicrophone(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/InviteToMicrophone/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciOdaBan(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciOdaBan/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciOdaBanKaldir(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciOdaBanKaldir/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciSiteBan(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciSiteBan/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciSiteBanKaldir(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciSiteBanKaldir/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciOdaMute(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciOdaMute/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciSiteMute(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciSiteMute/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciOdaMuteKaldir(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciOdaMuteKaldir/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynKullaniciSiteMuteKaldir(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/KullaniciSiteMuteKaldir/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynFinishCam(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/FinishCam";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynOdadanAt(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/OdadanAt/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynShowHideUserBanIconUserInList(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/ShowHideBanIconUserInList";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynShowHideUserMuteIconUserInList(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/ShowHideMuteIconUserInList";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynShowHideUserCamIconUserInList(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/ShowHideCamIconUserInList";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynUpdateUserImageInList(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/UpdateUserImageInList";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynAddRemoveCamWatcher(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/AddRemoveCamWatcher/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynCloseRoomVideoPanel(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/CloseRoomVideoPanel";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynClearTextboxAsBulkSettingMessage(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/ClearTextbox";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynClearTextboxAsRoomSettingMessage(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/ClearTextbox";
    return this.http.post<any>(serviceurl, data).toPromise();
  }


  publishDynRefreshMyRoomUserList(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/RefreshMyRoomUserList";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynOdaSiteBanGirisleriSet(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/OdaSiteBanGirisleriSet";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynOdaBanGirisleriDegisti(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/OdaBanGirisleriDegisti";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynP2PConferenceRequest(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/P2PConferenceRequest/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynP2PConferenceResponse(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/P2PConferenceResponse/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynRemoveBulkBanMute(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/RemoveBulkBanMute";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynEditUsersForChangedRank(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/EditUsersForChangedRank";
    return this.http.post<any>(serviceurl, data).toPromise();
  }


  publishDynSMSValidationRequestChanged(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/SMSValidationRequestChanged";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynInviteToRoom(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/InviteToRoom/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynOdaSahibi(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/OdaSahibi/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynCloseP2PChatPanel(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/CloseP2PChatPanel/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynDropMic(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/DropMic/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynChangedRoomOrderList(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/ChangedRoomOrderList";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynGetComplainNotification(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/GetComplainNotification";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynGetLiveSupportNotification(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/GetLiveSupportNotification";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynRoomCreated(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/RoomCreated";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynCloseScreenShareVideoPanel(roomId: number, data: any) {
    data.Channelname = this.getRoomSettingChannelName(roomId.toString());
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/CloseScreenShareVideoPanel";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynUpdateUserStateInListPublish(channelName: string, data: any) {
    data.Channelname = channelName;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/UpdateUserStateInListPublish";
    return this.http.post<any>(serviceurl, data).toPromise();
  }


  publishDynUpdateUserStateInListFriend(userId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/UpdateUserStateInListFriend";
    return this.http.post<any>(serviceurl, data, this.options)
      .toPromise();
  }

  publishDynUpdateUserAllInfoP2PSetting(userId: number, receiverId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/UpdateUserAllInfoP2PSetting/";
    return this.http.post<any>(serviceurl + receiverId, data, this.options)
      .toPromise();
  }

  publishDynUpdateUserAllInfoPublish(data: any) {
    data.Channelname = CentrifugoConfig.sessionChannelTag;
    if (data.MessageId === undefined || data.MessageId === null || data.MessageId === '') {
      data.MessageId = this.utilService.guid();;
    }
    let serviceurl = this.ServiceUrl + "api/dyn/UpdateUserAllInfoPublish";
    return this.http.post<any>(serviceurl, data).toPromise();
  }

  publishDynUpdateUserAllInfoFriend(userId: number, data: any) {
    let serviceurl = this.ServiceUrl + "api/dyn/UpdateUserAllInfoFriend";
    return this.http.post<any>(serviceurl, data, this.options)
      .toPromise();
  }

  //end endpoint area

  //helper functions area
  prepareUserSettingMessage(userId: number, dynamicMethodArray: Array<DynamicMethod>, userName: string = "") {
    return this.operationMessageService.getSettingMessage(
      this.indexedDBService.userFullInfo,
      this.utilService.getUserChannelName(userId),
      ChatType.AdminChangesMethod,
      dynamicMethodArray,
      userId,
      userName
    );
  }


  prepareRoomSettingMessage(roomId: number, dynamicMethodArray: Array<DynamicMethod>): OperationMessage {
    return this.operationMessageService.getSettingMessage(
      this.indexedDBService.userFullInfo,
      this.utilService.getRoomChannelName(roomId),
      ChatType.AdminChangesMethod,
      dynamicMethodArray
    );
  }

  prepareAdminBulkSettingMessage(dynamicMethodArray: Array<DynamicMethod>) {
    return this.operationMessageService.getSettingMessage(
      this.indexedDBService.userFullInfo,
      Constants.SETTINGSCHANNGELTAG,
      ChatType.AdminChangesMethod,
      dynamicMethodArray
    );
  }

  getRoomSettingChannelName(roomId: string) {
    return CentrifugoConfig.roomSettingChannelTag + CentrifugoConfig.roomChannelSeperator + roomId;
  }
  //end helper functions area

}
