import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'src/app/core/services/device.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { LoginService } from 'src/app/core/services/login.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { UtilService } from 'src/app/core/services/util.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-room-link',
  templateUrl: './room-link.component.html',
  styleUrls: ['./room-link.component.scss']
})
export class RoomLinkComponent implements OnInit {

  apiUrl = `${environment.apiUrl}`
  roomId:number 

  constructor(
      @Inject(DOCUMENT) private document: Document,
      public panelManagerService: PanelManagerService,
      public indexedDBService: IndexedDBService,
      public deviceService: DeviceService,
      private loginService: LoginService,
      public utilService: UtilService,
      private route: ActivatedRoute,
  ) {
    
  }

  ngOnInit(): void {
      this.checkRoomIdParameter();
  }

  checkRoomIdParameter(){
      this.route.params.subscribe(params => {
          this.roomId = parseInt(params['id'])
          let password = params['password']
          if (this.roomId) {
              if (this.indexedDBService.isAuthenticated()) {
                  this.loginService.loginWithUrl(this.roomId,password)
              }
              else{
                  this.loginService.callGuestLoginWithRoomUrl(this.roomId,password);
              }
          }
        });
  }

}