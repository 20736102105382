import { FileMessageData } from './../../../../models/chat-message';
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, HostListener, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { EditorComponent } from '../../../editor/editor.component';
import { MessengerService } from 'src/app/core/services/messenger.service';
import { ChatType, EditorOn, MessageSendingType, MessageType } from 'src/app/core/models/enums';
import { UtilService } from 'src/app/core/services/util.service';
import { IndexedDBService } from 'src/app/core/services/indexed-db.service';
import { RoomService } from 'src/app/core/services/room.service';
import { MessagePanelService } from 'src/app/core/services/messagePanel.service';
import { PanelManagerService } from 'src/app/core/services/panel-manager.service';
import { Subscription } from 'rxjs';
import { RoomUser } from 'src/app/core/models/room-user';
import { DeviceService } from 'src/app/core/services/device.service';
import { faPaperPlane, faSmile, faUserTie, faFilm, faIcons, faVolumeUp, faVolumeOff, faVolumeMute, faShare, faShareAltSquare, faFolder } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faDochub, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { AnimationsComponent } from 'src/app/admin/tabs/animations/components/animations/animations.component';
import { RoomChatButtonsMicrophoneSettingsComponent } from '../buttons/microphone-settings/microphone-settings.component';
import { StateIdList } from '../../../../models/state';
import { EnvironmentVariableService } from '../../../../services/environment-variable.service';

@Component({
  selector: 'app-room-chat-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class RoomChatEditorComponent implements OnInit, AfterViewInit {
  faSmile = faSmile;
  faPaperPlane = faPaperPlane;
  faUser = faUser;
  faUserTie = faUserTie;
  faFilm = faFilm;
  faIcons = faIcons;
  faYoutube = faYoutube;
  faVolumeUp = faVolumeUp;
  faVolumeOff = faVolumeMute;
  faShare = faShareAltSquare
  faFolder = faFolder
  messageSendingType = MessageSendingType;

  animationToggle: boolean = false;
  showMicSettings: boolean = false;
  emojiPanelIsOpen: boolean = false;

  isTyping: boolean = false;
  editorOn = EditorOn;
  @ViewChild('editor') editor: EditorComponent;
  editorMessageForSet: Promise<string> = Promise.resolve("");
  isClickRight: boolean = false;
  @ViewChild("emojiPanel", { static: false, read: ElementRef }) emojiPanel: ElementRef;
  @ViewChild('emojiImg', { static: false, read: ElementRef }) emojiImg: ElementRef;

  @ViewChild('animation', { static: false, read: ElementRef }) animation: ElementRef;
  @ViewChild('messageSendingTypeMenu', { static: false, read: ElementRef }) messageSendingTypeMenu: ElementRef;

  @ViewChild('micIcon', { static: false, read: ElementRef }) micIcon: ElementRef;

  @Output() editorTextChanged = new EventEmitter<string>();
  @Output() editorEnterPressed = new EventEmitter();


  isHandUp: boolean = false;
  isTakeMic: boolean = false;
  lastSendMessageTime = this.utilService.newDate();
  floodmessageCount = 0;
  sendButtonEnable = true;

  roomUserListChangedSubscription: Subscription;
  sendMessageToAllAuthorizedUsersSubscription: Subscription;

  fileMessageReceivedSubscription: Subscription;
  constructor(
    private messengerService: MessengerService,
    private indexedDBService: IndexedDBService,
    private utilService: UtilService,
    public roomService: RoomService,
    private messagePanelService: MessagePanelService,
    public panelManagerService: PanelManagerService,
    public deviceService: DeviceService,
    public environmentVariableService:EnvironmentVariableService
  ) {
    this.roomUserListChangedSubscription = this.roomService.roomUserListChanged.subscribe(() => {
      // let roomUser: RoomUser = this.roomService.roomUserList.find(f => f.KullaniciId === this.indexedDBService.userFullInfo.KullaniciId);
      let roomUser:RoomUser = this.roomService.getRoomUserFromActiveListByUserId(this.indexedDBService.userFullInfo.KullaniciId)
      this.isHandUp = false;
      this.isTakeMic = false;
      if (roomUser?.OnMainMic === true) {
        this.isTakeMic = true;
      }

      if (roomUser?.OnSecondMic === true)
        this.isTakeMic = true;
      if (roomUser?.HandUp === true)
        this.isHandUp = true;
    });
    this.fileMessageReceivedSubscription = this.roomService.roomFileMessageReceived.subscribe((data:FileMessageData) => {
      this.sendFileMessageOnRoom(data)
    })
    this.sendMessageToAllAuthorizedUsersSubscription = this.roomService.selectedSendingMessageType.subscribe(() => {
      this.selectSendMessageType();
    })
  }

  ngOnInit() {
    // || (this.utilService.isNullOrEmtpyObject(this.indexedDBService.userFullInfo.MuteOdaList) === false && this.indexedDBService.userFullInfo.MuteOdaList.find(f => f === this.roomService.currentRoom.Info.ID))
    if (
      this.indexedDBService.userFullInfo.BanSite
      || this.indexedDBService.userFullInfo.MuteSite
      || (this.utilService.isNullOrEmtpyObject(this.indexedDBService.userFullInfo.BanOdaList) === false && this.indexedDBService.userFullInfo.BanOdaList.find(f => f === this.roomService.currentRoom.Info.ID))) {
      this.panelManagerService.contentEditable = false;
    }
  }

  ngAfterViewInit() {
    //this.changeDetectorRef.detectChanges();
  }
  copyRoomLink() {
    let domain = "https://" + window.location.hostname;
    let sharableLink = "";
    
    if (!this.roomService.currentRoom.Info.HASPASSWORD) {
      sharableLink = domain + "/roomId/" + this.roomService.currentRoom.Info.ID;
      navigator.clipboard.writeText(sharableLink)
      .then(() => {
        this.messagePanelService.ShowPopupMessageWithLocalization('copy-room-link-success', 2000);
      });
    } else {
      this.roomService.getRoomPassword(this.roomService.currentRoom.Info.ID).then(res => {
        sharableLink = domain + "/roomId/" + this.roomService.currentRoom.Info.ID + "/" + res.sifre;
        navigator.clipboard.writeText(sharableLink)
        .then(() => {
          this.messagePanelService.ShowPopupMessageWithLocalization('copy-room-link-success', 2000);
        });
      });
    }
  }
  

  @HostListener('document:click', ['$event'])
  clickout(event) {

    if (!this.deviceService.isMobile) {
      if (this.emojiPanel.nativeElement.contains(event.target)) {
        this.emojiPanelIsOpen = true;
      }
      else if (this.emojiImg.nativeElement.contains(event.target)) {
        this.emojiPanelIsOpen = !this.emojiPanelIsOpen;
      }
      else {
        this.emojiPanelIsOpen = false;
      }
    }

    if (!this.animation.nativeElement.contains(event.target)) {
      this.animationToggle = false;
    }

    if (!this.micIcon.nativeElement.contains(event.target)) {
      this.panelManagerService.showMicSettings = false;
    }

    if (this.messageSendingTypeMenu) {
      if (!this.messageSendingTypeMenu.nativeElement.contains(event.target)) {
        this.isClickRight = false;
      }
    }

    event.preventDefault();
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.roomUserListChangedSubscription)
      this.roomUserListChangedSubscription.unsubscribe();

    if (this.fileMessageReceivedSubscription) {
      this.fileMessageReceivedSubscription.unsubscribe();
    }

    if (this.sendMessageToAllAuthorizedUsersSubscription) {
      this.sendMessageToAllAuthorizedUsersSubscription.unsubscribe();
    }
  }

  onRightClick($event) {
    if (this.indexedDBService.userFullInfo.RutbeFullInfo.GENEL_MESAJ) {
      $event.preventDefault();

      if (this.indexedDBService.userFullInfo.StateId == StateIdList.Cevrimdisi) {
        this.messagePanelService.ShowPopupMessageWithLocalization('Çevrimdışı kullanıcı mesaj gönderemez', 2000);
        return;
      }

      this.isClickRight = true;
    }
  }

  addEmoji($event) {
    this.editor.pasteTextToEditor(this.messengerService.createEmojiHtml($event.emoji), true);
  }

  selectSendMessageType() {
    this.isClickRight = false;
  }

  hideAllDynamicPanels() { }

  editorTextChange(text: string) {
    if (!this.utilService.isNullOrEmtpyString(text) && text.length > 0) {
      this.isTyping = true;
    } else {
      this.isTyping = false;
    }
    this.editorTextChanged.emit(text);
  }

  editorEnterPress() {
    this.emojiPanelIsOpen = false;
    this.isTyping = false;
    this.sendMessageOnRoom();
  }

  sendMessageOnRoom() {
    try {
      if (this.indexedDBService.userFullInfo.StateId == StateIdList.Cevrimdisi) {
        this.messagePanelService.ShowPopupMessageWithLocalization('Çevrimdışı kullanıcı mesaj gönderemez', 2000);
        return;
      }

      if (this.lastSendMessageTime > this.utilService.addSecondToDate(this.utilService.newDate(), -1)) {
        this.floodmessageCount++;

        if (this.floodmessageCount > 2) {
          this.sendButtonEnable = false;

          //setTimeout(() => {
          this.sendButtonEnable = true;
          this.floodmessageCount = 0;
          //}, 1000);

          return;
        }
      } else {
        this.floodmessageCount = 0;
      }

      this.lastSendMessageTime = this.utilService.newDate();

      this.roomService.currentMessageType = MessageType.Text;

      this.roomService.sendMessageToRoom(this.editor.getEditorTextForSend())
        .then(() => {
          this.editorMessageForSet = Promise.resolve("");
        })
        .catch(error => {
          if (!this.utilService.isNullOrEmtpyString(error))
            this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
          //this.messagePanelService.ShowPopupMessageWithLocalization('MessageSendError', 2000);
        })
        .finally(() => {
          this.roomService.setEditorAsDefault();
        });
    }
    catch (error) {
      console.log(error);
    }
  }

  sendFileMessageOnRoom(data:FileMessageData) {
    try {
      if (this.indexedDBService.userFullInfo.StateId == StateIdList.Cevrimdisi) {
        this.messagePanelService.ShowPopupMessageWithLocalization('Çevrimdışı kullanıcı mesaj gönderemez', 2000);
        return;
      }

      if (this.lastSendMessageTime > this.utilService.addSecondToDate(this.utilService.newDate(), -1)) {
        this.floodmessageCount++;

        if (this.floodmessageCount > 2) {
          this.sendButtonEnable = false;

          //setTimeout(() => {
          this.sendButtonEnable = true;
          this.floodmessageCount = 0;
          //}, 1000);

          return;
        }
      } else {
        this.floodmessageCount = 0;
      }

      this.lastSendMessageTime = this.utilService.newDate();

      this.roomService.currentMessageType = MessageType.Text;

      this.roomService.sendFileMessageToRoom(this.editor.getEditorTextForSend(),data)
        .then(() => {
          this.editorMessageForSet = Promise.resolve("");
        })
        .catch(error => {
          if (!this.utilService.isNullOrEmtpyString(error))
            this.messagePanelService.ShowPopupMessageWithLocalization(error, 2000);
          //this.messagePanelService.ShowPopupMessageWithLocalization('MessageSendError', 2000);
        })
        .finally(() => {
          this.roomService.setEditorAsDefault();
        });
    }
    catch (error) {
      console.log(error);
    }
  }


  toggleAnimationsPanel() {
    if (this.indexedDBService.userFullInfo.StateId == StateIdList.Cevrimdisi) {
      this.messagePanelService.ShowPopupMessageWithLocalization('Çevrimdışı kullanıcı mesaj gönderemez', 2000);
      return;
    }
    this.animationToggle = !this.animationToggle;
  }

  toggleMicSettingsPanel() {
    this.panelManagerService.showMicSettings = !this.panelManagerService.showMicSettings;
  }

  togglSendFolderPanel() {
    if (this.indexedDBService.userFullInfo.RutbeDerece == -1) {
      this.panelManagerService.guestWarningPopupDisplay = true;
      return
    }
    this.panelManagerService.sendFolderToRoomPopupDisplay = !this.panelManagerService.sendFolderToRoomPopupDisplay;
    
  }

  openYoutubePanel() {
    this.panelManagerService.youtubePlayerPopupDisplay = true;
    this.panelManagerService.youtubePlayerPopupMinus = false;
  }
  getSettingValue() {
    this.panelManagerService.showMicSettings = false
  }
}