import { Injectable, Injector } from '@angular/core';
import {
  AudioPreset,
  AudioPresets,
  DisconnectReason,
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalTrackPublication,
  LogLevel,
  MediaDeviceFailure,
  Participant,
  ParticipantEvent,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomConnectOptions,
  RoomEvent,
  RoomOptions,
  Track,
  TrackPublication,
  VideoCodec,
  VideoPresets,
  createAudioAnalyser,
  setLogLevel,
} from 'livekit-client';

import { Connection } from '../models/connection';
import { State } from '../models/state';
import { ConnectionOptions } from '../models/connectionOption';
import { RoomService } from '../../services/room.service';
import { PanelManagerService } from '../../services/panel-manager.service';
import { RoomCamera, RoomMicToken } from '../../models/room';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../models/constants';
import { MainPanels } from '../../models/enums';
import { RoomUser } from '../../models/room-user';
@Injectable({
  providedIn: 'root'
})
export class LivekitService {
  room: Room;

  state = State

  devices: MediaDeviceInfo[]
  //connect options

  preferredCodec: VideoCodec;

  //PARTICIPANTS
  participants: Participant[] = []

  //element-mapping
  elementMapping: { [k: string]: MediaDeviceKind } = {
    'video-input': 'videoinput',
    'audio-input': 'audioinput',
    'audio-output': 'audiooutput',
  };

  isOnMic: boolean = false;
  isOnScreenSharing:boolean = false;
  isCamOpen:boolean = false;
  
  isOnSharingTabMusic:boolean = false;
  allSenderAudioTrackAreMuted: boolean = false;
  isShareMusic: boolean = false;
  closeScreenSharefromComponent: boolean = false;
  //DEVICE ELEMENTS
  videoDevices: MediaDeviceInfo[] = [];
  audioInputDevices: MediaDeviceInfo[] = [];
  selectedVideoDeviceId: string | undefined;
  selectedAudioInputDeviceId: string | undefined;
  isMusicSharedWithoutMic: boolean = false;
  isMicEnabled = true

  //TOKEN_ROOM_MIC
  roomMicToken: RoomMicToken

  //reconnect options 
   maxRetryDelay = 3000;

   roomAIAssistant:RoomUser
   isRoomHasAIAgent:boolean = false

  constructor(public panelManagerService: PanelManagerService,
    public http: HttpClient,
    private injector: Injector) {
  }

  async initConnection() {
    const url = Connection.livekitUrl;
    const simulcast = ConnectionOptions.simulcast;
    const dynacast = ConnectionOptions.dynacast;
    const forceTURN = ConnectionOptions.forceTurn;
    const adaptiveStream = ConnectionOptions.adaptiveStream;
    const shouldPublish = ConnectionOptions.publishOption;
    const preferredCodec = this.preferredCodec;
    const autoSubscribe = ConnectionOptions.autoSubscribe;
    const e2eeEnabled = ConnectionOptions.e2eeEnabled;

    setLogLevel(LogLevel.debug);
    let roomService = this.injector.get(RoomService)
    let maxRetryCount = 999999
    const roomOpts: RoomOptions = {
      reconnectPolicy: {
        nextRetryDelayInMs: (context) => {
          if (context.retryCount >= maxRetryCount) {
            return null;
          }
          roomService.connectionProblemDetected();
          return this.maxRetryDelay;
        }
      },
      adaptiveStream,
      dynacast,
      publishDefaults: {
        simulcast,
        videoSimulcastLayers: [VideoPresets.h90, VideoPresets.h216],
        videoCodec: preferredCodec || 'vp8',
        dtx: true,
        red: true,
        forceStereo: false,
      },
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      e2ee: e2eeEnabled
        ? {
          keyProvider: this.state.e2eeKeyProvider,
          worker: new Worker(
            new URL('livekit-client/e2ee-worker', import.meta.url)
          ),
        }
        : undefined,
    };
    const audioQualityToUse = roomService.audioQualityFromRoomInfo !== undefined 
    && roomService.audioQualityFromRoomInfo !== 0 ?
    roomService.audioQualityFromRoomInfo :
    roomService.AudioQuality;

    if (audioQualityToUse) {
      const foundPreset = 
          this.findPresetByQuality(audioQualityToUse, AudioPresets)
      if (foundPreset && roomOpts.publishDefaults) {
          roomOpts.publishDefaults.audioPreset = foundPreset;
      }
    }
    if (
      roomOpts.publishDefaults?.videoCodec === 'av1' ||
      roomOpts.publishDefaults?.videoCodec === 'vp9'
    ) {
      roomOpts.publishDefaults.backupCodec = true;
    }

    const connectOpts: RoomConnectOptions = {
      autoSubscribe: autoSubscribe,
    };
    if (forceTURN) {
      connectOpts.rtcConfig = {
        iceTransportPolicy: 'relay',
      };
    }
    this.participants = [];
    this.getMicToken().then(res => {
      this.roomMicToken = res
    }).then(() => {
      this.connectToRoom(url, this.roomMicToken.token, roomOpts, connectOpts, shouldPublish);
    }).then(() => {
      this.handleDevicesChanged()
    });
  }

  findPresetByQuality(audioQuality: number, presets: Record<string, AudioPreset>): AudioPreset | undefined {
    for (const presetName in presets) {
        if (presets.hasOwnProperty(presetName)) {
            const preset = presets[presetName];
            if (preset.maxBitrate === audioQuality) {
                return preset;
            }
        }
    }
    return undefined;
}
  getMicToken() {
    let apiurl = environment.apiUrl + "api/mic/token";
    return this.http.get<RoomMicToken>(apiurl).toPromise();
  }

  async connectToRoom(
    url: string,
    token: string,
    roomOptions?: RoomOptions,
    connectOptions?: RoomConnectOptions,
    shouldPublish?: boolean
  ): Promise<Room | undefined> {
    this.room = new Room(roomOptions);
    
    await this.room.prepareConnection(url, token);
      this.room
        .on(RoomEvent.Connected, this.handleRoomConnect)
        .on(RoomEvent.Reconnected, this.handleRoomReconnect)
        .on(RoomEvent.Disconnected, this.handleRoomDisconnect)
        .on(RoomEvent.ParticipantConnected, this.participantConnected) 
        .on(RoomEvent.ParticipantDisconnected, this.participantDisconnected)
        .on(RoomEvent.TrackPublished, this.handleTrackPublished) 
        .on(RoomEvent.TrackUnpublished, this.handleTrackUnPublished)
        .on(RoomEvent.LocalTrackPublished, this.handleLocalTrackPublished)
        .on(RoomEvent.LocalTrackUnpublished, this.handleLocalTrackUnPublished)
         .on(RoomEvent.MediaDevicesChanged, this.handleDevicesChanged)
        .on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed) 
        .on(RoomEvent.TrackUnsubscribed, this.handleTrackUnSubscribed) 
        .on(RoomEvent.SignalConnected, this.handleSignalConnected) 
        .on(RoomEvent.MediaDevicesError, this.handleMediaDevicesError)

    try {
      await this.room.connect(url, token, connectOptions);
    } catch (error: any) {
      let message: any = error.message || error;
      if (error.message) {
        message = error.message;
      }
      return;
    }
    this.room.remoteParticipants.forEach((participant) => {
      this.participantConnected(participant);
    });
    this.participantConnected(this.room.localParticipant);
    return this.room;
  }
  participantConnected = (participant: Participant) => {
    participant
      .on(ParticipantEvent.TrackMuted, (pub: TrackPublication) => { 
        if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
          this.renderAudioElement(participant);
        }
        if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
          this.renderVideoElement(participant);
        }
        if (pub.source == Track.Source.ScreenShareAudio) {
          if (!this.isOnScreenSharing) {
            this.renderShareMusicTab(participant)
          }
        }
        if (pub.source == Track.Source.ScreenShare) {
          this.renderScreenShareVideoElement(participant)
        }
      })
      .on(ParticipantEvent.TrackUnmuted, (pub: TrackPublication) => {
        if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
          this.renderAudioElement(participant);
        }
        if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
          this.renderVideoElement(participant);
        }
        if (pub.source == Track.Source.ScreenShareAudio) {
          if (!this.isOnScreenSharing) {
            this.renderShareMusicTab(participant)
          }
        }
        if (pub.source == Track.Source.ScreenShare) {
          this.renderScreenShareVideoElement(participant)
        }
      })
      .on(ParticipantEvent.IsSpeakingChanged, () => {
        this.updateMicIndicator(participant);
      })
  }
  updateMicIndicator(participant: Participant) {
    let id = "user-on-mic-" + participant.identity
    let userOnMic = document.getElementById(id);
    if (userOnMic) {
      if (participant.isSpeaking) {
        userOnMic.style.opacity = "1";
      } else {
        userOnMic.style.opacity = "0";
      }
    }
  }

  async disconnectRoom() {
    if (this.room) {
      await this.room.disconnect();
    }
  }


  participantDisconnected = (participant: RemoteParticipant) => {
    this.renderAudioElement(participant);
    this.removeAudioElement(participant)
    this.renderVideoElement(participant, true);
    this.renderScreenShareVideoElement(participant,true)
  }

  async toggleShareScreenAudio(isOpen:boolean){
    if (isOpen) {
      this.publishShareScreenAudio()
    }
    else{
     this.unpublishShareAudio()
    }
  }

  async publishShareScreenAudio(){
    await this.room.localParticipant.createScreenTracks({
      audio: true,
    }).then((tracks: LocalTrack[])=>{
      tracks.forEach((track) => {
        if (track.kind === 'audio' && track.source == Track.Source.ScreenShareAudio) {
           this.room.localParticipant.publishTrack(track);
           this.isShareMusic=true
          this.renderShareMusicTab(this.room.localParticipant)
        }
      });
    }).catch(()=>{
      this.isShareMusic = false
    });
  }

  unpublishShareAudio(){
    const track= this.room.localParticipant.getTrackPublication(Track.Source.ScreenShareAudio)
    if (track?.track?.kind === 'audio' && track.track.source == Track.Source.ScreenShareAudio) {
      this.room.localParticipant.unpublishTrack(track.track)
      this.renderShareMusicTab(this.room.localParticipant)
    }
  }

  muteShareAudio(){
    const track= this.room.localParticipant.getTrackPublication(Track.Source.ScreenShareAudio)
    track.mute()
  }
  unmuteShareAudio(){
    const track= this.room.localParticipant.getTrackPublication(Track.Source.ScreenShareAudio)
    track.unmute()
  }

  renderShareMusicTab(participant: Participant) {
    let {identity}=participant
    let audioELm = <HTMLAudioElement>document.getElementById("screen-music-" + identity);
    const screenMusicPub = participant.getTrackPublication(Track.Source.ScreenShareAudio);
    const screenMusicEnabled = screenMusicPub && screenMusicPub.isSubscribed && !screenMusicPub.isMuted;
    if (screenMusicEnabled) {
      if (!(participant instanceof LocalParticipant)) {
        screenMusicPub?.audioTrack?.attach(audioELm);
      }
    } else {
      if (!(participant instanceof LocalParticipant)) {
        screenMusicPub?.audioTrack?.detach(audioELm);
      }
    }
  }
  async toggleAudio(isOpen: boolean) {
    try {
      if (!this.room) return;
      this.selectedAudioInputDeviceId = this.audioInputDevices[0].deviceId;
      await this.room.localParticipant.setMicrophoneEnabled(isOpen);
      this.isOnMic = isOpen;
      this.allSenderAudioTrackAreMuted = !isOpen;
      this.isMicEnabled = isOpen
      this.renderAudioElement(this.room.localParticipant);
    } catch (error) {
      console.error('toggleAudio an error occurred:', error);
    }
  }
  

  async toggleVideo(open: boolean): Promise<boolean> {
    try {
      if (!this.room) return;
      await this.setDefaultVideoInputDevice();
      await this.room.localParticipant.setCameraEnabled(open);
      this.isCamOpen = open;
      this.renderVideoElement(this.room.localParticipant);
      return true; 
    } catch (error) {
      console.error('toggleVideo an error occurred:', error);
      return false; 
    }
  }

  async toggleScreenShare(open: boolean): Promise<boolean> {
    try {
        if (!this.room) return false; 
        await this.room.localParticipant.setScreenShareEnabled(open, { audio: true });
        return true; 
    } catch (error) {
        console.error('toggleVideo an error occurred:', error);
        return false; 
    }
}

  removeAudioElement(participant:Participant) {
      let identity = participant.identity
      let element = <HTMLElement>document.getElementById("participant-" + identity);
      if (element) {
        element.parentElement?.removeChild(element);
      }
  }

  removeVideoElement(participant:Participant) {
    let identity = participant.identity;
    let videoContainerId = "room_video_" + this.roomMicToken.roomId + "_" + identity;
    let videoElm = <HTMLVideoElement>document.getElementById(videoContainerId);
      if (videoElm) {
        let appCameraId = Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + identity
        let appCameraComponent = <HTMLElement>document.getElementById(appCameraId);
        appCameraComponent.remove();
      }
  }

  handleRoomDisconnect = (reason?: DisconnectReason) => {

    if (!this.room) return;
    this.renderAudioElement(this.room.localParticipant);
    this.renderVideoElement(this.room.localParticipant);
    this.removeAudioElement(this.room.localParticipant);
    this.participants.forEach((p) => {
      this.removeAudioElement(p);
    });
    this.panelManagerService.roomVideoElementsClearSource.next();
  }
  

  handleRoomConnect = () => {
  }

  handleRoomReconnect = () => {
    if (!this.panelManagerService.roomOpened) {
      this.disconnectRoom();
    }
    else{
      let roomService = this.injector.get(RoomService)
      roomService.reconnectedSuccessfully()
    }
  }

  handleTrackPublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(participant);
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(participant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(participant)
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(participant)
    }
  }
  handleTrackUnPublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (participant.identity.includes('agent-')) {
      this.isRoomHasAIAgent = false
      let roomService = this.injector.get(RoomService)
      roomService.micUserListLoadedSource.next()
    }
  }

  handleLocalTrackPublished = (pub: LocalTrackPublication) => {
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(this.room.localParticipant);
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(this.room.localParticipant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(this.room.localParticipant)
        this.isOnSharingTabMusic = true
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(this.room.localParticipant)
    }
  }

  handleLocalTrackUnPublished = (pub: LocalTrackPublication) => {
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(this.room.localParticipant);
      this.unpublishShareAudio()
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera ) {
      this.renderVideoElement(this.room.localParticipant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(this.room.localParticipant)
        this.isOnSharingTabMusic = false
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      if (!this.closeScreenSharefromComponent) {
        const roomService = this.injector.get(RoomService);
        roomService.stopScreenShareFromLivekit(parseInt(this.room.localParticipant.identity),roomService.currentRoom.Info?.ID,false,false);
      }
      this.renderScreenShareVideoElement(this.room.localParticipant)
    }
  }

  handleMediaDevicesError = (e: Error) => {
    const failure = MediaDeviceFailure.getFailure(e)
    console.log("MediaDeviceFailure - > ", failure);
  }

  handleTrackSubscribed = (track: RemoteTrack, pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (participant.identity.includes('agent-')) {
      this.isRoomHasAIAgent = true
      this.appendAIAssistantToList(participant)
    }
    if (pub.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(participant);
    }
    if (pub.kind == 'video' && pub.source == Track.Source.Camera) {
      this.renderVideoElement(participant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(participant)
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(participant)
    }
  }
  
  handleTrackUnSubscribed = (track: RemoteTrack, pub: RemoteTrackPublication, participant: RemoteParticipant) => {
    if (track.kind == 'audio' && pub.source == Track.Source.Microphone) {
      this.renderAudioElement(participant);
    }
    if (track.kind == 'video') {
      this.renderVideoElement(participant);
    }
    if (pub.source == Track.Source.ScreenShareAudio) {
      if (!this.isOnScreenSharing) {
        this.renderShareMusicTab(participant)
      }
    }
    if (pub.source == Track.Source.ScreenShare) {
      this.renderScreenShareVideoElement(participant)
    }
  }


  async handleSignalConnected() {
    if (ConnectionOptions.publishOption) {
      await this.room.localParticipant.enableCameraAndMicrophone();
    }
  }

  appendAIAssistantToList(participant:Participant){
    let roomService = this.injector.get(RoomService)
      this.roomAIAssistant = {
        KullaniciId: Number(participant.identity),
        KullaniciAdi: "Veribot",
        IkinciKullaniciAdi: "",
        Nicktype: null,
        BanOda: false,
        BanSite: false,
        MuteOda: false,
        MuteSite: false,
        Karaliste: false,
        RutbeAdi: "AI Assistant",
        RutbeDerece: 0,
        RutbeId: 0,
        ProfilResmiId: "10000000-0000-0000-0000-000000000012",
        FlashnickId: "",
        StateId: 0,
        BanOdaList: null,
        MuteOdaList: null,
        IsCamOpen: false,
        IsScreenShareOpen: false,
        OnMainMic: false,
        EnterRoomIndex: 0,
        HandUp: false,
        OnSecondMic: false,
        RoomCamSession: null,
     }
     if (roomService.micUserList.length < 0) {
       roomService.micUserAddedSource.next(this.roomAIAssistant)
     }
     else{
      roomService.micUserListLoadedSource.next()
     }
  }
  async handleDeviceSelected(deviceId: string, kind: MediaDeviceKind) {
    if (!kind) {
      return;
    }
    this.state.defaultDevices.set(kind, deviceId);
    if (this.room) {
      if (kind === 'videoinput') {
        await this.room.switchActiveDevice(kind, deviceId);
        this.selectedVideoDeviceId = deviceId;
      }
      else if (kind === 'audioinput') {
        await this.room.switchActiveDevice(kind, deviceId);
        this.selectedAudioInputDeviceId = deviceId;
      }
    }
  }

 async setDefaultVideoInputDevice(){
    let deviceId =  this.videoDevices[0].deviceId
    this.selectedVideoDeviceId = this.videoDevices[0].deviceId
    setTimeout(()=>{
      this.state.defaultDevices.set('videoinput', deviceId);
    },100)
    await this.room.switchActiveDevice('videoinput',deviceId)
  }

  async handleDevicesChanged() {
    if (this.elementMapping) {
      await Promise.all(
        Object.keys(this.elementMapping).map(async (id) => {
          const kind = this.elementMapping[id];
          if (!kind) {
            return;
          }
          if (kind == 'videoinput') {
            this.videoDevices = await Room.getLocalDevices(kind);
          }
          if (kind == 'audioinput') {
            this.audioInputDevices = await Room.getLocalDevices(kind);
          }
        })
      );
    }
   }

   renderAudioElement(participant: Participant) {
    try {
      this.addNewParticipants(participant);
      const { identity } = participant;
      const roomService = this.injector.get(RoomService);
      const container = <HTMLElement>document.getElementById("participants-area");
      if (!container) return;
      let div = <HTMLElement>document.getElementById("participant-" + identity);
      if (!div) {
        div = document.createElement('div');
        div.id = `participant-${identity}`;
        div.className = 'participant';
        div.innerHTML = `<audio id="audio-${identity}"></audio>`;
        div.innerHTML += `<audio id="screen-music-${identity}"></audio>`;
        container.appendChild(div);
      }
      let audioELm = <HTMLAudioElement>document.getElementById("audio-" + identity);
      const micPub = participant.getTrackPublication(Track.Source.Microphone);
      const micEnabled = micPub && micPub.isSubscribed && !micPub.isMuted;
      if (micEnabled) {
        if (!(participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.attach(audioELm);
        }
         if (roomService.allParticipantAreMuted) {
          let remoteParticipant = participant as RemoteParticipant
          this.muteParticipant(remoteParticipant)
         }
      } else {
        if (!(participant instanceof LocalParticipant)) {
          micPub?.audioTrack?.detach(audioELm);
        }
      }
    } catch (error) {
      console.error('renderAudioElement an error occurred:', error);
    }
  }

  muteParticipant(participant: RemoteParticipant) {
      const micPublication = participant.getTrackPublication(Track.Source.Microphone);
      const screenShareAudioPublication = participant.getTrackPublication(Track.Source.ScreenShareAudio);
        if (micPublication) {
            micPublication.setEnabled(false);
        }
        if (screenShareAudioPublication) {
            screenShareAudioPublication.setEnabled(false);
        }
  }

  renderVideoElement(participant: Participant, remove: boolean = false) {
    try {
      this.addNewParticipants(participant);
      const roomService = this.injector.get(RoomService);
      const { identity } = participant;
      let videoContainerId = "room_video_" + this.roomMicToken.roomId + "_" + identity;
      let videoElm = <HTMLVideoElement>document.getElementById(videoContainerId);
      const cameraPub = participant.getTrackPublication(Track.Source.Camera);
  
      if (remove) {
        if (videoElm) {
          let appCameraId = Constants.mainSwiperTag + '' + MainPanels.RoomVideo + '_' + identity
          let appCameraComponent = <HTMLElement>document.getElementById(appCameraId);
          if (appCameraComponent) {
            appCameraComponent.remove();
          }
        }
        return;
      }
      const cameraEnabled = cameraPub && cameraPub.isSubscribed && !cameraPub.isMuted;
      if (cameraEnabled && videoElm) {
        if (participant instanceof LocalParticipant) {
          videoElm.style.transform = 'scale(-1, 1)';
        }
        cameraPub?.videoTrack?.attach(videoElm);
        roomService.callGetMicUserList();
  
      } else {
        if (cameraPub?.videoTrack) {
          cameraPub.videoTrack?.detach(videoElm);
          roomService.callGetMicUserList();
        }
      }
    } catch (error) {
      console.error('renderVideoElement an error occurred:', error);
    }
  }

  renderScreenShareVideoElement(participant: Participant, remove: boolean = false) {
    try {
      this.addNewParticipants(participant);
      const roomService = this.injector.get(RoomService);
      const { identity } = participant;
      let videoContainerId = "screenShare_video_" + this.roomMicToken.roomId + "_" + identity; 
      let videoElm = <HTMLVideoElement>document.getElementById(videoContainerId);
      const screenSharePub = participant.getTrackPublication(Track.Source.ScreenShare);
  
      if (remove) {
        if (videoElm) {
          let appScreenShareId = Constants.mainSwiperTag + '' + MainPanels.ScreenShare + '_' + identity
          let appScreenShareComponent = <HTMLElement>document.getElementById(appScreenShareId);
          if (appScreenShareComponent) {
            appScreenShareComponent.remove();
          }
        }
        return;
      }
      const screenShareEnabled = screenSharePub && screenSharePub.isSubscribed && !screenSharePub.isMuted;
      const screenShareAudioPub = participant.getTrackPublicationByName(Track.Source.ScreenShareAudio)
      const screenShareAudioEnabled = screenShareAudioPub && screenShareAudioPub.isSubscribed  && !screenShareAudioPub.isMuted;
      if (screenShareEnabled && videoElm) {
        screenSharePub?.videoTrack?.attach(videoElm);
        if (screenShareAudioEnabled) {
          screenShareAudioPub.audioTrack?.attach(videoElm)
        }
      } 
      setTimeout(() => {
        roomService.callGetMicUserList();
      },500)
    } catch (error) {
      console.error('renderScreenShareVideoElement an error occurred:', error);
    }
  }

  addNewParticipants(participant: Participant) {
    const index = this.participants.findIndex(p => p.identity === participant.identity);
    if (index === -1) {
      this.participants.push(participant);
    }
   }
}