import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CentrifugoService } from './services/centrifugo.service';


@NgModule({
  declarations: [

  ],
  imports: [


  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: CentrifugoService) => () => ds.init(),
      deps: [CentrifugoService],
      multi: true,
    },
  ],
})
export class CentrifugoModule { }
